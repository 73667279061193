import {Injectable, OnInit} from '@angular/core';
import {AuthenticationService} from '../../auth/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HistoryService, Result, SearchHistory} from '../../../generated';

@Injectable({
    providedIn: 'root',
})
export class InternalHistoryService implements OnInit {

    private resourceHistory: Result[];
    private searchHistory: SearchHistory[];

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private historyService: HistoryService
    ) {
        this.resourceHistory = [];
    }

    ngOnInit(): void {
        this.historyService.getResourceHistory(this.authenticationService.getTenant())
            .subscribe(r => {
                this.resourceHistory = r;
            });

        this.historyService.getSearchHistory(this.authenticationService.getTenant())
            .subscribe(r => {
                this.searchHistory = r;
            });
    }

    public getResourceHistory(): Result[] {
        return this.resourceHistory;
    }

    public addResourceHistoryForUser(type: string, id: string): void {
        this.historyService.addResourceHistoryForUser(this.authenticationService.getTenant(), type, id)
            .subscribe(p => {
                this.historyService.getResourceHistory(this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.resourceHistory = r;
                    });
            });
    }

    public getSearchHistory(): SearchHistory[] {
        return this.searchHistory;
    }

    public addSearchHistoryForUser(text: string): void {
        this.historyService.addSearchHistoryForUser(this.authenticationService.getTenant(), {term: text})
            .subscribe(p => {
                this.searchHistory = p;
            });
    }
}
