<clr-modal [(clrModalOpen)]="openDeleteModal">
    <h3 class="modal-title">Delete the {{selected.length}} selected workspaces ?</h3>
    <div class="modal-body">
        <ul cds-list *ngFor="let workspace of selected">
            <li> {{workspace.name}}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="deleting" (click)="delete();">
            <clr-spinner [clrSmall]="true" *ngIf="deleting" style="margin-top: 7px;"></clr-spinner>
            <ng-template [ngIf]="!deleting">Ok</ng-template>
        </button>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <h1 style="float:left">Workspaces</h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <clr-dg-action-bar style="margin-top: 0rem;">
            <div class="btn-group" *ngIf="selected.length == 0">
                <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/new/workspace']"
                    *hasPermission="'/workspace/create'; id '_tenant'; type 'tenant'">
                    <cds-icon shape="plus"></cds-icon>
                    Create
                </button>
            </div>
            <div class="btn-group" *ngIf="selected.length > 0">
                <button type="button" class="btn btn-link btn-sm btn-secondary" (click)="openDeleteModal = true"
                    *ngIf="selected.length >= 1">
                    <cds-icon shape="window-close"></cds-icon>
                    Delete
                </button>
                <cds-divider orientation="vertical"></cds-divider>
            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-link btn-sm btn-secondary" (click)="refresh()">
                    <cds-icon shape="refresh"></cds-icon>
                    Refresh
                </button>
                <clr-dropdown>
                    <button type="button" class="btn btn-link btn-sm btn-secondary" clrDropdownTrigger>
                        Export
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <button type="button" (click)="onExportAll()" clrDropdownItem>Export All</button>
                        <button type="button" (click)="onExportSelected()" [disabled]="selected.length === 0"
                            clrDropdownItem>
                            Export Selected Items
                        </button>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </div>
        </clr-dg-action-bar>
        <clr-datagrid [(clrDgSelected)]="selected" class="datagrid-compact" *ngIf="infrastructures">
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    ID
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Name
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Type
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Infrastructure
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Creation date
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>Description</clr-dg-column>

            <clr-dg-row *clrDgItems="let workspace of workspaces" [clrDgItem]="workspace">
                <clr-dg-cell><a [routerLink]="['/workspaces', workspace.id]">{{workspace.id}}</a></clr-dg-cell>
                <clr-dg-cell>{{workspace.name}}</clr-dg-cell>
                <clr-dg-cell>
                    <app-runtime-icon [type]="workspace.type" [size]=12 [displayTitle]=true></app-runtime-icon>
                </clr-dg-cell>
                <clr-dg-cell><app-infra-icon [type]="infrastructures.get(workspace.infrastructure_id).type"
                        [displayTitle]="false" [size]="12"></app-infra-icon>
                    {{infrastructures.get(workspace.infrastructure_id).name}}</clr-dg-cell>
                <clr-dg-cell>{{workspace.created | date:'medium'}}</clr-dg-cell>
                <clr-dg-cell>{{workspace.description}}</clr-dg-cell>
            </clr-dg-row>


            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="20">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Workspaces per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} workspaces
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>
</div>
