import {Component, OnInit} from '@angular/core';
import '@cds/core/tag/register';
import {Application, ApplicationService} from "../../generated";
import {AuthenticationService} from "../auth/authentication.service";
import {forkJoin} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-application',
    templateUrl: './application.component.html'
})
export class ApplicationComponent implements OnInit {

    applicationForm: UntypedFormGroup;
    openCreateApplicationModal: boolean = false;

    viewings: boolean[];

    applications: Application[];
    selected: Application[];
    openDeleteModal: boolean = false;
    deleting: boolean = false;

    constructor(
        private applicationService: ApplicationService,
        private authenticationService: AuthenticationService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.applications = [];
        this.selected = [];
        this.initForm();
    }

    private initForm() {
        this.applicationForm = this.formBuilder.group({
            application: this.formBuilder.group({
                name: ['', Validators.required],
                description: [''],
            }),
            valid: this.formBuilder.group({}),
        });
    }

    ngOnInit() {
        this.applicationService.findApplications(this.authenticationService.getTenant())
            .subscribe(r => {
                this.applications = r;
                this.viewings = this.applications.map(a => false);
            });
    }

    refresh() {
        this.ngOnInit();
    }

    delete() {
        this.deleting = true;
        let subscriptions = this.selected.map(s =>
            this.applicationService.deleteApplicationById(this.authenticationService.getTenant(), s.id));
        forkJoin(subscriptions)
            .subscribe(a => {
                this.openDeleteModal = false;
                this.deleting = false;
                this.refresh();
            });
    }

    createApplication(): void {
        let application = {
            name: this.applicationForm.get('application').get('name').value,
            description: this.applicationForm.get('application').get('description').value
        };
        this.applicationService.createApplication(this.authenticationService.getTenant(), application)
            .subscribe(
                data => {
                    this.openCreateApplicationModal = false;
                    this.initForm();
                    this.refresh();
                },
                error => {
                    this.openCreateApplicationModal = true;
                });
    }
}
