import {Component, OnInit} from '@angular/core';
import '@cds/core/tag/register';
import {
    AuditLog,
    EnvService,
    FollowService,
    Patch,
    PermissionService, Project1,
    RoleAndAssignment,
    RoleService,
    Tenant,
    User, User1,
    UserService
} from '../../generated';
import {AuthenticationService} from '../auth/authentication.service';
import {ThemeOption} from 'ngx-echarts';
import {UserSettingsService} from '../shared/services/user-settings.service';
import {EChartsOption} from 'echarts';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
    tenant: Tenant;
    options: any;
    userDetails: User;
    openChangePasswordModal = false;

    passwordForm: UntypedFormGroup;

    auditlogs: Array<AuditLog>;
    logsReady = false;

    // user: UserInfo;

    gradient: EChartsOption = {
        tooltip: {
            trigger: 'axis'
        },
        xAxis: [{
            type: 'time',
            boundaryGap: false,
            splitLine: {
                show: false
            },
            axisLabel: {
                rotate: 30
            }
        }],
        yAxis: {},
        series: [{
            type: 'line',
            smooth: false,
            showSymbol: false
        }]
    };
    roles: Array<RoleAndAssignment>;

    permissions$: Map<string, Observable<Array<string>>> = new Map<string, Observable<Array<string>>>();
    loading: Map<string, boolean> = new Map<string, boolean>();
    following: Array<User1 | Project1>;

    constructor(
        private envService: EnvService,
        private userService: UserService,
        private roleService: RoleService,
        private permissionService: PermissionService,
        private authenticationService: AuthenticationService,
        private userSettingsService: UserSettingsService,
        private followService: FollowService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.tenant = {};
        this.userDetails = {};
        this.auditlogs = [];
        this.passwordForm = this.formBuilder.group({
            /* basic: this.formBuilder.group({
                username: [this.user.username, Validators.required],
                firstname: ['', Validators.required],
                lastname: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
            }),
            security: this.formBuilder.group({
                power: [],
                groups: [],
            }), */
            password: this.formBuilder.group({
                password: ['', Validators.required],
                confirm: ['', Validators.required],
            }, {validators: this.passwordConfirmedValidator}),
            valid: this.formBuilder.group({}),
        });
    }

    theme(): ThemeOption {
        return this.userSettingsService.getTheme();
    }

    ngOnInit(): void {
        this.envService.getEnv(this.authenticationService.getTenant()).subscribe(e => {
            this.tenant = e;
        });

        this.userService.findCurrentUser(this.authenticationService.getTenant()).subscribe(e => {
            this.userDetails = e;
            this.userService.findLogsByUserId(this.authenticationService.getTenant(), e.id)
                .subscribe(x => {
                    this.logsReady = true;
                    this.auditlogs = x;

                    this.gradient.series[0].data = x.map(t => [t.created, 1]);
                });

            this.roleService.findCurrentRoles(this.authenticationService.getTenant()).subscribe(r => {
                this.roles = r;
            });

            this.followService.getUserFollowing(this.authenticationService.getTenant(), e.id)
                .subscribe(f => {
                    this.following = f;
                });
        });
    }

    changePassword(): void {
        const patch: Patch = {
            op: Patch.OpEnum.Replace,
            value: this.passwordForm.get('password').get('password').value,
            path: '/password'
        };

        this.userService.updateUser(this.authenticationService.getTenant(), this.userDetails.id, [patch])
            .subscribe(
                data => {
                    this.openChangePasswordModal = false;
                },
                error => {
                    this.openChangePasswordModal = true;
                });
    }

    fetchPermissions(roleId: string): void {
        this.loading.set(roleId, true);
        this.permissions$.set(roleId,
            this.roleService.findPermissionsByRoleId(this.authenticationService.getTenant(), roleId)
                .pipe(tap(() => (this.loading.set(roleId, false))))
        );
    }


    passwordConfirmedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const password = control.get('password');
        const confirm = control.get('confirm');
        if (password.value === '' || password == null) {
            return {passwordIssue: 'Password required'};
        }
        if (confirm.value === '' || confirm == null) {
            return {passwordIssue: 'Confirm password required'};
        }

        return password && confirm && password.value !== confirm.value ? {passwordIssue: ' Password don\'t match.'} : null;
    }
}
