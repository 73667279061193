import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProjectResolver} from './project-resolver.service';
import {ProjectListComponent} from './list/project-list.component';
import {ProjectDetailsComponent} from './details/project-details.component';
import {ProjectConfigurationComponent} from './configuration/project-configuration.component';
import {AuthGuard} from '../auth/auth.guard';
import {ProjectPermissionsComponent} from './permissions/project-permissions.component';
import {ProjectMetricsComponent} from './metrics/project-metrics.component';
import {ProjectCostsComponent} from './budget/project-costs.component';
import {RedirectJobComponent} from './redirect/redirect-job.component';
import {RedirectJobRunComponent} from './redirect/redirect-job-run.component';
import {RedirectWorkflowComponent} from './redirect/redirect-workflow.component';
import {RedirectWorkflowRunComponent} from "./redirect/redirect-workflow-run.component";

const routes: Routes = [
    {
        path: 'jobs/:jobId',
        component: RedirectJobComponent
    },
    {
        path: 'jobs/:jobId/runs/:runId',
        component: RedirectJobRunComponent
    },
    {
        path: 'workflows/:workflowId',
        component: RedirectWorkflowComponent
    },
    {
        path: 'workflows/:workflowId/runs/:runId',
        component: RedirectWorkflowRunComponent
    },
    {
        path: 'projects',
        component: ProjectListComponent
    },
    {
        path: 'projects/:id',
        component: ProjectDetailsComponent,
        resolve: {
            project: ProjectResolver
        }
    },
    {
        path: 'projects/:id/configuration',
        component: ProjectConfigurationComponent,
        resolve: {
            project: ProjectResolver
        }
    },
    {
        path: 'projects/:id/metrics',
        component: ProjectMetricsComponent,
        resolve: {
            project: ProjectResolver
        }
    },
    {
        path: 'projects/:id/costs',
        component: ProjectCostsComponent,
        resolve: {
            project: ProjectResolver
        }
    },
    {
        path: 'projects/:id/permissions',
        component: ProjectPermissionsComponent,
        resolve: {
            project: ProjectResolver
        }
    },
    {
        path: 'projects/:id/jobs',
        loadChildren: () => import('./job/job.module').then(m => m.JobModule),
        canActivate: [AuthGuard],
        resolve: {
            project: ProjectResolver
        }
    },
    {
        path: 'projects/:id/workflows',
        loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule),
        canActivate: [AuthGuard],
        resolve: {
            project: ProjectResolver
        }
    },
    {
        path: 'projects/:id/endpoints',
        loadChildren: () => import('./endpoint/endpoint.module').then(m => m.EndpointModule),
        canActivate: [AuthGuard],
        resolve: {
            project: ProjectResolver
        }
    },
    {
        path: 'projects/:id/experiments',
        loadChildren: () => import('./experiment/experiment.module').then(m => m.ExperimentModule),
        canActivate: [AuthGuard],
        resolve: {
            project: ProjectResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProjectRoutingModule {
}
