import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClarityModule} from '@clr/angular';
import {SignpostComponent} from './signpost/signpost.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [SignpostComponent],
    imports: [
        CommonModule,
        ClarityModule,
        RouterModule,
    ],
    exports: [SignpostComponent]
})
export class AboutModule {
}
