import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {

    private refreshProjects = new BehaviorSubject<boolean>(false);

    forceRefreshProjects(): void {
        this.refreshProjects.next(true);
    }

    getRefreshProjectsAsObservable(): Observable<boolean> {
        return this.refreshProjects.asObservable();
    }
}
