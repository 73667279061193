<ng-template #displayResult let-results>
    <p class="p3" *ngFor="let result of results" style="margin-top: 0.5rem;">
        <ng-template [ngIf]="result.type == 'job'">
            <a (click)="closeModal()" routerLink="/jobs/{{$any(result.result).id}}">
                <cds-icon shape="applications" aria-current="true" aria-label="Current"></cds-icon>
                job - {{$any(result.result).name}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'user'">
            <a (click)="closeModal()" routerLink="/advanced/security/users/{{$any(result.result).id}}">
                <cds-icon shape="user" aria-current="true" aria-label="Current"></cds-icon>
                user - {{$any(result.result).username}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'workspace'">
            <a (click)="closeModal()" routerLink="/workspaces/{{$any(result.result).id}}">
                <cds-icon shape="computer" aria-current="true" aria-label="Current"></cds-icon>
                workspace - {{$any(result.result).name}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'runtime'">
            <a (click)="closeModal()" routerLink="/advanced/runtimes/{{$any(result.result).id}}">
                <cds-icon shape="host-group" aria-current="true" aria-label="Current"></cds-icon>
                runtime - {{$any(result.result).name}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'secret'">
            <a (click)="closeModal()" routerLink="/advanced/security/secrets/{{$any(result.result).id}}">
                <cds-icon shape="key" aria-current="true" aria-label="Current"></cds-icon>
                secret - {{$any(result.result).name}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'project'">
            <a (click)="closeModal()" routerLink="/projects/{{$any(result.result).id}}">
                <cds-icon shape="folder-open" aria-current="true" aria-label="Current"></cds-icon>
                project - {{$any(result.result).name}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'bridge'">
            <a (click)="closeModal()" routerLink="/advanced/access/bridges/{{$any(result.result).id}}">
                <cds-icon shape="connect" aria-current="true" aria-label="Current"></cds-icon>
                bridge - {{$any(result.result).name}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'infrastructure'">
            <a (click)="closeModal()" routerLink="/advanced/resources/infrastructures/{{$any(result.result).id}}">
                <cds-icon shape="host-group" aria-current="true" aria-label="Current"></cds-icon>
                infrastructure - {{$any(result.result).name}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'workflow'">
            <a (click)="closeModal()" routerLink="/workflows/{{$any(result.result).id}}">
                <cds-icon shape="ci-cd" aria-current="true" aria-label="Current"></cds-icon>
                workflow - {{$any(result.result).name}}
            </a>
        </ng-template>
        <ng-template [ngIf]="result.type == 'datawarehouse'">
            <a (click)="closeModal()" routerLink="/data/datawarehouses/{{$any(result.result).id}}">
                <span class="iconify-inline" data-icon="maki:warehouse" style="font-size: 16px;"></span>
                datawarehouse - {{$any(result.result).name}}
            </a>
        </ng-template>
    </p>
</ng-template>

<clr-main-container>
    <!--    <clr-alert clrAlertType="warning" [clrAlertAppLevel]="true">-->
    <!--        <clr-alert-item>-->
    <!--            <div class="alert-text">Upcoming maintenance on 21/06/2023</div>-->
    <!--            <div class="alert-actions">-->
    <!--                <a [routerLink]="['alerts']">-->
    <!--                <button class="btn alert-action">More</button>-->
    <!--                </a>-->
    <!--            </div>-->
    <!--        </clr-alert-item>-->
    <!--    </clr-alert>-->
    <!--    <clr-alert clrAlertType="danger" [clrAlertAppLevel]="true">-->
    <!--        <clr-alert-item>-->
    <!--            <div class="alert-text">Your trial has expired, please add a new payment method</div>-->
    <!--            <div class="alert-actions">-->
    <!--                <a [routerLink]="['/costs/payment-methods']">-->
    <!--                    <button class="btn alert-action">More</button>-->
    <!--                </a>-->
    <!--            </div>-->
    <!--        </clr-alert-item>-->
    <!--    </clr-alert>-->

    <!--    <clr-header class="header-7">-->
    <!--        <div class="branding">-->
    <!--            <a [routerLink]="['/']"-->
    <!--               class="nav-link">-->
    <!--&lt;!&ndash;                <cds-icon shape="step-forward-2"></cds-icon>&ndash;&gt;-->
    <!--                <img src="{{style().metadata['icon'] || 'assets/logo7.png'}}" style="height: 32px;margin-right: 12px;"/>-->
    <!--                <span class="title">{{style().metadata['title'] || 'Graal Systems'}}</span>-->
    <!--            </a>-->
    <!--        </div>-->

    <!--        <form class="search" style="z-index: 2000;">-->
    <!--            <label for="search_input">-->
    <!--                <input id="search_input" #search_input [ngModel]="search" (focusin)="onFocusInEvent($event)" name="search_input" type="text" placeholder="Search in resources and services" style="width: 400px;" />-->
    <!--            </label>-->
    <!--        </form>-->

    <clr-modal [(clrModalOpen)]="openSearchModal" [clrModalClosable]="true" [clrModalSize]="'lg'"
        [clrModalStaticBackdrop]="false">
        <div class="modal-body" style="padding-top: -7px;">
            <div class="clr-row" style="padding-top: -3px;">
                <div class="clr-col-12">
                    <form novalidate="" class="search ng-pristine ng-valid ng-touched" style="z-index: 2000;">
                        <label for="search_input" ng-reflect-for-attr="search_input" style="padding-left: 0px;">
                            <span
                                style='display: inline-block; background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2036%2036%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23ffffff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3ESearch%3C%2Ftitle%3E%3Cg%20id%3D%22icons%22%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22M15%2C4.05A10.95%2C10.95%2C0%2C1%2C1%2C4.05%2C15%2C11%2C11%2C0%2C0%2C1%2C15%2C4.05M15%2C2A13%2C13%2C0%2C1%2C0%2C28%2C15%2C13%2C13%2C0%2C0%2C0%2C15%2C2Z%22%2F%3E%3Cpath%20class%3D%22cls-1%22%20%20d%3D%22M33.71%2C32.29l-7.37-7.42-1.42%2C1.41%2C7.37%2C7.42a1%2C1%2C0%2C1%2C0%2C1.42-1.41Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); background-repeat: no-repeat; background-size: contain; cursor: pointer; height: 1rem; width: 1rem; margin: 1rem 0 0; vertical-align: top;margin-top: 16px;'></span>
                            <input id="search_input" #search_input name="search_input" type="text"
                                placeholder="Search in resources and services"
                                style="width: 400px; padding-left: 14px;background-color: transparent;border: none;margin-top: 13px;color: white;">
                        </label>
                    </form>
                </div>
                <div class="clr-col-6">
                    <h5>Results <clr-spinner [clrSmall]="true" style="margin-top: 4px;"
                            *ngIf="isSearching"></clr-spinner></h5>
                    <ng-template *ngTemplateOutlet="displayResult; context: {$implicit: results}"></ng-template>
                </div>
                <div class="clr-col-6">
                    <h5>Recent search</h5>
                    <p class="p3" style="margin-top: 0.5rem;" *ngFor="let term of getSearchHistory()">
                        <a (click)="setSearchTerm(term.term)">
                            <cds-icon shape="history" aria-current="true" aria-label="Current"></cds-icon>
                            {{term.term}}
                        </a>
                    </p>
                    <h5>Last resources used</h5>
                    <ng-template
                        *ngTemplateOutlet="displayResult; context: {$implicit: getResourceHistory()}"></ng-template>
                </div>
            </div>
        </div>
    </clr-modal>

    <div [class.content-container]="true">
<!--        <div class="clr-row">-->
<!--            <div class="clr-col-12" style="padding-left: 0px;padding-right: 0px;">-->
<!--                <div class="card" style="margin-top: 0px;">-->
<!--                    <div class="card-block">-->
<!--                        <div class="clr-row">-->
<!--                            <div class="clr-col-2">-->
<!--                                <cds-icon status="success" shape="success-standard" size="24"></cds-icon>-->
<!--                            </div>-->
<!--                            <div class="clr-col-10">-->
<!--                                <h5 style="margin-top: 0px;">title</h5>-->
<!--                                <p style="margin-top: 0px;">qsdqsd</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="card-footer">-->
<!--                        <button class="btn btn-sm btn-link">Footer Action 1</button>-->
<!--                        <button class="btn btn-sm btn-link">Footer Action 2</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <clr-vertical-nav
            style="background-color: rgb(14, 23, 28); {{mainSideCollapsed ? 'padding-left: 8px; padding-right: 8px; width: 64px;' : ''}}"
            [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="mainSideCollapsed">

            <clr-dropdown>
                <div style="display: flex;margin-bottom: 24px;height: 48px;" clrDropdownTrigger clrVerticalNavLink
                    *ngIf="userDetails">
                    <app-user-icon
                        style="margin-bottom: 32px; {{mainSideCollapsed ? 'margin-right: 40px;margin-left: 16px;' : 'margin-left: 24px;'}}align-self: baseline;"
                        clrVerticalNavIcon
                        [names]="[userDetails.firstname + ' ' + userDetails.lastname]"></app-user-icon>
                    <p *ngIf="!mainSideCollapsed"
                        style="display: inline-block; vertical-align: middle; text-align: left; font-weight: 600; padding-left: 24px; padding-right: 6px;margin-top: -4px;">
                        {{userDetails.firstname + ' ' + userDetails.lastname}}<br />
                        {{tenant.name}}</p>
                    <cds-icon shape="angle" direction="right" class="nav-group-trigger-icon" size="16"></cds-icon>
                </div>
                <clr-dropdown-menu clrPosition="right-top" *clrIfOpen>
                    <a routerLink="/profile" clrDropdownItem>My profile</a>
                    <a routerLink="/preferences" clrDropdownItem>Preferences</a>
                    <a routerLink="/applications" clrDropdownItem>Personal access tokens</a>
                    <a (click)="logoff()" clrDropdownItem>Log out</a>
                </clr-dropdown-menu>
            </clr-dropdown>

            <a clrVerticalNavLink routerLink="/notifications" routerLinkActive="active"
               [routerLinkActiveOptions]="{ exact: true }" style="margin-top: -24px;margin-bottom: 12px;">
                <span clrVerticalNavIcon class="badge badge-purple">{{ notifications }}<span class="clr-sr-only">item in a badge</span></span>
                Notifications</a>

            <a clrVerticalNavLink routerLink="/" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"><cds-icon clrVerticalNavIcon shape="home"></cds-icon>
                Overview</a>
            <a clrVerticalNavLink (click)="openModal()"><cds-icon clrVerticalNavIcon shape="search"></cds-icon>
                Search</a>
            <clr-tooltip>
                <a clrVerticalNavLink clrTooltipTrigger routerLink="/projects" routerLinkActive="active">
                    <cds-icon clrVerticalNavIcon shape="folder-open"></cds-icon> Projects
                </a>
                <clr-tooltip-content *ngIf="mainSideCollapsed">
                    <span>Projects</span>
                </clr-tooltip-content>
            </clr-tooltip>
            <clr-tooltip>
                <a clrVerticalNavLink clrTooltipTrigger routerLink="/workspaces" routerLinkActive="active">
                    <cds-icon clrVerticalNavIcon shape="computer"></cds-icon> Workspaces
                </a>
                <clr-tooltip-content *ngIf="mainSideCollapsed">
                    <span>Workspaces</span>
                </clr-tooltip-content>
            </clr-tooltip>
            <clr-tooltip>
                <a clrVerticalNavLink clrTooltipTrigger routerLink="/data" routerLinkActive="active">
                    <cds-icon clrVerticalNavIcon shape="data-cluster"></cds-icon> Data
                </a>
                <clr-tooltip-content *ngIf="mainSideCollapsed">
                    <span>Data</span>
                </clr-tooltip-content>
            </clr-tooltip>
            <clr-tooltip>
                <a clrVerticalNavLink clrTooltipTrigger routerLink="/draw" routerLinkActive="active">
                    <cds-icon clrVerticalNavIcon shape="pencil"></cds-icon> Draw
                </a>
                <clr-tooltip-content *ngIf="mainSideCollapsed">
                    <span>Draw</span>
                </clr-tooltip-content>
            </clr-tooltip>

            <!--            <a clrVerticalNavLink routerLink="/draw" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><cds-icon clrVerticalNavIcon shape="pencil"></cds-icon> Overview</a>-->
            <clr-tooltip>
                <a clrVerticalNavLink clrTooltipTrigger routerLink="/advanced" routerLinkActive="active">
                    <cds-icon clrVerticalNavIcon shape="cog"></cds-icon> Advanced
                </a>
                <clr-tooltip-content *ngIf="mainSideCollapsed">
                    <span>Advanced</span>
                </clr-tooltip-content>
            </clr-tooltip>

            <!--            <div style="margin-top: 150px;"></div>-->

            <clr-dropdown style="margin-top: 100px;">
                <a clrDropdownTrigger clrVerticalNavLink
                    style="font-size: 16px;{{!mainSideCollapsed ? 'padding-left: 24px;' : 'padding-right: 15px;'}}"
                    style="font-weight: 600;">
                    <cds-icon clrVerticalNavIcon shape="plus-circle"></cds-icon> Create
                </a>
                <clr-dropdown-menu clrPosition="right-top" *clrIfOpen>
                    <a routerLink="/new/project" clrDropdownItem><cds-icon shape="folder-open" aria-current="true"
                            aria-label="Current"></cds-icon> Project</a>
                    <a routerLink="/new/workspace" clrDropdownItem><cds-icon shape="computer" aria-current="true"
                            aria-label="Current"></cds-icon> Workspace</a>
                    <a routerLink="/new/job" clrDropdownItem><cds-icon shape="applications" aria-current="true"
                            aria-label="Current"></cds-icon> Job</a>
                    <a routerLink="/new/workflow" clrDropdownItem><cds-icon shape="ci-cd" aria-current="true"
                            aria-label="Current"></cds-icon> Workflow</a>
                    <a routerLink="/data/datawarehouses/new" clrDropdownItem><span class="iconify-inline"
                            data-icon="maki:warehouse" style="font-size: 16px;"></span> Data Warehouse</a>
                </clr-dropdown-menu>
            </clr-dropdown>

            <clr-tooltip *ngFor="let project of projects">
                <a clrVerticalNavLink clrTooltipTrigger routerLink="/projects/{{project.id}}" routerLinkActive="active">
                    <span clrVerticalNavIcon class="badge badge-blue"
                        style="width: 16px;background: {{project.badge}};"></span> {{project.name}} <cds-icon
                        shape="angle" direction="right" class="nav-group-trigger-icon" size="16"></cds-icon>
                </a>
                <clr-tooltip-content *ngIf="mainSideCollapsed" clrSize="lg">
                    <span>{{project.name}}</span>
                </clr-tooltip-content>
            </clr-tooltip>

            <div style="position: absolute;bottom: 72px;">
                <clr-tooltip style="{{!mainSideCollapsed ? 'width: 240px;' : 'width: 50px;'}}">
                    <a clrVerticalNavLink clrTooltipTrigger routerLink="/alerts" routerLinkActive="active">
                        <cds-icon clrVerticalNavIcon shape="bell"></cds-icon> Issues and alarms
                    </a>
                    <clr-tooltip-content *ngIf="mainSideCollapsed">
                        <span>Issues and alarms</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </div>
            <div style="position: absolute;bottom: 36px;">
                <clr-tooltip style="{{!mainSideCollapsed ? 'width: 240px;' : 'width: 50px;'}}">
                    <a clrVerticalNavLink clrTooltipTrigger routerLink="/support" routerLinkActive="active">
                        <cds-icon clrVerticalNavIcon shape="cursor-hand-open"></cds-icon> Support center
                    </a>
                    <clr-tooltip-content *ngIf="mainSideCollapsed">
                        <span>Support center</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </div>
            <div style="position: absolute;bottom: 0px;">
                <app-about-signpost></app-about-signpost>
            </div>

        </clr-vertical-nav>

        <app-navigation-sidenav></app-navigation-sidenav>

        <main [class.content-area]="true"
            [ngStyle]="{'padding': router.url.startsWith('/draw') ? '0px' : '0rem 1.2rem 0rem 1.2rem', 'overflow-y': router.url.startsWith('/draw') ? 'hidden' : 'auto'}">
            <router-outlet></router-outlet>
        </main>
    </div>
</clr-main-container>
<simple-notifications [options]="options"></simple-notifications>
