import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import '@cds/core/tag/register';
import { UserSettingsService } from '../../services/user-settings.service';
import { ActivityService, UserService, ResourceType, TimelineSummary } from '../../../../generated';
import { AuthenticationService } from '../../../auth/authentication.service';
import { DOCUMENT } from '@angular/common';

interface CalendarActivity {
    date: Date;
    count: number;
    level: number;
}

@Component({
    selector: 'app-calendar',
    styles: [`
        ::ng-deep .in-clr-stack-block .stack-block-label .stack-view-key {
            max-width: 0px;
        }
    `],
    templateUrl: './timeline-calendar.component.html',
    styleUrls: ['./timeline-calendar.component.css']
})
export class TimelineCalendarComponent implements OnInit {

    @Input()
    resourceId: string;
    @Input()
    resourceType: ResourceType;

    sum_contributions: number;
    selectedYear: number;
    availableYears: Array<number>;

    minDate: Date;
    maxDate: Date;

    timelineArray: Array<CalendarActivity>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private authenticationService: AuthenticationService,
        private activityService: ActivityService,
        private userSettingsService: UserSettingsService
    ) {
    }

    ngOnInit(): void {
        document.documentElement.style.setProperty(`--level_1`, this.userSettingsService.getTheme()['color'][1]);
        document.documentElement.style.setProperty(`--level_2`, this.userSettingsService.getTheme()['color'][4]);
        document.documentElement.style.setProperty(`--level_3`, this.userSettingsService.getTheme()['color'][7]);
        document.documentElement.style.setProperty(`--level_4`, this.userSettingsService.getTheme()['color'][9]);

        this.activityService.getCreationDate(this.authenticationService.getTenant(), this.resourceId, this.resourceType).subscribe(response => {
            this.availableYears = response

            this.availableYears.sort().reverse()

            this.selectedYear = this.availableYears[0]

            this.updateGraph()
        })
    }

    createTimelineArray(timeline_summary: Array<TimelineSummary>): Array<CalendarActivity> {
        // Takes the Timeline Summary from the Activity Summary and adds an ActivityLevel info that represent the level of a day.
        // For example: 0 = 0 contributions

        const TimelineArray: Array<CalendarActivity> = [];
        const currentDate = this.minDate
        let i = timeline_summary.length - 1
        let valueDate: Date

        // If there are contributions
        if (this.sum_contributions > 0) {
            valueDate = new Date(timeline_summary[i].date)
        } else {
            valueDate = new Date()
        }


        // Add empty square to match day
        for (let i = 0; i < this.minDate.getDay(); i++) {
            TimelineArray.push(
                {
                    "date": new Date(currentDate),
                    "count": 0,
                    "level": 8
                }
            )
        }

        while (currentDate <= this.maxDate) {
            let count = 0
            let level = 0
            if (valueDate.getTime() == currentDate.getTime()) {
                count = timeline_summary[i].count

                i -= 1;
                if (i >= 0) {
                    valueDate = new Date(timeline_summary[i].date)
                }

            }

            if (count == 0) {
                level = 0
            } else if (count < 3) {
                level = 1
            } else if (count < 6) {
                level = 2
            } else if (count < 10) {
                level = 3
            } else {
                level = 4
            }

            TimelineArray.push(
                {
                    "date": new Date(currentDate),
                    "count": count,
                    "level": level
                }
            )
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return TimelineArray
    }

    updateYear(event) {
        const newSelectedYear = this.availableYears[event.target.value]

        if (newSelectedYear != this.selectedYear) {
            this.selectedYear = newSelectedYear
            this.updateGraph()
        }
    }

    updateGraph() {
        this.activityService.getActivitiesSummary(this.authenticationService.getTenant(), this.resourceId, this.resourceType, this.selectedYear).subscribe(r => {

            this.minDate = new Date(r.min_date)
            this.maxDate = new Date(r.max_date)

            this.sum_contributions = r.sum_contributions
            this.timelineArray = this.createTimelineArray(r.timeline_summary)
        });
    }
}