import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {ProfileComponent} from './profile.component';
import {ProfileRoutingModule} from './profile-routing.module';
import {CommonModule} from "@angular/common";
import {
    ClrCheckboxModule,
    ClrIconModule,
    ClrInputModule,
    ClrLoadingModule,
    ClrModalModule,
    ClrPasswordModule,
    ClrSignpostModule,
    ClrSpinnerModule,
    ClrTabsModule,
    ClrTreeViewModule,
    ClrComboboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrStepperModule,

} from "@clr/angular";
import {NgxEchartsModule} from "ngx-echarts";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ComponentsModule} from "../shared/components/components.module";

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        ProfileRoutingModule,
        CommonModule,
        ClrIconModule,
        ClrCheckboxModule,
        ClrTreeViewModule,
        ClrInputModule,
        ClrTabsModule,
        ClrSpinnerModule,
        NgxEchartsModule,
        ClrLoadingModule,
        ClrSignpostModule,
        ClrModalModule,
        ReactiveFormsModule,
        FormsModule,
        ClrPasswordModule,
        ClrComboboxModule,
        ClrDatagridModule,
        ClrDropdownModule,
        ClrStepperModule,
        ComponentsModule,

    ],
    declarations: [
        ProfileComponent
    ]
})
export class ProfileModule {
}
