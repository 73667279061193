import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProjectNewComponent} from './project/project-new.component';
import {JobNewComponent} from './job/job-new.component';
import {WorkflowNewComponent} from './workflow/workflow-new.component';
import {WorkspaceNewComponent} from './workspace/workspace-new.component';

const routes: Routes = [
    {
        path: 'new/project',
        component: ProjectNewComponent
    },
    {
        path: 'new/job',
        component: JobNewComponent
    },
    {
        path: 'new/workflow',
        component: WorkflowNewComponent
    },
    {
        path: 'new/workspace',
        component: WorkspaceNewComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NewRoutingModule {

}
