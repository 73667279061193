import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import '@cds/core/badge/register.js';
import {WorkspaceDetailsComponent} from './details/workspace-details.component';
import {WorkspaceListComponent} from './list/workspace-list.component';
import {WorkspaceResolver} from './workspace-resolver.service';
import {WorkspaceRoutingModule} from './workspace-routing.module';
import {
    ClrComboboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrInputModule,
    ClrModalModule,
    ClrRadioModule,
    ClrSelectModule,
    ClrSignpostModule,
    ClrSpinnerModule,
    ClrStepperModule,
    ClrTabsModule,
    ClrTreeViewModule
} from '@clr/angular';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {DirectivesModule} from '../shared/directives/directives.module';
import {ComponentsModule} from '../shared/components/components.module';
import {PipesModule} from '../shared/pipes/pipes.module';
import {EditableModule} from "@ngneat/edit-in-place";

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        WorkspaceRoutingModule,
        ClrDatagridModule,
        CommonModule,
        ClrModalModule,
        ClrSpinnerModule,
        ReactiveFormsModule,
        ClrStepperModule,
        ClrInputModule,
        ClrComboboxModule,
        ClrRadioModule,
        ClrSelectModule,
        ClrDropdownModule,
        ClrTreeViewModule,
        ClrTabsModule,
        ClrSignpostModule,
        DirectivesModule,
        ComponentsModule,
        PipesModule,
        EditableModule
    ],
    declarations: [
        WorkspaceDetailsComponent,
        WorkspaceListComponent
    ],
    providers: [
        WorkspaceResolver
    ]
})
export class WorkspaceModule {
}
