<clr-vertical-nav style="height: 100%;" *ngIf="(data && data.project) && path && path.startsWith('projects/:id')">
    <h4 style="padding-left: 0.6rem;margin-top: 0.2rem;margin-bottom: 9px;">{{data.project.name}}</h4>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        Overview
    </a>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}/jobs" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="applications"></cds-icon> Jobs
    </a>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}/workflows" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="ci-cd"></cds-icon> Workflows
    </a>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}/endpoints" routerLinkActive="active">
        <cds-icon shape="router"></cds-icon> Endpoints
    </a>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}/experiments" routerLinkActive="active">
        <span class="iconify-inline" data-icon="carbon:chemistry" style="font-size: 16px;"></span> Experiments
    </a>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}/metrics" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <cds-icon clrVerticalNavIcon shape="line-chart"></cds-icon> Metrics
    </a>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}/permissions" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <cds-icon clrVerticalNavIcon shape="users"></cds-icon> Users & permissions
    </a>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}/costs" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <cds-icon clrVerticalNavIcon shape="wallet"></cds-icon> Costs
    </a>
    <a clrVerticalNavLink routerLink="/projects/{{data.project.id}}/configuration" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <cds-icon clrVerticalNavIcon shape="cog"></cds-icon> Configuration
    </a>
</clr-vertical-nav>

<clr-vertical-nav style="height: 100%;" *ngIf="path && path.startsWith('advanced')">
    <h4 style="padding-left: 0.6rem;margin-top: 0.2rem;margin-bottom: 9px;">Advanced</h4>
    <a clrVerticalNavLink routerLink="/advanced" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">Overview</a>
    <a clrVerticalNavLink routerLink="/advanced/configuration" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <cds-icon clrVerticalNavIcon shape="cog"></cds-icon> Configuration</a>
    <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
        <cds-icon shape="dollar" clrVerticalNavIcon></cds-icon>
        Costs
        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink routerLink="/advanced/costs/budgets" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="wallet"></cds-icon> Budgets
            </a>
            <a clrVerticalNavLink routerLink="/advanced/costs/invoices" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="file"></cds-icon> Invoices
            </a>
            <a clrVerticalNavLink routerLink="/advanced/costs/transactions" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="wallet"></cds-icon> Transactions
            </a>
            <a clrVerticalNavLink routerLink="/advanced/costs/payment-methods" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="credit-card"></cds-icon> Payment methods
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
    <a clrVerticalNavLink routerLink="/advanced/runtimes" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="host-group"></cds-icon> Runtimes
    </a>
    <a clrVerticalNavLink routerLink="/advanced/libraries" routerLinkActive="active"><cds-icon clrVerticalNavIcon
            shape="application"></cds-icon> Libraries</a>

    <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
        <cds-icon shape="namespace" clrVerticalNavIcon></cds-icon> Resources
        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink routerLink="/advanced/resources/capacity" routerLinkActive="active">
                <cds-icon shape="landscape"></cds-icon> Capacity
            </a>
            <a clrVerticalNavLink routerLink="/advanced/resources/devices" routerLinkActive="active">
                <cds-icon shape="wifi"></cds-icon> Edge computing
            </a>
            <a clrVerticalNavLink routerLink="/advanced/resources/gpu" routerLinkActive="active">
                <cds-icon shape="storage-adapter"></cds-icon> GPU
            </a>
            <a clrVerticalNavLink routerLink="/advanced/resources/quotas" routerLinkActive="active">
                <cds-icon shape="slider"></cds-icon> Quotas
            </a>
            <a clrVerticalNavLink routerLink="/advanced/resources/instance-types" routerLinkActive="active">
                <cds-icon shape="vmw-app"></cds-icon> Instance types
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
    <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
        <cds-icon shape="bar-chart" clrVerticalNavIcon></cds-icon> Access
        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink routerLink="/advanced/access/endpoints" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="link"></cds-icon> Endpoints
            </a>
            <a clrVerticalNavLink routerLink="/advanced/access/bridges" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="connect"></cds-icon> Logical bridges
            </a>
            <a clrVerticalNavLink routerLink="/advanced/access/gateways" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="nvme"></cds-icon> Network gateways
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
    <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
        <cds-icon shape="bar-chart" clrVerticalNavIcon></cds-icon> Performances
        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink routerLink="/advanced/performances/recommendations" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="rewind"></cds-icon> Recommendations
            </a>
            <a clrVerticalNavLink routerLink="/advanced/performances/metrics" routerLinkActive="active">
                <cds-icon shape="line-chart"></cds-icon> Metrics
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>

    <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
        <cds-icon shape="shield" clrVerticalNavIcon></cds-icon>
        Security
        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink routerLink="/advanced/security/audit" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="rewind"></cds-icon> Audit
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/vaults" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="media-changer"></cds-icon> Vaults
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/secrets" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="key"></cds-icon> Secrets
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/firewall" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="firewall"></cds-icon> Firewall
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/users" routerLinkActive="active">
                <cds-icon shape="user"></cds-icon> Users
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/identities" routerLinkActive="active">
                <cds-icon shape="administrator"></cds-icon> Identities
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/groups" routerLinkActive="active">
                <cds-icon shape="users"></cds-icon> Groups
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/assignments" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="flag"></cds-icon> Assignments
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/roles" routerLinkActive="active">
                <cds-icon shape="process-on-vm"></cds-icon> Roles
            </a>
            <a clrVerticalNavLink routerLink="/advanced/security/directory" routerLinkActive="active">
                <cds-icon shape="organization"></cds-icon> External identity providers
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
</clr-vertical-nav>

<clr-vertical-nav style="height: 100%;" *ngIf="path && path.startsWith('data')">
    <h4 style="padding-left: 0.6rem;margin-top: 0.2rem;margin-bottom: 9px;">Data</h4>
    <a clrVerticalNavLink routerLink="/data" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        Overview
    </a>
    <a clrVerticalNavLink routerLink="/data/explorer" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <cds-icon shape="search"></cds-icon> Explorer
    </a>
    <a clrVerticalNavLink routerLink="/data/datawarehouses" routerLinkActive="active" routerLinkActive="active">
        <span class="iconify-inline" data-icon="maki:warehouse" style="font-size: 16px;"></span> Data Warehouses
    </a>
    <a clrVerticalNavLink routerLink="/data/buckets" routerLinkActive="active" routerLinkActive="active">
        <cds-icon shape="objects"></cds-icon> Object storages
    </a>
    <a clrVerticalNavLink routerLink="/data/catalogs" routerLinkActive="active" routerLinkActive="active">
        <cds-icon shape="book"></cds-icon> Catalogs
    </a>
    <a clrVerticalNavLink routerLink="/data/streams" routerLinkActive="active" routerLinkActive="active">
        <cds-icon shape="switch"></cds-icon> Streams
    </a>
    <a clrVerticalNavLink routerLink="/data/indicators" routerLinkActive="active" routerLinkActive="active">
        <cds-icon shape="scatter-plot"></cds-icon> Indicators
    </a>
</clr-vertical-nav>