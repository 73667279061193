import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {SupportHomeComponent} from "./home/support-home.component";
import {SupportRoutingModule} from './support-routing.module';
import {
    ClrComboboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrModalModule,
    ClrRadioModule,
    ClrSelectModule,
    ClrSpinnerModule,
    ClrStepperModule, ClrTabsModule,
    ClrTextareaModule
} from "@clr/angular";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "../shared/directives/directives.module";
import {TicketNewComponent} from "./new/ticket-new.component";
import {TicketDetailsComponent} from "./details/ticket-details.component";
import {TicketResolver} from "./ticket-resolver.service";

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        SupportRoutingModule,
        ClrDatagridModule,
        CommonModule,
        ClrIconModule,
        ClrDropdownModule,
        ClrModalModule,
        ClrSpinnerModule,
        ClrStepperModule,
        ClrInputModule,
        ReactiveFormsModule,
        ClrRadioModule,
        FormsModule,
        ClrTextareaModule,
        ClrSelectModule,
        DirectivesModule,
        ClrComboboxModule,
        ClrTabsModule
    ],
    declarations: [
        SupportHomeComponent,
        TicketNewComponent,
        TicketDetailsComponent
    ],
    providers: [
        TicketResolver
    ]
})
export class SupportModule {
}
