<div class="clr-row">
    <div class="clr-col-12">
        <p><a [routerLink]="['/support']">Support</a> > Create a new ticket</p>
        <br/>
        <h1 style="display: inline">
            Create a new ticket
        </h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-12">
        <form clrStepper [formGroup]="form" (ngSubmit)="submit()">
            <clr-stepper-panel formGroupName="basic">
                <clr-step-title>Basic informations</clr-step-title>
                <!--                <clr-step-description>Description goes here.</clr-step-description>-->
                <clr-step-content *clrIfExpanded>
                    <clr-input-container>
                        <label>Title</label>
                        <input clrInput formControlName="title"/>
                        <clr-control-error *clrIfError="'required'">Title Required</clr-control-error>
                    </clr-input-container>

                    <clr-textarea-container>
                        <label>Description</label>
                        <textarea clrTextarea formControlName="description" style="height: 300px;width: 800px;">

                        </textarea>
                        <clr-control-error *clrIfError="'required'">Description Required</clr-control-error>
                    </clr-textarea-container>

                    <clr-radio-container>
                        <label>Ticket type</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="issue" formControlName="type"/>
                            <label>Issue</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="quota" formControlName="type"/>
                            <label>Quota</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="details">
                <clr-step-title>Details</clr-step-title>
                <!--                <clr-step-description>Description goes here.</clr-step-description>-->
                <clr-step-content *clrIfExpanded>

                    <ng-container *ngIf="form.get('basic').get('type').value === 'issue'">
                        <clr-combobox-container>
                            <label>Severity</label>
                            <clr-combobox formControlName="severity" name="severity" required>
                                <clr-options>
                                    <clr-option clrValue="p1_minimal">
                                        Priority 1 / Minimal
                                    </clr-option>
                                    <clr-option clrValue="p2_moderate">
                                        Priority 2 / Moderate
                                    </clr-option>
                                    <clr-option clrValue="p3_critical">
                                        Priority 3 / Critical
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-combobox-container>

                        <clr-input-container>
                            <label>Resource type</label>
                            <input clrInput formControlName="resource_type"/>
                            <clr-control-helper>The type of the resource</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <clr-input-container>
                            <label>Resource id</label>
                            <input clrInput formControlName="resource_id"/>
                            <clr-control-helper>The id of the resource</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>
                    </ng-container>

                    <ng-container *ngIf="form.get('basic').get('type').value === 'quota'">
                        <clr-combobox-container>
                            <label>Quota</label>
                            <clr-combobox formControlName="scope" name="scope" required>
                                <clr-options>
                                    <clr-option clrValue="workspaces.max">
                                        Maximum workspaces
                                    </clr-option>
                                    <clr-option clrValue="workflows.max">
                                        Maximum workflows
                                    </clr-option>
                                    <clr-option clrValue="jobs.max">
                                        Maximum jobs
                                    </clr-option>
                                    <clr-option clrValue="secrets.max">
                                        Maximum secrets
                                    </clr-option>
                                    <clr-option clrValue="network.gateway.max">
                                        Maximum gateway
                                    </clr-option>
                                    <clr-option clrValue="buckets.max">
                                        Maximum buckets
                                    </clr-option>
                                    <clr-option clrValue="users.max">
                                        Maximum users
                                    </clr-option>
                                    <clr-option clrValue="bridges.max">
                                        Maximum bridges
                                    </clr-option>
                                    <clr-option clrValue="secrets.max">
                                        Maximum secrets
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-combobox-container>
                        <clr-input-container>
                            <label>New value</label>
                            <input clrInput formControlName="new_value" required/>
                            <clr-control-helper>New value of the quota</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>
                    </ng-container>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="valid">
                <clr-step-title>Summary</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <p>Name: {{form.get('basic').get('title').value}}</p>
                    <p>Description: {{form.get('basic').get('description').value}}</p>

                    <button clrStepButton="submit">submit</button>
                </clr-step-content>
            </clr-stepper-panel>
        </form>

    </div>
</div>
