import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../../auth/authentication.service';
import {FollowService, User1, UserService} from '../../../../generated';
import {assignUserIcon, ClarityIcons, pinIcon} from '@cds/core/icon';

ClarityIcons.addIcons(assignUserIcon, pinIcon);

@Component({
    selector: 'app-follow',
    templateUrl: './follow.component.html'
})
export class FollowComponent implements OnInit {

    @Input()
    resourceId: string;
    @Input()
    resourceType: string;
    follow: boolean;

    @Output() reload: EventEmitter<boolean> = new EventEmitter();
    followers: Array<User1>;

    constructor(private authenticationService: AuthenticationService,
                private userService: UserService,
                private followService: FollowService
    ) {
    }

    ngOnInit(): void {
        this.userService.findCurrentUser(this.authenticationService.getTenant()).subscribe(u => {
            // check if resource is followed
            this.follow = false;
            switch (this.resourceType) {
                case 'project':
                    this.followService.getProjectFollowers(this.authenticationService.getTenant(), this.resourceId)
                        .subscribe(r => {
                            this.followers = r;
                            this.follow = this.followers.map(u => u.id).filter(i => i === u.id).length > 0;
                        });
                    break;
                case 'user':
                    this.followService.getUserFollowers(this.authenticationService.getTenant(), this.resourceId)
                        .subscribe(r => {
                            this.followers = r;
                            this.follow = this.followers.map(u => u.id).filter(i => i === u.id).length > 0;
                        });
                    break;
            }
        });
    }

    editFollow(follow: boolean): void {
        switch (this.resourceType) {
            case 'project':
                if (follow) {
                    this.followService.createProjectFollower(this.authenticationService.getTenant(), this.resourceId)
                        .subscribe(r => {
                            this.followOrUnfollow();
                        });
                } else {
                    this.followService.deleteFollowingProject(this.authenticationService.getTenant(), this.resourceId)
                        .subscribe(r => {
                            this.followOrUnfollow();
                        });
                }
                break;
            case 'user':
                if (follow) {
                    this.followService.createUserFollower( this.authenticationService.getTenant(), this.resourceId)
                        .subscribe(r => {
                            this.followOrUnfollow();
                        });
                } else {
                    this.followService.deleteFollowingUser(this.authenticationService.getTenant(), this.resourceId)
                        .subscribe(r => {
                            this.followOrUnfollow();
                        });
                }
                break;
        }
    }

    private followOrUnfollow(): void {
        this.follow = !this.follow;
        this.reload.emit(this.follow);
    }

    followResource(): void {
        this.editFollow(true);
    }

    unfollowResource(): void {
        this.editFollow(false);
    }
}
