import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from "../../auth/authentication.service";


@Component({
    selector: 'app-data-indicators-page',
    templateUrl: './data-indicators.component.html'
})
export class DataIndicatorsComponent implements OnInit {

    constructor(private authenticationService:AuthenticationService, private route: ActivatedRoute) {
    }

    ngOnInit() {
    }
}
