
<div class="clr-row">
    <div class="clr-col-3">
        <div class="card">
            <div class="card-block">
                <div class="card-media-block wrap">
                    <div class="card-media-description" style="height: 436px">
                        <h4 class="card-title">Total budget
                            <clr-spinner *ngIf="!totalBudgetReady" [clrInline]="true"></clr-spinner>
                        </h4>
                        <div *ngIf="totalBudgetReady" echarts [theme]="theme()"
                             [options]="totalBudget" class="demo-chart"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-9">
        <div class="card">
            <div class="card-block">
                <div class="card-media-block wrap">
                    <div class="card-media-description" style="height: 436px">
                        <h4 class="card-title">Budget
                            <clr-spinner *ngIf="!budgetReady" [clrInline]="true"></clr-spinner>
                        </h4>
                        <div *ngIf="budgetReady" echarts [theme]="theme()"
                             [options]="budget" class="demo-chart"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <clr-stack-view style="margin-top: 24px;">
            <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                <clr-stack-label>Details</clr-stack-label>
                <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">
                    <clr-stack-label ></clr-stack-label>
                    <clr-stack-content>

                        <table class="table table-noborder">
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Reference</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total price</th>
                                <th>Created</th>
                                <th>Dimensions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let usage of usages">
                                <td>{{usage.product_type}}</td>
                                <td>{{usage.product_reference}}</td>
                                <td>{{pricesFor(usage).amount.amount}}{{pricesFor(usage).amount.currency}}/month</td>
                                <td>{{usage.quantity}} minutes</td>
                                <td>{{(usage.quantity * parseFloat(pricesFor(usage).amount.amount) / MINUTES_IN_MONTH).toFixed(4)}}{{pricesFor(usage).amount.currency}}</td>
                                <td>{{usage.created | date:'medium' }}</td>
                                <td>
                                    <p style="margin-top: 0px;" *ngIf="usage.dimensions.workspace_id">workspace: {{usage.dimensions.workspace_id }}</p>
                                    <p style="margin-top: 0px;" *ngIf="usage.dimensions.project_id">project: {{usage.dimensions.project_id }}</p>
                                    <p style="margin-top: 0px;" *ngIf="usage.dimensions.job_id">run: {{usage.dimensions.job_id }}</p>
                                    <p style="margin-top: 0px;" *ngIf="usage.dimensions.instances">instances: {{usage.dimensions.instances }}</p>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </clr-stack-content>
                </clr-stack-block>
            </clr-stack-block>
        </clr-stack-view>

    </div>
</div>
