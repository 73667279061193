<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'azure'"><span class="iconify-inline" data-icon="logos:microsoft-azure"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'s3'"><span class="iconify-inline" data-icon="mdi:aws"
            style="color: white;font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'managed'"><span class="iconify-inline" data-icon="mdi:aws"
                                             style="color: white;font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'gcs'"><span class="iconify-inline" data-icon="logos:google-cloud"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchDefault><cds-icon shape="data-cluster"></cds-icon> {{word}}</ng-container>
    <!-- TODO: missing size="{{size}}" -->
</ng-container>
