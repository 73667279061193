<clr-modal [(clrModalOpen)]="_openCommentModal" [clrModalClosable]="true" [clrModalStaticBackdrop]="false">
    <div class="modal-body" style="padding-top: -7px;">
        <form [formGroup]="commentForm" (ngSubmit)="addComment()">
            <div class="clr-row">
                <div class="clr-col-12">
                    <clr-input-container>
                        <label>Comment</label>
                        <input clrInput formControlName="comment" required />
                        <clr-control-error>This field cannot be empty!</clr-control-error>
                    </clr-input-container>
                </div>
            </div>
            <button type="submit" class="btn btn-primary">
                Add comment
            </button>
        </form>
    </div>
</clr-modal>

<div class="clr-col-12" style="margin-left: 48px; margin-top: 24px;">
    <ng-container *ngFor="let comment of comments">
        <div class="clr-row">
            <h6 style="display: inline; margin-top: 8px;">
                <a [routerLink]="['/advanced/security/users', comment.user_id]"><app-user-fullname
                        [userId]="comment.user_id"></app-user-fullname></a>
                - {{ comment.create_time | dateAgo }} <a (click)="deleteComment(comment)"
                    *ngIf="comment.user_id === authUser.id"><cds-icon shape="trash"
                        style="color: red;margin-top: -6px;"></cds-icon></a>
            </h6>
        </div>
        <div class="clr-row" style="margin-top: -24px;">
            <p>{{ comment.comment }}</p>
        </div>
    </ng-container>
    <div class="clr-row" style="margin-bottom: 0px; margin-top: -12px; margin-left: -12px;">
        <p>
            <a (click)="openCommentModal(activity)">
                <cds-icon shape="add-text"></cds-icon> Add comment
            </a>
        </p>
    </div>
</div>
