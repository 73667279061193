import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CopyClipboardDirective} from './copyclipboard/copy-clipboard.directive';
import {EditableDirective} from './editable/editable.directive';
import {HasPermissionDirective} from './has_permission/has_permission.directive';

@NgModule({
    declarations: [
        CopyClipboardDirective,
        EditableDirective,
        HasPermissionDirective
    ],
    exports: [
        CopyClipboardDirective,
        EditableDirective,
        HasPermissionDirective
    ],
    imports: [
        CommonModule
    ]
})
export class DirectivesModule {
}
