<clr-modal [(clrModalOpen)]="openDeleteModal">
    <h3 class="modal-title">Delete the {{selected.length}} selected projects ?</h3>
    <div class="modal-body">
        <div class="alert alert-danger">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        Associated configurations, resources and jobs will be deleted
                    </span>
                </div>
            </div>
        </div>
        <ul cds-list *ngFor="let project of selected">
            <li> {{project.name}}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="deleting" (click)="delete();">
            <clr-spinner [clrSmall]="true" *ngIf="deleting" style="margin-top: 7px;"></clr-spinner>
            <ng-template [ngIf]="!deleting">Ok</ng-template>
        </button>
    </div>
</clr-modal>
<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <h1 style="float:left">Projects</h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <clr-dg-action-bar style="margin-top: 0rem;">
            <div class="btn-group" *ngIf="selected.length == 0">
                <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/new/project']"
                    *hasPermission="'/project/create'; id '_tenant'; type 'tenant'">
                    <cds-icon shape="plus"></cds-icon>
                    Create
                </button>
            </div>
            <div class="btn-group" *ngIf="selected.length == 0">
                <button type="button" class="btn btn-link btn-sm btn-secondary" (click)="viewAsList = !viewAsList">
                    <cds-icon shape="bars"></cds-icon>
                    {{!viewAsList ? 'Show as list' : 'Show as cards'}}
                </button>
            </div>
            <div class="btn-group" *ngIf="selected.length > 0">
                <button type="button" class="btn btn-link btn-sm btn-secondary" (click)="openDeleteModal = true"
                    *ngIf="selected.length >= 1">
                    <cds-icon shape="window-close"></cds-icon>
                    Delete
                </button>
                <cds-divider orientation="vertical"></cds-divider>
            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-link btn-sm btn-secondary" (click)="refresh()">
                    <cds-icon shape="refresh"></cds-icon>
                    Refresh
                </button>
                <clr-dropdown>
                    <button type="button" class="btn btn-link btn-sm btn-secondary" clrDropdownTrigger>
                        Export
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <button type="button" (click)="onExportAll()" clrDropdownItem>Export All</button>
                        <button type="button" (click)="onExportSelected()" [disabled]="selected.length === 0"
                            clrDropdownItem>
                            Export Selected Items
                        </button>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </div>
        </clr-dg-action-bar>

        <ng-template [ngIf]="!viewAsList">
            <div class="clr-row">
                <div class="clr-col-lg-3 clr-col-12"
                    *ngFor="let project of projects; let index = index; let isFirst = first; let isOdd = odd;">
                    <a [routerLink]="['/projects', project.id]" class="card clickable">
                        <div class="card-img" style="max-height: 170px; overflow: hidden;">
                            <!--                        class="gl-banner" width="1200px"-->
                            <img [src]="project.id | project_banner | async"
                                style="background: {{project.badge}}; background: linear-gradient(101deg, #232e34 0%, {{project.badge}} 82%);min-height: 170px;">
                            <!--                        <img src="assets/img{{ index + 1 }}.jpg" height="150"/>-->
                        </div>
                        <div class="card-block">
                            <div class="card-title">
                                {{project.name}}
                            </div>
                            <p class="card-text">
                                {{project.description}}
                            </p>
                            <p class="card-footer"
                                style="padding-left: 0px; padding-right: 0px;margin-top: 0px;padding-top: 0px;">
                                <a *ngFor="let mapEntry of project.labels | keyvalue"
                                    class="label label-blue">{{mapEntry.key}}:{{mapEntry.value}}</a>
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </ng-template>

        <ng-template [ngIf]="viewAsList">
            <clr-datagrid [(clrDgSelected)]="selected" class="datagrid-compact">
                <clr-dg-column>
                    <ng-container *clrDgHideableColumn="{hidden: false}">
                        Project ID
                    </ng-container>
                </clr-dg-column>
                <clr-dg-column>
                    <ng-container *clrDgHideableColumn="{hidden: false}">
                        Name
                    </ng-container>
                </clr-dg-column>
                <clr-dg-column>
                    <ng-container *clrDgHideableColumn="{hidden: false}">
                        Labels
                    </ng-container>
                </clr-dg-column>
                <clr-dg-column>
                    <ng-container *clrDgHideableColumn="{hidden: false}">
                        Creation date
                    </ng-container>
                </clr-dg-column>
                <clr-dg-column>Description</clr-dg-column>

                <clr-dg-row *clrDgItems="let project of projects" [clrDgItem]="project">
                    <clr-dg-cell><a [routerLink]="['/projects', project.id]">{{project.id}}</a></clr-dg-cell>
                    <clr-dg-cell>{{project.name}}</clr-dg-cell>
                    <clr-dg-cell><a *ngFor="let mapEntry of project.labels | keyvalue"
                            class="label label-blue">{{mapEntry.key}}:{{mapEntry.value}}</a></clr-dg-cell>
                    <clr-dg-cell>{{project.created | date:'medium'}}</clr-dg-cell>
                    <clr-dg-cell>{{project.description}}</clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>{{projects.length}} users</clr-dg-footer>
            </clr-datagrid>
        </ng-template>
    </div>
</div>
