import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DataHubComponent} from './hub/data-hub.component';
import {DataExplorerComponent} from './explorer/data-explorer.component';
import {DataIndicatorsComponent} from './indicators/data-indicators.component';
import {AuthGuard} from '../auth/auth.guard';
import {DataHomeComponent} from './home/data-home.component';

const routes: Routes = [
    {
        path: 'data',
        component: DataHomeComponent
    },
    {
        path: 'data/hub',
        component: DataHubComponent
    },
    {
        path: 'data/explorer',
        component: DataExplorerComponent
    },
    {
        path: 'data/indicators',
        component: DataIndicatorsComponent
    },
    {
        path: 'data/buckets',
        loadChildren: () => import('./bucket/bucket.module').then(m => m.BucketModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'data/streams',
        loadChildren: () => import('./stream/stream.module').then(m => m.StreamModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'data/catalogs',
        loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'data/datawarehouses',
        loadChildren: () => import('./datawarehouse/datawarehouse.module').then(m => m.DatawarehouseModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DataRoutingModule {

}
