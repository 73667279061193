<clr-modal [(clrModalOpen)]="openErrorModal">
    <h3 class="modal-title">Error</h3>
    <div class="modal-body">
        <p>The job {{form.get('basic').get('name').value}} can not be created.</p>
        <div class="alert alert-danger">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <ng-container *ngIf="internalError">
                        <span class="alert-text">{{internalError.message}}</span>
                    </ng-container>
                    <ng-container *ngIf="!internalError">
                        <span class="alert-text">
                            Internal error, please contact the support
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="openErrorModal = false">Ok</button>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-12">
        <p>Jobs > Add a new job</p>
        <br />
        <h1 style="display: inline">
            Add a new job
        </h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-12">
        <br />
        <form clrStepper [formGroup]="form" (ngSubmit)="submit()">
            <clr-stepper-panel formGroupName="basic">
                <clr-step-title>Basic informations</clr-step-title>
                <clr-step-content>

                    <div class="progress top" style="margin-top: 13px;" *ngIf="uploadProgress">
                        <progress [value]="uploadProgress" max="100"></progress>
                    </div>
                    <clr-combobox-container *ngIf="!project && projects">
                        <label>Project</label>
                        <clr-combobox formControlName="project_id" name="project">
                            <clr-options>
                                <clr-option *clrOptionItems="let project of projects; field:'name'"
                                    [clrValue]="project">
                                    {{project.name}}
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <clr-control-helper>The job will be added under this project</clr-control-helper>
                        <clr-control-error *clrIfError="'required'">Project Required</clr-control-error>
                    </clr-combobox-container>

                    <clr-input-container>
                        <label>Name</label>
                        <input clrInput formControlName="name" />
                        <clr-control-error *clrIfError="'required'">Name Required</clr-control-error>
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description</label>
                        <input clrInput formControlName="description" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <label>Continuous Job</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="ctJob" formControlName="continuous_job"/>
                        </clr-toggle-wrapper>
                        <clr-control-helper>Toggle to enable continuous running job.</clr-control-helper>
                    </clr-toggle-container>

                    <clr-stack-view style="margin-top: 24px;">
                        <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                            <clr-stack-label>Advanced</clr-stack-label>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3"
                                [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">
                                <clr-stack-label></clr-stack-label>
                                <clr-stack-content>
                                    <h3>Labels</h3>
                                    <p>
                                        <cds-icon shape="error-standard"></cds-icon>
                                        Labels of the job, as tuple of key/value
                                    </p>
                                    <div class="clr-row" formArrayName="labels"
                                        *ngFor="let field of getLabel(); let ind = index;">
                                        <ng-container [formGroupName]="ind">
                                            <div class="clr-col-3">
                                                <div class="clr-form-control">
                                                    <div class="clr-control-container">
                                                        <div class="clr-input-wrapper">
                                                            <input type="email" class="form-control"
                                                                formControlName="key" class="clr-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clr-col-3">
                                                <div class="clr-form-control">
                                                    <div class="clr-control-container">
                                                        <div class="clr-input-wrapper">
                                                            <input type="email" class="form-control"
                                                                formControlName="value" class="clr-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clr-col-2">
                                                <button class="btn btn-danger-outline btn-sm" type="button"
                                                    (click)="removeLabel(ind)" style="margin-top:30px;">
                                                    Remove
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <button class="btn btn-outline btn-sm" type="button" (click)="addLabel()">Add
                                        label</button>
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </clr-stack-view>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="options">
                <clr-step-title>Options</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <h3>Runtime</h3>
                    <clr-radio-container (change)="onImageChange($event)">
                        <!-- Uncomment to add dynamic runtimes -->
                        <!-- <clr-radio-wrapper *ngFor="let runtime of runtimes; let index = index">
                            <input type="radio" clrRadio required value="{{runtime.technical_name}}"
                                formControlName="type" />
                            <label>
                                <span class="iconify-inline" [attr.data-icon]="runtime.icon"
                                    style="font-size: 20px;"></span>
                                {{runtime.name}}
                                <cds-icon *ngIf="runtime.status == 'beta'" size="md" style="color: orange"
                                    shape="beta"></cds-icon>
                                <cds-icon *ngIf="runtime.status == 'new'" size="md" style="color: blueviolet"
                                    shape="new"></cds-icon>
                            </label>
                        </clr-radio-wrapper> -->
                        <!-- No ngIf for Spark as it can be a continuous and non-continuous job-->
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="spark" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="cib:apache-spark"
                                    style="font-size: 20px;"></span> Spark</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="python" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="bx:bxl-python"
                                    style="font-size: 20px;"></span> Python</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="dask" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="simple-icons:dask"
                                    style="font-size: 20px;"></span> Dask</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="mxnet" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="bx:bxl-python"
                                    style="font-size: 20px;"></span> MXNet</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="tensorflow" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="cib:tensorflow"
                                    style="font-size: 20px;"></span> Tensorflow</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="pytorch" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="cib:pytorch" style="font-size: 20px;"></span>
                                PyTorch</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="lowcode" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="ic:outline-code-off"
                                    style="font-size: 20px;"></span> Low code</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="ray" formControlName="type" />
                            <label> Ray <cds-icon size="md" style="color: orange" shape="beta"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="xgboost" formControlName="type" />
                            <label> Distributed XGBoost <cds-icon size="md" style="color: orange"
                                    shape="beta"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="bash" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="codicon:terminal-bash"
                                    style="font-size: 20px;"></span> Generic (based on Ubuntu)</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="apachehadoop" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="simple-icons:apachehadoop"
                                    style="font-size: 20px;"></span> Apache Hadoop (Generic YARN Application) <cds-icon
                                    size="md" style="color: blueviolet" shape="new"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="apachemapreduce" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="simple-icons:apachehadoop"
                                    style="font-size: 20px;"></span> Apache Hadoop MapReduce <cds-icon size="md"
                                    style="color: blueviolet" shape="new"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="apachesqoop" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="simple-icons:apachehadoop"
                                    style="font-size: 20px;"></span> Apache Sqoop <cds-icon size="md"
                                    style="color: blueviolet" shape="new"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="flink" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="cib:apache-flink"
                                    style="font-size: 20px;"></span> Apache Flink <cds-icon size="md"
                                    style="color: blueviolet" shape="new"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="knime" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="file-icons:knime"
                                    style="font-size: 20px;"></span> KNIME <cds-icon size="md" style="color: blueviolet"
                                    shape="new"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="hive" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="simple-icons:apachehive"
                                    style="font-size: 20px;"></span> Apache Hive <cds-icon size="md"
                                    style="color: blueviolet" shape="new"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="spring-cloud-data-flow"
                                formControlName="type" />
                            <label><span class="iconify-inline" data-icon="cib:spring" style="font-size: 20px;"></span>
                                Spring Cloud Data Flow <cds-icon size="md" style="color: orange"
                                    shape="beta"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="spring-batch" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="cib:spring" style="font-size: 20px;"></span>
                                Spring Batch <cds-icon size="md" style="color: orange" shape="beta"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="akka-data-pipeline" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="file-icons:akka"
                                    style="font-size: 20px;"></span> Akka Data Pipeline <cds-icon size="md"
                                    style="color: orange" shape="beta"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="beam" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="cib:apache" style="font-size: 20px;"></span>
                                Apache Beam <cds-icon size="md" style="color: blueviolet"
                                    shape="new"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled" *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="pulsar" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="simple-icons:apachepulsar"
                                    style="font-size: 20px;"></span> Apache Pulsar Function <cds-icon size="md"
                                    style="color: orange" shape="beta"></cds-icon></label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="sas" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="file-icons:sas"
                                    style="font-size: 16px;"></span> SAS</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="database-migration" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="eos-icons:database-migration"
                                    style="font-size: 20px;"></span> Database Migration Tool</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === false">
                            <input type="radio" clrRadio required value="dbt" formControlName="type" />
                            <label><span class="iconify-inline" data-icon="simple-icons:dbt"
                                    style="font-size: 20px;"></span> DBT</label>
                        </clr-radio-wrapper>

                        <clr-radio-wrapper *ngIf="form.get('basic').get('continuous_job').value === true">
                            <input type="radio" clrRadio required value="docker" formControlName="type"/>
                            <label><span class="iconify-inline" data-icon="simple-icons:docker"
                                         style="font-size: 20px;"></span> Docker</label>
                        </clr-radio-wrapper>

                        <clr-control-helper>Choose the runtime</clr-control-helper>
                        <clr-control-error>Please select a runtime</clr-control-error>
                    </clr-radio-container>

                    <ng-container
                        *ngIf="form.get('options').get('type').value != 'database-migration' && form.get('options').get('type').value != 'lowcode' && form.get('options').get('type').value != 'spark' && form.get('options').get('type').value != 'knime' && form.get('options').get('type').value != 'bash' && form.get('options').get('type').value != 'sas' && form.get('options').get('type').value != 'flink' && form.get('options').get('type').value != 'docker'">

                        <clr-combobox-container>
                            <label>Libraries</label>
                            <clr-combobox formControlName="libraries" name="multiSelect" clrMulti="true">
                                <ng-container *clrOptionSelected="let selected">
                                    {{selected?.filename}}
                                </ng-container>
                                <clr-options>
                                    <clr-option *clrOptionItems="let library of libraries" [clrValue]="library">
                                        {{library.filename}}
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-helper>These libraries will be available under the /workspace folder
                            </clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-combobox-container>

                        <div class="btn-group">
                            <button type="button" class="btn btn-sm" (click)="uploader.click()">
                                <cds-icon shape="plus"></cds-icon>
                                Add
                            </button>
                        </div>
                        <input hidden type="file" #uploader (change)="librariesDoUploadFile($event)" />
                    </ng-container>

                    <clr-input-container hidden>
                        <label>Image</label>
                        <input clrInput formControlName="image" required size="60" />
                    </clr-input-container>

                    <clr-stack-view style="margin-top: 24px;">
                        <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                            <clr-stack-label>Advanced</clr-stack-label>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3"
                                [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">
                                <clr-stack-label></clr-stack-label>
                                <clr-stack-content>
                                    <h3>Environment variables</h3>
                                    <p>
                                        <cds-icon shape="error-standard"></cds-icon>
                                        Environment variables can be accessed via methods like <code
                                            class="language-python">os.getenv()</code> in Python or <code
                                            class="language-java">System.getEnv()</code> in Java.
                                    </p>
                                    <button class="btn btn-outline" type="button" (click)="fileInput.click()">Load
                                        variables from
                                        a file
                                        <input #fileInput type="file" accept=".env" #uploader
                                            (change)="loadEnvFromFile($event)" onclick="this.value=null;"
                                            style="display:none;" />
                                    </button>
                                    <div class="clr-row" formArrayName="env"
                                        *ngFor="let field of getEnv(); let ind = index;">
                                        <ng-container [formGroupName]="ind">
                                            <div class="clr-col-3">
                                                <div class="clr-form-control">
                                                    <label class="clr-control-label">Environment variable name</label>
                                                    <div class="clr-control-container">
                                                        <div class="clr-input-wrapper">
                                                            <input type="email" class="form-control"
                                                                formControlName="key" class="clr-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clr-col-3">
                                                <div class="clr-form-control">
                                                    <label class="clr-control-label">Value</label>
                                                    <div class="clr-control-container">
                                                        <div class="clr-input-wrapper">
                                                            <input type="email" class="form-control"
                                                                formControlName="value" class="clr-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clr-col-2" *ngIf="ind > 0">
                                                <button class="btn btn-danger-outline" type="button"
                                                    (click)="removeEnv(ind)" style="margin-top:30px;">
                                                    Remove
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <button class="btn btn-outline" type="button" (click)="addEnv()">Add environment
                                        variable</button>

                                    <h3>Parameters</h3>
                                    <p>
                                        <cds-icon shape="error-standard"></cds-icon>
                                        Parameters are passed to job as command-line arguments in the same order in
                                        which they’re sent.
                                        The indexing of the arguments starts at one, and the first argument can be
                                        accessed inside the a Bash script using <code class="language-shell">$1</code>.
                                        Similarly, the second argument can be accessed using $2, and so on.
                                        The main() method is typically the entry point for a Java class as the JVM
                                        passes the command line argument through the <code
                                            class="language-java">String[] args</code> parameter.
                                        The positional parameter refers to this representation of the arguments using
                                        their position.
                                    </p>
                                    <div class="clr-row" formArrayName="parameters"
                                        *ngFor="let field of getParameters(); let ind = index;">
                                        <ng-container [formGroupName]="ind">
                                            <div class="clr-col-3">
                                                <div class="clr-form-control">
                                                    <label class="clr-control-label">Parameter</label>
                                                    <div class="clr-control-container">
                                                        <div class="clr-input-wrapper">
                                                            <input type="email" class="form-control"
                                                                formControlName="key" class="clr-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clr-col-2" *ngIf="ind > 0">
                                                <button class="btn btn-danger-outline" type="button"
                                                    (click)="removeParameter(ind)" style="margin-top:30px;">
                                                    Remove
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <button class="btn btn-outline" type="button" (click)="addParameter()">Add
                                        parameter</button>

                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </clr-stack-view>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="specific">
                <clr-step-title>Specific configurations</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <ng-container *ngIf="form.get('options').get('type').value === 'knime'">
                        <h3>Workflow</h3>

                        <clr-input-container>
                            <label>Name</label>
                            <input clrInput formControlName="workflow" required />
                            <clr-control-helper>The name of the workflow inside the archive you want to
                                run</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <clr-combobox-container>
                            <label>Archive</label>
                            <clr-combobox formControlName="archive" name="archive" required>
                                <clr-options>
                                    <clr-option *clrOptionItems="let library of libraries; field:'filename'"
                                        [clrValue]="library">
                                        {{library.filename}}
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-helper>The ZIP archive containing your workflows.
                            </clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-combobox-container>

                        <h3>Preferences</h3>
                        <p>
                            <cds-icon shape="error-standard"></cds-icon>
                            Preferences contain default settings for your workflow.
                        </p>
                        <div class="clr-row" formArrayName="preferences"
                            *ngFor="let field of getPreferences(); let ind = index;">
                            <ng-container [formGroupName]="ind">
                                <div class="clr-col-3">
                                    <div class="clr-form-control">
                                        <label class="clr-control-label">Preferences property</label>
                                        <div class="clr-control-container">
                                            <div class="clr-input-wrapper">
                                                <input type="email" class="form-control" formControlName="key"
                                                    class="clr-input" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clr-col-3">
                                    <div class="clr-form-control">
                                        <label class="clr-control-label">Value</label>
                                        <div class="clr-control-container">
                                            <div class="clr-input-wrapper">
                                                <input type="email" class="form-control" formControlName="value"
                                                    class="clr-input" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clr-col-2" *ngIf="ind > 0">
                                    <button type="button" class="btn btn-danger-outline"
                                        (click)="removePreferences(ind)" style="margin-top:30px;">
                                        Remove
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <button type="button" class="btn btn-outline" (click)="addPreferences()">Add preference</button>
                    </ng-container>

                    <ng-container *ngIf="form.get('options').get('type').value === 'spark'">
                        <h3>Entrypoint</h3>

                        <clr-combobox-container>
                            <label>Main library</label>
                            <clr-combobox formControlName="main_library" name="main_library" required>
                                <clr-options>
                                    <clr-option *clrOptionItems="let library of libraries; field:'filename'"
                                        [clrValue]="library">
                                        {{library.filename}}
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-helper>This library will be used as the primary file for your Spark
                                submit.<b>For Python, you should set the main Python file here</b>
                            </clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-combobox-container>

                        <div class="btn-group">
                            <button type="button" class="btn btn-sm" (click)="mainsparkuploader.click()">
                                <cds-icon shape="plus"></cds-icon>
                                Add
                            </button>
                        </div>
                        <input hidden type="file" #mainsparkuploader (change)="mainDoUploadFile($event)" />

                        <clr-input-container>
                            <label>Main class name</label>
                            <input clrInput formControlName="main_class_name" />
                            <clr-control-helper>The name of the class you want to run. <b>Only for Java and Scala, leave
                                    empty for Python</b></clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <clr-combobox-container>
                            <label>Python files</label>
                            <clr-combobox formControlName="py_files" name="multiSelect" clrMulti="true">
                                <ng-container *clrOptionSelected="let selected">
                                    {{selected?.filename}}
                                </ng-container>
                                <clr-options>
                                    <clr-option *clrOptionItems="let library of libraries" [clrValue]="library">
                                        {{library.filename}}
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-helper>The Python files will be added as <code
                                    class="language-bash">--py-files</code>.
                            </clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-combobox-container>

                        <div class="btn-group">
                            <button type="button" class="btn btn-sm" (click)="pyfileuploader.click()">
                                <cds-icon shape="plus"></cds-icon>
                                Add
                            </button>
                        </div>
                        <input hidden type="file" #pyfileuploader (change)="pyFilesDoUploadFile($event)" />

                        <clr-stack-view style="margin-top: 24px;">
                            <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3"
                                [clrStackViewPosinset]="1">
                                <clr-stack-label>Advanced</clr-stack-label>
                                <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3"
                                    [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">
                                    <clr-stack-label></clr-stack-label>
                                    <clr-stack-content>

                                        <h3>Configuration properties</h3>
                                        <p>
                                            <cds-icon shape="error-standard"></cds-icon>
                                            Configuration properties avoid to hard-code certain configurations in our
                                            SparkConf.
                                            Any values specified in the properties will be passed on to the application
                                            through
                                            <code class="language-bash">--conf</code>.
                                        </p>
                                        <div class="clr-row" formArrayName="conf"
                                            *ngFor="let field of getConf(); let ind = index;">
                                            <ng-container [formGroupName]="ind">
                                                <div class="clr-col-3">
                                                    <div class="clr-form-control">
                                                        <label class="clr-control-label">Configuration property</label>
                                                        <div class="clr-control-container">
                                                            <div class="clr-input-wrapper">
                                                                <input type="email" class="form-control"
                                                                    formControlName="key" class="clr-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clr-col-3">
                                                    <div class="clr-form-control">
                                                        <label class="clr-control-label">Value</label>
                                                        <div class="clr-control-container">
                                                            <div class="clr-input-wrapper">
                                                                <input type="email" class="form-control"
                                                                    formControlName="value" class="clr-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clr-col-2" *ngIf="ind > 0">
                                                    <button type="button" class="btn btn-danger-outline"
                                                        (click)="removeConf(ind)" style="margin-top:30px;">
                                                        Remove
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <button type="button" class="btn btn-outline" (click)="addConf()">Add
                                            configuration</button>

                                    </clr-stack-content>
                                </clr-stack-block>
                            </clr-stack-block>
                        </clr-stack-view>

                    </ng-container>

                    <ng-container *ngIf="form.get('options').get('type').value === 'flink'">
                        <h3>Entrypoint</h3>

                        <clr-combobox-container>
                            <label>Main library</label>
                            <clr-combobox formControlName="main_library" name="main_library" required>
                                <clr-options>
                                    <clr-option *clrOptionItems="let library of libraries; field:'filename'"
                                        [clrValue]="library">
                                        {{library.filename}}
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-helper>This library will be used as the primary file for your Flink application
                            </clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-combobox-container>

                        <div class="btn-group">
                            <button type="button" class="btn btn-sm" (click)="mainflinkuploader.click()">
                                <cds-icon shape="plus"></cds-icon>
                                Add
                            </button>
                        </div>
                        <input hidden type="file" #mainflinkuploader (change)="mainDoUploadFile($event)" />

                        <clr-input-container>
                            <label>Main class name</label>
                            <input clrInput formControlName="main_class_name" />
                            <clr-control-helper>The name of the class you want to run. Only for Java and Scala, leave
                                empty for Python</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>
                    </ng-container>

                    <ng-container
                        *ngIf="form.get('options').get('type').value === 'bash' || form.get('options').get('type').value === 'sas'">
                        <clr-textarea-container layout="vertical">
                            <label>Script</label>
                            <textarea clrTextarea formControlName="lines" required cols="80" rows="10"></textarea>
                        </clr-textarea-container>
                    </ng-container>

                    <ng-container *ngIf="form.get('options').get('type').value === 'database-migration'">

                        <clr-combobox-container>
                            <label>Data Warehouse</label>
                            <clr-combobox formControlName="datawarehouse_id" name="Data Warehouse">
                                <clr-options>
                                    <clr-option *clrOptionItems="let dataWarehouse of dataWarehouses"
                                        [clrValue]="dataWarehouse.id">
                                        {{dataWarehouse.name}} : {{dataWarehouse.id}}
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-helper>The new job will use the following data warehouse</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-combobox-container>

                        <clr-input-container>
                            <label>Url</label>
                            <input clrInput formControlName="url" />
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <clr-input-container>
                            <label>Path</label>
                            <input clrInput formControlName="path" />
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <clr-input-container>
                            <label>Revision</label>
                            <input clrInput formControlName="revision" />
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <clr-input-container>
                            <label>Username</label>
                            <input clrInput formControlName="username" />
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <clr-input-container>
                            <label>Password</label>
                            <input clrInput formControlName="password" />
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                    </ng-container>

                    <ng-container *ngIf="form.get('options').get('type').value === 'lowcode'">

                        <clr-combobox-container>
                            <label>Graph</label>
                            <clr-combobox formControlName="definition" name="definition"
                                (clrInputChange)="getCompatibleFramework($event)" required>
                                <clr-options>
                                    <clr-option *clrOptionItems="let graph of graph_list; field:'name'"
                                        [clrValue]="graph">
                                        {{ graph.name }} <span style="color: steelblue;">({{ graph.id }})</span>
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-error *clrIfError="'required'">This field cannot be empty!</clr-control-error>
                        </clr-combobox-container>

                        <clr-button-group>
                            <clr-button class="btn btn-link" (click)="goToPage('/draw')">
                                Create a Graph
                            </clr-button>
                        </clr-button-group>

                        <clr-combobox-container *ngIf="form.get('specific').get('definition').value != ''">
                            <label>Mode</label>
                            <clr-combobox formControlName="mode" name="mode" required>
                                <clr-options>
                                    <clr-option *clrOptionItems="let framework of compatibleFramework;"
                                        [clrValue]="framework">
                                        {{framework}}
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-error *clrIfError="'required'">This field cannot be empty!</clr-control-error>
                        </clr-combobox-container>

                    </ng-container>

                    <ng-container *ngIf="form.get('options').get('type').value === 'dbt'">

                        <clr-combobox-container>
                            <label>Command</label>
                            <clr-combobox formControlName="command" name="command" required>
                                <clr-options>
                                    <clr-option clrValue="build">
                                        Build
                                    </clr-option>
                                    <clr-option clrValue="run">
                                        Run
                                    </clr-option>
                                    <clr-option clrValue="test">
                                        Test
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-error *clrIfError="'required'">This field cannot be empty!</clr-control-error>
                        </clr-combobox-container>

                        <clr-combobox-container>
                            <label>Adapter</label>
                            <clr-combobox formControlName="adapter" name="adapter" required>
                                <clr-options>
                                    <clr-option clrValue="postgres">
                                        Postgres
                                    </clr-option>
                                    <clr-option clrValue="bigquery">
                                        Bigquery
                                    </clr-option>
                                    <clr-option clrValue="snowflake">
                                        Snowflake
                                    </clr-option>
                                    <clr-option clrValue="redshift">
                                        Redshift
                                    </clr-option>
                                    <clr-option clrValue="spark">
                                        Spark
                                    </clr-option>
                                    <clr-option clrValue="trino">
                                        Trino / Starbust
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <clr-control-error *clrIfError="'required'">This field cannot be empty!</clr-control-error>
                        </clr-combobox-container>

                        <clr-input-container>
                            <label>Target</label>
                            <input clrInput formControlName="target" required />
                            <clr-control-helper>The name of the target</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <clr-input-container>
                            <label>Profile</label>
                            <input clrInput formControlName="profile" required />
                            <clr-control-helper>The name of the profile you want to run</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>
                    </ng-container>

                    <ng-container
                        *ngIf="form.get('options').get('type').value === 'python' || form.get('options').get('type').value === 'tensorflow' || form.get('options').get('type').value === 'xgboost' || form.get('options').get('type').value === 'pytorch'">
                        <clr-input-container>
                            <label>Module</label>
                            <input clrInput formControlName="module" required />
                            <clr-control-helper>The name of the module you want to run</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>
                    </ng-container>

                    <ng-container *ngIf="form.get('options').get('type').value === 'dask'">

                        <clr-input-container>
                            <label>Package</label>
                            <input clrInput required formControlName="package_name" />
                            <clr-control-helper>The name of the package</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>
                        <clr-input-container>
                            <label>Module</label>
                            <input clrInput required formControlName="module_name" />
                            <clr-control-helper>The name of the module</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>
                        <clr-input-container>
                            <label>Function</label>
                            <input clrInput required formControlName="function_name" />
                            <clr-control-helper>The name of the function</clr-control-helper>
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>
                        <clr-range-container>
                            <label>Maximum of workers</label>
                            <input type="range" clrRange formControlName="number_workers_max" min="2" max="16" />
                            <clr-control-helper>Maximum number of workers for the job:
                                {{this.form.get('specific').get('number_workers_max').value}}
                            </clr-control-helper>
                        </clr-range-container>

                    </ng-container>

                    <ng-container *ngIf="form.get('options').get('type').value === 'docker'">

                        <clr-input-container>
                            <label>Docker image</label>
                            <input clrInput formControlName="docker_image" required />
                            <clr-control-helper>Provide the name of the Docker image to deploy. The image name needs to follow the following format: <code>username/image-name:tag</code>.</clr-control-helper>
                            <clr-control-error *clrIfError="'required'">Docker image required (format: <code>username/image-name:tag</code>).</clr-control-error>
                        </clr-input-container>

                        <clr-input-container>
                            <label>Port</label>
                            <input clrInput formControlName="docker_port" required/>
                            <clr-control-helper>Specify the port used to build the Docker image</clr-control-helper>
                            <clr-control-error *clrIfError="'required'">Port required</clr-control-error>
                        </clr-input-container>

                        <clr-input-container>
                            <label>Command</label>
                            <input clrInput formControlName="docker_command" />
                            <clr-control-error>There was an error</clr-control-error>
                        </clr-input-container>

                        <!-- <clr-textarea-container>
                            <label>Run command</label>
                            <textarea clrTextarea formControlName="docker_run_cmd" required></textarea>
                            <clr-control-helper>Enter custom run commands.</clr-control-helper>
                            <clr-control-error *clrIfError="'required'">Run commands are required</clr-control-error>
                        </clr-textarea-container> -->

                        <clr-textarea-container layout="vertical">
                            <label>Docker credentials</label>
                            <textarea clrTextarea placeholder="Enter docker credentials" formControlName="docker_credentials" cols="80" rows="10"></textarea>
                        </clr-textarea-container>
                    </ng-container>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="advanced">
                <clr-step-title>Advanced</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <clr-range-container *ngIf="form.get('basic').get('continuous_job').value == false">
                        <label>Timeout</label>
                        <input type="range" clrRange required formControlName="timeout_seconds" min="60" step="60"
                            max="21600" />
                        <clr-control-helper>Maximum duration of the job:
                            {{this.form.get('advanced').get('timeout_seconds').value/60}} minutes
                        </clr-control-helper>
                    </clr-range-container>

                    <clr-range-container>
                        <label>Retries</label>
                        <input type="range" clrRange required formControlName="max_retries" min="0" step="1" max="10" />
                        <clr-control-helper>Maximum retries in case of failure:
                            {{this.form.get('advanced').get('max_retries').value}}
                        </clr-control-helper>
                    </clr-range-container>

                    <!--                    <clr-combobox-container>-->
                    <!--                        <label>Secrets</label>-->
                    <!--                        <clr-combobox formControlName="secrets" name="multiSelect" clrMulti="true">-->
                    <!--                            <ng-container *clrOptionSelected="let selected">-->
                    <!--                                {{selected?.name}}-->
                    <!--                            </ng-container>-->
                    <!--                            <clr-options>-->
                    <!--                                <clr-option *clrOptionItems="let secret of secrets" [clrValue]="secret">-->
                    <!--                                    {{secret.name}}-->
                    <!--                                </clr-option>-->
                    <!--                            </clr-options>-->
                    <!--                        </clr-combobox>-->
                    <!--                        <clr-control-helper>These secrets will be available in the /secrets file</clr-control-helper>-->
                    <!--                        <clr-control-error>There was an error</clr-control-error>-->
                    <!--                    </clr-combobox-container>-->

                    <clr-stack-view style="margin-top: 24px;">
                        <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                            <clr-stack-label>Advanced</clr-stack-label>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3"
                                [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">
                                <clr-stack-label></clr-stack-label>
                                <clr-stack-content>
                                    <clr-toggle-container>
                                        <label>Create a new identity</label>
                                        <clr-toggle-wrapper>
                                            <input type="checkbox" clrToggle value="true"
                                                formControlName="auto_create_identity" />
                                        </clr-toggle-wrapper>
                                    </clr-toggle-container>

                                    <ng-container *ngIf="!form.get('advanced').get('auto_create_identity').value">
                                        <clr-combobox-container>
                                            <label>Identity</label>
                                            <clr-combobox formControlName="identity_id" name="Identity">
                                                <clr-options>
                                                    <clr-option *clrOptionItems="let identity of identities"
                                                        [clrValue]="identity.id">
                                                        {{identity.name}} : {{identity.id}}
                                                    </clr-option>
                                                </clr-options>
                                            </clr-combobox>
                                            <clr-control-helper>The new job will have the following
                                                identity</clr-control-helper>
                                            <clr-control-error>There was an error</clr-control-error>
                                        </clr-combobox-container>
                                    </ng-container>

                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </clr-stack-view>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="resources">
                <clr-step-title>Resources</clr-step-title>
                <clr-step-content *clrIfExpanded>
                    <clr-combobox-container *ngIf="instanceTypes">
                        <label>Launcher instance type</label>
                        <clr-combobox formControlName="instance_type">
                            <clr-options>
                                <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                    [clrValue]="instanceType.id">
                                    {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                    byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                    GPUs)
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                        <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                    </clr-combobox-container>
                    <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                        instanceInfo(form.get('resources').get('instance_type').value) }}</p>

                    <ng-container
                        *ngIf="form.get('options').get('type').value === 'dask' || form.get('options').get('type').value === 'mxnet' || form.get('options').get('type').value === 'pytorch'">
                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Worker instance type</label>
                            <clr-combobox formControlName="worker_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('worker_instance_type').value) }}</p>
                    </ng-container>


                    <clr-range-container
                        *ngIf="form.get('options').get('type').value === 'tensorflow' || form.get('options').get('type').value === 'xgboost' || form.get('options').get('type').value === 'mxnet' || form.get('options').get('type').value === 'pytorch'">
                        <label>Number of replicas</label>
                        <input type="range" clrRange formControlName="number_replicas" min="1" max="16" />
                        <clr-control-helper>Number of replicas for the job:
                            {{this.form.get('resources').get('number_replicas').value}}
                        </clr-control-helper>
                    </clr-range-container>

                    <ng-container
                        *ngIf="form.get('options').get('type').value === 'dask' || form.get('options').get('type').value === 'spark'">
                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Driver instance type</label>
                            <clr-combobox formControlName="driver_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--<clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('driver_instance_type').value) }}</p>
                    </ng-container>
                    <ng-container *ngIf="form.get('options').get('type').value === 'flink'">
                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Job Manager instance type</label>
                            <clr-combobox formControlName="job_manager_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('job_manager_instance_type').value) }}</p>

                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Task Manager instance type</label>
                            <clr-combobox formControlName="task_manager_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('task_manager_instance_type').value) }}</p>
                    </ng-container>
                    <ng-container *ngIf="form.get('options').get('type').value === 'mxnet'">
                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Server instance type</label>
                            <clr-combobox formControlName="server_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('server_instance_type').value) }}</p>

                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Scheduler instance type</label>
                            <clr-combobox formControlName="scheduler_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('scheduler_instance_type').value) }}</p>
                    </ng-container>
                    <ng-container *ngIf="form.get('options').get('type').value === 'tensorflow'">
                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Replica instance type</label>
                            <clr-combobox formControlName="replica_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('replica_instance_type').value) }}</p>
                    </ng-container>
                    <ng-container
                        *ngIf="form.get('options').get('type').value === 'spark' || form.get('options').get('type').value === 'lowcode'">
                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Executor instance type</label>
                            <clr-combobox formControlName="executor_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('executor_instance_type').value) }}</p>

                        <clr-range-container>
                            <label>Maximum of executors</label>
                            <input type="range" clrRange formControlName="number_executors" min="1" max="16" />
                            <clr-control-helper>Maximum number of executors for the job:
                                {{this.form.get('resources').get('number_executors').value}}
                            </clr-control-helper>
                        </clr-range-container>
                    </ng-container>
                    <ng-container
                        *ngIf="form.get('options').get('type').value === 'xgboost' || form.get('options').get('type').value === 'pytorch'">
                        <clr-combobox-container *ngIf="instanceTypes">
                            <label>Master instance type</label>
                            <clr-combobox formControlName="master_instance_type">
                                <clr-options>
                                    <clr-option *clrOptionItems="let instanceType of instanceTypes"
                                        [clrValue]="instanceType.id">
                                        {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory |
                                        byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}}
                                        GPUs)
                                    </clr-option>
                                </clr-options>
                            </clr-combobox>
                            <!--                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>-->
                            <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                        </clr-combobox-container>
                        <p style="margin-top: 0.2rem;margin-left: 12px;">{{
                            instanceInfo(form.get('resources').get('master_instance_type').value) }}</p>
                    </ng-container>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="schedule">
                <clr-step-title>Scheduling</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <clr-radio-container>
                        <!-- Trigger tekton 2 -->
                        <!--<label>Scheduling</label>-->
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="once" formControlName="type" />
                            <label>Manual execution</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="cron" formControlName="type" />
                            <label>Cron</label>
                        </clr-radio-wrapper>
                        <clr-control-helper>Choose the type</clr-control-helper>
                        <clr-control-error>Please select a scheduling</clr-control-error>
                    </clr-radio-container>

                    <clr-combobox-container *ngIf="this.form.get('schedule').get('type').value=='cron'">
                        <label>Infrastructure</label>
                        <clr-combobox formControlName="infrastructure_id" name="infrastructure" required>
                            <clr-options>
                                <clr-option *clrOptionItems="let infrastructure of infrastructures"
                                    [clrValue]="infrastructure.id">
                                    <span
                                        class="flag-icon flag-icon-{{infrastructure.country}} flag-icon-squared"></span>
                                    <app-infra-icon style="margin-left: 4px;" [type]="infrastructure.type"
                                        [displayTitle]="false" [size]="12"></app-infra-icon>
                                    {{infrastructure.name}}
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <clr-control-helper>The run will be executed on this infrastructure</clr-control-helper>
                        <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                    </clr-combobox-container>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <!--            <clr-stepper-panel formGroupName="notifications">-->
            <!--                <clr-step-title>Notifications</clr-step-title>-->
            <!--                <clr-step-content *clrIfExpanded>-->

            <!--                    <div class="clr-row" formArrayName="notifications"-->
            <!--                         *ngFor="let field of getNotification(); let ind = index;">-->
            <!--                        <ng-container [formGroupName]="ind">-->
            <!--                            <div class="clr-col-3">-->
            <!--                                <clr-radio-container>-->
            <!--                                    <label>Type</label>-->
            <!--                                    <clr-radio-wrapper>-->
            <!--                                        <input type="radio" clrRadio value="mail" formControlName="type"/>-->
            <!--                                        <label> Mail</label><br/>-->
            <!--                                    </clr-radio-wrapper>-->
            <!--                                    <clr-radio-wrapper>-->
            <!--                                        <input type="radio" clrRadio value="webhook" formControlName="type"/>-->
            <!--                                        <label> Webhook</label><br/>-->
            <!--                                    </clr-radio-wrapper>-->
            <!--                                    <clr-radio-wrapper>-->
            <!--                                        <input type="radio" clrRadio value="eventhub" formControlName="type"/>-->
            <!--                                        <label> Azure EventHub</label><br/>-->
            <!--                                    </clr-radio-wrapper>-->
            <!--                                    <clr-radio-wrapper>-->
            <!--                                        <input type="radio" clrRadio value="pubsub" formControlName="type"/>-->
            <!--                                        <label> Google PubSub</label><br/>-->
            <!--                                    </clr-radio-wrapper>-->
            <!--                                    <clr-radio-wrapper>-->
            <!--                                        <input type="radio" clrRadio value="sqs" formControlName="type"/>-->
            <!--                                        <label> AWS SQS</label><br/>-->
            <!--                                    </clr-radio-wrapper>-->
            <!--                                    <clr-radio-wrapper>-->
            <!--                                        <input type="radio" clrRadio value="kafka" formControlName="type"/>-->
            <!--                                        <label> Apache Kafka</label><br/>-->
            <!--                                    </clr-radio-wrapper>-->
            <!--                                    <clr-radio-wrapper>-->
            <!--                                        <input type="radio" clrRadio value="teams" formControlName="type"/>-->
            <!--                                        <label> Teams</label><br/>-->
            <!--                                    </clr-radio-wrapper>-->
            <!--                                    <clr-radio-wrapper>-->
            <!--                                        <input type="radio" clrRadio value="slack" formControlName="type"/>-->
            <!--                                        <label> Slack</label><br/>-->
            <!--                                    </clr-radio-wrapper>-->
            <!--                                </clr-radio-container>-->
            <!--                            </div>-->
            <!--                            <div class="clr-col-3">-->
            <!--                                <clr-checkbox-container clrInline="vertical">-->
            <!--                                    <label>On</label>-->
            <!--                                    <clr-checkbox-wrapper>-->
            <!--                                        <input type="checkbox" clrCheckbox value="start" formControlName="on_start"/>-->
            <!--                                        <label> Start</label>-->
            <!--                                    </clr-checkbox-wrapper>-->
            <!--                                    <clr-checkbox-wrapper>-->
            <!--                                        <input type="checkbox" clrCheckbox value="success" formControlName="on_success"/>-->
            <!--                                        <label> Success</label>-->
            <!--                                    </clr-checkbox-wrapper>-->
            <!--                                    <clr-checkbox-wrapper>-->
            <!--                                        <input type="checkbox" clrCheckbox value="failure" formControlName="on_failure"/>-->
            <!--                                        <label> Failure</label>-->
            <!--                                    </clr-checkbox-wrapper>-->
            <!--                                </clr-checkbox-container>-->
            <!--                            </div>-->
            <!--                            <div class="clr-col-6">-->

            <!--                                <ng-container *ngIf="getCurrentNotification(ind).get('type').value === 'mail'">-->
            <!--                                    <clr-input-container>-->
            <!--                                        <label>Email</label>-->
            <!--                                        <input clrInput formControlName="emailTo"/>-->
            <!--                                        <clr-control-error *clrIfError="'required'">Email required</clr-control-error>-->
            <!--                                    </clr-input-container>-->

            <!--                                    <clr-input-container>-->
            <!--                                        <label>Subject</label>-->
            <!--                                        <input clrInput formControlName="emailSubject"/>-->
            <!--                                        <clr-control-error *clrIfError="'required'">Subject required</clr-control-error>-->
            <!--                                    </clr-input-container>-->
            <!--                                </ng-container>-->

            <!--                                <ng-container *ngIf="getCurrentNotification(ind).get('type').value === 'webhook'">-->
            <!--                                    <clr-input-container>-->
            <!--                                        <label>Url</label>-->
            <!--                                        <input clrInput formControlName="webhookUrl"/>-->
            <!--                                    </clr-input-container>-->

            <!--                                    <clr-combobox-container>-->
            <!--                                        <label>Method</label>-->
            <!--                                        <clr-combobox formControlName="webhookMethod" name="Method">-->
            <!--                                            <clr-options>-->
            <!--                                                <clr-option *clrOptionItems="let method of methods" [clrValue]="method.name">-->
            <!--                                                    {{method.name}}-->
            <!--                                                </clr-option>-->
            <!--                                            </clr-options>-->
            <!--                                        </clr-combobox>-->
            <!--                                        <clr-control-helper>HTTP methods</clr-control-helper>-->
            <!--                                    </clr-combobox-container>-->

            <!--                                    <clr-input-container>-->
            <!--                                        <label>Headers</label>-->
            <!--                                    </clr-input-container>-->
            <!--                                    <div class="clr-row" formArrayName="webhookHeaders"-->
            <!--                                         *ngFor="let field of getHeader(ind); let indHeader = index;">-->
            <!--                                        <ng-container [formGroupName]="indHeader">-->
            <!--                                            <div class="clr-col-5">-->
            <!--                                                <clr-input-container>-->
            <!--                                                    <label>Key</label>-->
            <!--                                                    <input clrInput formControlName="key"/>-->
            <!--                                                </clr-input-container>-->
            <!--                                            </div>-->
            <!--                                            <div class="clr-col-5">-->
            <!--                                                <clr-input-container>-->
            <!--                                                    <label>Value</label>-->
            <!--                                                    <input clrInput formControlName="value"/>-->
            <!--                                                </clr-input-container>-->
            <!--                                            </div>-->
            <!--                                            <div class="clr-col-2" *ngIf="indHeader > 0">-->
            <!--                                                <button class="btn btn-danger-outline" type="button" (click)="removeHeader(ind, indHeader)"-->
            <!--                                                        style="margin-top:30px;">-->
            <!--                                                    Remove-->
            <!--                                                </button>-->
            <!--                                            </div>-->
            <!--                                        </ng-container>-->
            <!--                                    </div>-->
            <!--                                    <button class="btn btn-outline" type="button" (click)="addHeader(ind)">Add Header-->
            <!--                                    </button>-->
            <!--                                </ng-container>-->
            <!--                            </div>-->
            <!--                            <div class="clr-col-2">-->
            <!--                                <button class="btn btn-danger-outline" type="button" (click)="removeNotification(ind)"-->
            <!--                                        style="margin-top:30px;">-->
            <!--                                    Remove-->
            <!--                                </button>-->
            <!--                            </div>-->
            <!--                            <cds-divider orientation="horizontal" style="margin-top: 10px" *ngIf="getNotificationCount() > 1"></cds-divider>-->
            <!--                        </ng-container>-->
            <!--                    </div>-->
            <!--                    <button class="btn btn-outline" type="button" (click)="addNotification()" style="margin-top: 10px">Add new notification</button>-->

            <!--                    <button clrStepButton="next">next</button>-->
            <!--                </clr-step-content>-->
            <!--            </clr-stepper-panel>-->

            <clr-stepper-panel formGroupName="valid">
                <clr-step-title>Summary</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <p>Name: {{form.get('basic').get('name').value}}</p>
                    <p>Description: {{form.get('basic').get('description').value}}</p>

                    <button clrStepButton="submit" [disabled]="creating">
                        <clr-spinner [clrSmall]="true" *ngIf="creating" style="margin-top: 7px;"></clr-spinner>
                        submit
                    </button>
                </clr-step-content>
            </clr-stepper-panel>
        </form>

    </div>
</div>