<span *ngIf="!timelineArray" class="spinner">
</span>
<div *ngIf="timelineArray">

    <h4>{{sum_contributions}} contributions in {{selectedYear}}</h4>
    <div style="display: flex">
        <div class="graph">
            <ul class="months">
                <li>Jan</li>
                <li>Feb</li>
                <li>Mar</li>
                <li>Apr</li>
                <li>May</li>
                <li>Jun</li>
                <li>Jul</li>
                <li>Aug</li>
                <li>Sep</li>
                <li>Oct</li>
                <li>Nov</li>
                <li>Dec</li>
            </ul>
            <ul class="days">
                <li>Sun</li>
                <li>Mon</li>
                <li>Tue</li>
                <li>Wed</li>
                <li>Thu</li>
                <li>Fri</li>
                <li>Sat</li>
            </ul>
            <ul class="squares">
                <li [ngClass]="'level_' + activityDay.level.toString()" *ngFor="let activityDay of timelineArray">
                </li>
            </ul>
        </div>
        <clr-radio-container style="padding-left:1em; margin-top: 0">
            <label>Year</label>
            <clr-radio-wrapper *ngFor="let year of availableYears; let i = index">
                <input type="radio" clrRadio name="options" value="{{i}}" (change)="updateYear($event)" />
                <label>{{year}}</label>
            </clr-radio-wrapper>
        </clr-radio-container>
    </div>
</div>