<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <p>Data > Indicators</p>
        <h1 style="display: inline">Indicators</h1>
    </div>
    <div class="clr-col-12 clr-col-sm-12">
        <div class="alert alert-info">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="alert-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        No indicator to display, please add some data to see your indicator.
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>