import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdvancedHomeComponent} from './home/advanced-home.component';
import {AuthGuard} from '../auth/auth.guard';

const routes: Routes = [
    {
        path: 'advanced',
        component: AdvancedHomeComponent
    },
    {
        path: 'advanced/runtimes',
        loadChildren: () => import('./runtime/runtime.module').then(m => m.RuntimeModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'advanced/access',
        loadChildren: () => import('./access/access.module').then(m => m.AccessModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'advanced/libraries',
        loadChildren: () => import('./library/library.module').then(m => m.LibraryModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'advanced/performances',
        loadChildren: () => import('./performances/performances.module').then(m => m.PerformancesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'advanced/costs',
        loadChildren: () => import('./cost/cost.module').then(m => m.CostModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'advanced/security',
        loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'advanced/resources',
        loadChildren: () => import('./resource/resource.module').then(m => m.ResourceModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'advanced/configuration',
        loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdvancedRoutingModule {
}
