<clr-modal [(clrModalOpen)]="openDeleteModal">
    <h3 class="modal-title">Delete "{{ticket.title}}" ?</h3>
    <div class="modal-body">
        <div class="alert alert-danger">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        Ticket will be deleted, not closed
                    </span>
                </div>
            </div>
        </div>
        <p>Please confirm before</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="deleting" (click)="delete();">
            <clr-spinner [clrSmall]="true" *ngIf="deleting" style="margin-top: 7px;"></clr-spinner>
            <ng-template [ngIf]="!deleting">Ok</ng-template>
        </button>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-12">
        <div cds-layout="vertical gap:lg">
            <p><a [routerLink]="['/support']">Tickets</a> > {{ticket.title}}</p>
            <ng-container [ngSwitch]="ticket.details.type">
                <h1 *ngSwitchCase="'subscription'" style="margin-top: 0.2rem;">
                    <cds-icon shape="crown" size="36"></cds-icon>
                    {{ ticket.title }}
                </h1>
                <h1 *ngSwitchCase="'issue'" style="margin-top: 0.2rem;">
                    <cds-icon shape="bug" size="36"></cds-icon>
                    {{ ticket.title }}
                </h1>
                <h1 *ngSwitchCase="'quota'" style="margin-top: 0.2rem;">
                    <cds-icon shape="deploy" size="36"></cds-icon>
                    {{ ticket.title }}
                </h1>
                <h1 *ngSwitchDefault style="margin-top: 0.2rem;">
                    <cds-icon shape="minus" size="36"></cds-icon>
                    {{ ticket.title }}
                </h1>
            </ng-container>
            <a class="label label-{{ticket.status === 'OPEN' ? 'success' : 'danger'}}">{{ticket.status}}</a>

            <h5 *ngIf="ticket.description" style="margin-top: -1.2rem;margin-bottom: 8px;">{{ ticket.description }}</h5>

            <clr-dg-action-bar style="margin-top: 0rem;">
                <div class="btn-group">
                    <button class="btn btn-sm btn-secondary btn-danger" (click)="openDeleteModal = true">
                        <cds-icon shape="times"></cds-icon>
                        Delete
                    </button>
                </div>
                <div class="btn-group">
                    <button class="btn btn-sm btn-link" (click)="refresh()">
                        <cds-icon shape="refresh"></cds-icon>
                        Refresh
                    </button>
                </div>
            </clr-dg-action-bar>
        </div>
    </div>
</div>
<div class="clr-row" style="margin-top: 13px;">
    <div class="clr-col-12">
        <clr-tabs>
            <clr-tab>
                <button clrTabLink>Summary</button>
                <clr-tab-content>
                    <div class="clr-row">
                        <div class="clr-col-12">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description">
                                            <div class class="clr-row">
                                                <div class="clr-col-3">
                                                    <h4 style="margin-top: 0.2rem;">Definition</h4>
                                                    <table class="table table-noborder" style="margin-top: 0.2rem;">
                                                        <tbody>
                                                            <tr>
                                                                <td class="left">Created</td>
                                                                <td class="left">{{ticket.created}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Updated</td>
                                                                <td class="left">{{ticket.updated}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <ng-container *ngIf="ticket.details.type === 'issue'">
                                                    <div class="clr-col-3">
                                                        <h4 style="margin-top: 0.2rem;">Issue</h4>
                                                        <table class="table table-noborder" style="margin-top: 0.2rem;">
                                                            <tbody>

                                                                <tr>
                                                                    <td class="left">Severity</td>
                                                                    <td class="left">{{$any(ticket.details).severity}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="left">Resource id</td>
                                                                    <td class="left">
                                                                        {{$any(ticket.details).resource_id}}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="left">Resource type</td>
                                                                    <td class="left">
                                                                        {{$any(ticket.details).resource_type}}
                                                                    </td>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
</div>