import {Component, OnInit, ViewChild} from '@angular/core';
import {ClrSignpostContent} from '@clr/angular';
import {Environment} from '../../environment';

@Component({
    selector: 'app-about-signpost',
    templateUrl: './signpost.component.html'
})
export class SignpostComponent implements OnInit {
    loading = true;
    @ViewChild('signpost') signpost: ClrSignpostContent;

    platformVersion: string;
    documentationUrl: string;

    constructor(
        private environment: Environment
    ) {

    }

    ngOnInit(): void {
        this.loading = false;
        this.platformVersion = this.environment.platformVersion;
        this.documentationUrl = this.environment.documentationUrl;
    }
}
