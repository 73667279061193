import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import '@cds/core/alert/register.js';
import '@cds/core/modal/register.js';
import {
    EnvService,
    NotificationService,
    Project,
    ProjectService,
    Result,
    SearchHistory,
    SearchService,
    Style,
    Tenant,
    User,
    UserService
} from '../generated';
import {Router} from '@angular/router';

import '@cds/core/icon/register.js';
import {
    addTextIcon,
    administratorIcon,
    angleIcon,
    applicationIcon,
    applicationsIcon,
    bankIcon,
    barChartIcon,
    barsIcon,
    bellIcon,
    betaIcon,
    blockIcon,
    bookIcon,
    bubbleExclamationIcon,
    bugIcon,
    buildingIcon,
    checkIcon,
    ciCdIcon,
    ClarityIcons,
    clockIcon,
    codeIcon,
    computerIcon,
    connectIcon,
    copyToClipboardIcon,
    cpuIcon,
    creditCardIcon,
    crownIcon,
    cursorHandOpenIcon,
    dataClusterIcon,
    deployIcon,
    dollarIcon,
    downloadIcon,
    ellipsisHorizontalIcon,
    fileIcon,
    firewallIcon,
    flagIcon,
    flameIcon,
    folderOpenIcon,
    historyIcon,
    homeIcon,
    hostGroupIcon,
    infoCircleIcon,
    keyIcon,
    landscapeIcon,
    lineChartIcon,
    linkIcon,
    mapMarkerIcon,
    mediaChangerIcon,
    memoryIcon,
    namespaceIcon,
    newIcon,
    nodesIcon,
    nvmeIcon,
    objectsIcon,
    organizationIcon,
    pencilIcon,
    playIcon,
    plusCircleIcon,
    plusIcon,
    popOutIcon,
    processOnVmIcon,
    refreshIcon,
    rewindIcon,
    routerIcon,
    scatterPlotIcon,
    searchIcon,
    shieldIcon,
    shrinkIcon,
    sliderIcon,
    starIcon,
    stopIcon,
    storageAdapterIcon,
    switchIcon,
    talkBubblesIcon,
    targetIcon,
    toolsIcon,
    trashIcon,
    uploadIcon,
    userIcon,
    usersIcon,
    vmwAppIcon,
    walletIcon,
    wifiIcon,
    windowCloseIcon
} from '@cds/core/icon';
import SwiperCore, {Pagination} from 'swiper';
import {UserInfo} from 'angular-oauth2-oidc';
import {AuthenticationService} from './auth/authentication.service';
import {InternalHistoryService} from './shared/services/internal-history.service';
import {InternalStyleService} from './shared/services/internal-style.service';
import {DOCUMENT} from '@angular/common';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {SidebarService} from './shared/services/sidebar.service';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {ToastService} from './shared/services/toast.service';
import {Options} from 'angular2-notifications/lib/interfaces/options.type';
import {Environment} from './environment';

// TODO: AddIcons in corresponding files to allow tree-shaking
ClarityIcons.addIcons(
    deployIcon,
    flameIcon,
    ellipsisHorizontalIcon,
    crownIcon,
    buildingIcon,
    talkBubblesIcon,
    bubbleExclamationIcon,
    uploadIcon,
    bugIcon,
    applicationsIcon,
    trashIcon,
    stopIcon,
    shrinkIcon,
    cpuIcon,
    clockIcon,
    memoryIcon,
    bankIcon,
    playIcon,
    bellIcon,
    blockIcon,
    nodesIcon,
    targetIcon,
    walletIcon,
    ciCdIcon,
    windowCloseIcon,
    computerIcon,
    connectIcon,
    downloadIcon,
    folderOpenIcon,
    historyIcon,
    hostGroupIcon,
    keyIcon,
    linkIcon,
    lineChartIcon,
    plusIcon,
    userIcon,
    bookIcon,
    infoCircleIcon,
    copyToClipboardIcon,
    storageAdapterIcon,
    sliderIcon,
    wifiIcon,
    usersIcon,
    shieldIcon,
    organizationIcon,
    dollarIcon,
    applicationIcon,
    angleIcon,
    refreshIcon,
    plusCircleIcon,
    checkIcon,
    starIcon,
    mapMarkerIcon,
    addTextIcon,
    codeIcon,
    homeIcon,
    searchIcon,
    dataClusterIcon,
    pencilIcon,
    newIcon,
    betaIcon,
    toolsIcon,
    cursorHandOpenIcon,
    popOutIcon,
    routerIcon,
    objectsIcon,
    switchIcon,
    scatterPlotIcon,
    creditCardIcon,
    fileIcon,
    namespaceIcon,
    landscapeIcon,
    vmwAppIcon,
    barChartIcon,
    nvmeIcon,
    rewindIcon,
    administratorIcon,
    firewallIcon,
    mediaChangerIcon,
    flagIcon,
    processOnVmIcon,
    barsIcon
);

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {

    tenant: Tenant;
    userDetails: User;

    openSearchModal = false;
    isSearching = false;
    search = '';
    results: Result[];

    projects: Array<Project>;

    @ViewChild('search_input', {static: false})
    elementRef: ElementRef;
    mainSideCollapsed = true;
    private subscription: Subscription;

    notifications = 0;
    private socket: WebSocketSubject<any>;

    options: Options = {
        timeOut: 10000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
        // animate: NotificationAnimationType.FromRight,
        position: ['top', 'right'],
        lastOnBottom: true,
        preventDuplicates: false
    };

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public router: Router,
        private environment: Environment,
        private envService: EnvService,
        private userService: UserService,
        private searchService: SearchService,
        private sidebarService: SidebarService,
        private projectService: ProjectService,
        private historyService: InternalHistoryService,
        private styleService: InternalStyleService,
        private authenticationService: AuthenticationService,
        private changeDetectorRef: ChangeDetectorRef,
        private notificationService: NotificationService,
        private toastService: ToastService
    ) {
        this.tenant = {};
        this.results = [];
    }

    style(): Style {
        return this.styleService.style;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {

        this.socket = webSocket(this.environment.wsUrl + '/push?token=' + this.authenticationService.getToken() + '&tenant=' + this.authenticationService.getTenant());
        this.authenticationService.onTokenRefreshed().subscribe(token => {
            console.log('Reload config with new token');
            this.socket = webSocket(this.environment.wsUrl + '/push?token=' + this.authenticationService.getToken() + '&tenant=' + this.authenticationService.getTenant());
        });

        this.notificationService.findNotifications(this.authenticationService.getTenant())
            .subscribe(r => {
                this.notifications += r.length;
            });

        this.socket.subscribe(message => {
            this.notifications += 1;
            this.toastService.notify(message.subject, message.body, message.icon);
        });

        this.envService.getEnv(this.authenticationService.getTenant()).subscribe(r => {
            this.tenant = r;
            this.styleService.loadStyle();
        });

        this.projectService.findProjects(this.authenticationService.getTenant()).subscribe(e => {
            this.projects = e;
        });

        this.subscription = this.sidebarService.getRefreshProjectsAsObservable().subscribe(x => {
            this.projectService.findProjects(this.authenticationService.getTenant()).subscribe(e => {
                this.projects = e;
            });
        });

        this.userService.findCurrentUser(this.authenticationService.getTenant()).subscribe(e => {
            this.userDetails = e;
        });

        // const now = new Date().getUTCMilliseconds();
        // const toDate = new Date();
        // toDate.setMilliseconds(now);
        // const fromDate = new Date();
        // fromDate.setSeconds(now / 1000 - 365 * 24 * 60 * 60);
        // this.costService.getCosts(this.authenticationService.getTenant(), fromDate.toISOString(), toDate.toISOString(), 'tenant', {}).subscribe(e => {
        //     this.budgetReady = true;
        // });

        this.historyService.ngOnInit();

    }

    public getSearchHistory(): SearchHistory[] {
        return this.historyService.getSearchHistory();
    }

    public getResourceHistory(): Result[] {
        return this.historyService.getResourceHistory();
    }

    user(): UserInfo {
        const user = this.authenticationService.getUser();
        return user;
    }

    logoff(): void {
        this.authenticationService.logoff();
    }

    onFocusInEvent($event: FocusEvent): void {
        this.openSearchModal = true;
    }

    openModal(): void {
        this.changeDetectorRef.detectChanges();
        fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
            // get value
            map((event: any) => {
                return event.target.value;
            })
            // if character length greater then 2
            , filter(res => res.length > 2)
            // Time in milliseconds between key events
            , debounceTime(1000)
            // If previous query is diffent from current
            , distinctUntilChanged()
            // subscription for response
        ).subscribe((text: string) => {
            this.isSearching = true;
            this.openSearchModal = true;

            this.historyService.addSearchHistoryForUser(text);

            this.searchService.search(this.authenticationService.getTenant(), text).subscribe(e => {
                this.isSearching = false;
                this.results = e;
            }, (err) => {
                this.isSearching = false;
                console.log('error', err);
            });
        });
        this.openSearchModal = true;
    }

    closeModal(): void {
        this.openSearchModal = false;
    }

    setSearchTerm(term: string): void {
        this.elementRef.nativeElement.value = term;
        this.elementRef.nativeElement.dispatchEvent(new Event('keyup'));
    }
}
