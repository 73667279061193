import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import '@cds/core/input/register.js';
import {AuthenticationService} from '../../auth/authentication.service';
import {EnvService, Tenant} from '../../../generated';

@Component({
    selector: 'app-advanced-home-page',
    templateUrl: './advanced-home.component.html'
})
export class AdvancedHomeComponent implements OnInit {
    tenant: Tenant;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private envService: EnvService,
    ) {
    }

    ngOnInit(): void {
        this.envService.getEnv(this.authenticationService.getTenant()).subscribe(t => {
            this.tenant = t;
        });
    }
}
