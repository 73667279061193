import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

import '@cds/core/divider/register.js';
import {JobService} from '../../../generated';
import {AuthenticationService} from '../../auth/authentication.service';

@Component({
    template: `

    `,
    selector: 'redirect-job'
})
export class RedirectJobComponent implements OnInit {

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private jobService: JobService
    ) {

    }

    ngOnInit(): void {
        this.route.paramMap
            .subscribe((params: Params) => {
                this.jobService.findJobByJobId(this.authenticationService.getTenant(), params.get('jobId'))
                    .subscribe(
                        job => {
                            this.router.navigate(['projects', job.project_id, 'jobs', job.id]);
                        },
                        e => {
                            this.router.navigate(['not-found.component.html']);
                        });
            });
    }
}
