import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NewRoutingModule } from './new-routing.module';
import {
    ClrAccordionModule,
    ClrCheckboxModule,
    ClrComboboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrModalModule,
    ClrRadioModule,
    ClrSelectModule,
    ClrSignpostModule,
    ClrRangeModule,
    ClrSpinnerModule,
    ClrStackViewModule,
    ClrStepperModule,
    ClrTabsModule,
    ClrTreeViewModule,
    ClrTextareaModule, ClarityModule
} from '@clr/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobNewComponent } from './job/job-new.component';
import { ProjectNewComponent } from './project/project-new.component';
import { WorkflowNewComponent } from './workflow/workflow-new.component';
import { WorkspaceNewComponent } from './workspace/workspace-new.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { ComponentsModule } from '../shared/components/components.module';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        NewRoutingModule,
        ClrDatagridModule,
        CommonModule,
        ClrIconModule,
        ClrCheckboxModule,
        FormsModule,
        ClrTreeViewModule,
        ClrDropdownModule,
        NgxEchartsModule,
        ClrModalModule,
        ClrSpinnerModule,
        ReactiveFormsModule,
        ClrInputModule,
        ClrComboboxModule,
        DirectivesModule,
        PipesModule,
        ClrTextareaModule,
        ClrRangeModule,
        ClrAccordionModule,
        ClrStepperModule,
        ClrSignpostModule,
        ClrTabsModule,
        ClarityModule,
        ClrSelectModule,
        ClrRadioModule,
        ComponentsModule,
        ClrStackViewModule,
        ColorPickerModule
    ],
    declarations: [
        JobNewComponent,
        ProjectNewComponent,
        WorkflowNewComponent,
        WorkspaceNewComponent
    ]
})
export class NewModule {
}
