import {Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import '@cds/core/tag/register';
import {Activity, ActivityService, Comment, CommentActivity, User, UserService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';
import {DOCUMENT} from '@angular/common';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-comment',
    styles: [`
        ::ng-deep .in-clr-stack-block .stack-block-label .stack-view-key {
            max-width: 0px;
        }
    `],
    templateUrl: './timeline-comment.component.html'
})
export class TimelineCommentComponent implements OnInit {

    @Input()
    activity: Activity;
    comments: Array<CommentActivity>;

    commentForm: UntypedFormGroup;

    _openCommentModal = false;

    authUser: User;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private formBuilder: UntypedFormBuilder,
        private activityService: ActivityService
    ) {
    }

    ngOnInit(): void {
        this.activityService.getCommentsFromActivity(this.authenticationService.getTenant(), this.activity.id)
            .subscribe(r => {
                this.comments = r;
            });
        this.commentForm = this.formBuilder.group({
            comment: ['']
        });
        this._openCommentModal = false;
        this.authUser = this.authenticationService.getInternalUser();
    }

    openCommentModal(activity: Activity): void {
        this.activity = activity;
        this._openCommentModal = true;
    }

    addComment(): void {
        if (this.activity) {
            // Add comment
            const comment: Comment = {
                comment: this.commentForm.get('comment').value
            };
            this.activityService.createCommentForActivity(this.authenticationService.getTenant(), this.activity.id, comment)
                .subscribe(r => {
                    this.ngOnInit();
                });
        }
    }

    deleteComment(comment: CommentActivity): void {
        if (this.activity) {
            // Delete comment
            this.activityService.deleteCommentFromActivity(this.authenticationService.getTenant(), this.activity.id, comment.id)
                .subscribe(r => {
                    this.ngOnInit();
                });
        }
    }
}
