import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {ApplicationComponent} from './application.component';
import {ApplicationRoutingModule} from './application-routing.module';
import {CommonModule} from "@angular/common";
import {
    ClrCheckboxModule,
    ClrComboboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrLoadingModule,
    ClrModalModule,
    ClrPasswordModule,
    ClrSignpostModule,
    ClrSpinnerModule,
    ClrStepperModule,
    ClrTabsModule,
    ClrTreeViewModule,
} from "@clr/angular";
import {NgxEchartsModule} from "ngx-echarts";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DirectivesModule} from "../shared/directives/directives.module";

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        ApplicationRoutingModule,
        CommonModule,
        ClrIconModule,
        ClrCheckboxModule,
        ClrTreeViewModule,
        ClrInputModule,
        ClrTabsModule,
        ClrSpinnerModule,
        NgxEchartsModule,
        ClrLoadingModule,
        ClrSignpostModule,
        ClrModalModule,
        ReactiveFormsModule,
        FormsModule,
        ClrPasswordModule,
        ClrComboboxModule,
        ClrDatagridModule,
        ClrDropdownModule,
        ClrStepperModule,
        DirectivesModule
    ],
    declarations: [
        ApplicationComponent
    ]
})
export class ApplicationModule {
}
