import {Component, OnInit} from '@angular/core';
import '@cds/core/tag/register';
import {Notification1, NotificationService} from '../../generated';
import {AuthenticationService} from '../auth/authentication.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
    notifications: Array<Notification1>;

    constructor(
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService
    ) {
    }

    onSendMessage(): void {
        const notification1: Notification1 = {
            to: this.authenticationService.getInternalUser().id,
            subject: `Hello`,
            body: `Message generated at ${new Date()}`,
            icon: 'failed'
        };
        this.notificationService.sendNotification(this.authenticationService.getTenant(), notification1).subscribe(r => {
        });
    }

    ngOnInit(): void {
        this.notificationService.findNotifications(this.authenticationService.getTenant()).subscribe(r => {
            this.notifications = r;
        });
    }
}
