import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import '@cds/core/divider/register.js';
import {AuthenticationService} from '../../auth/authentication.service';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Patch, Project, ProjectService, Style, TenantService} from '../../../generated';
import {SidebarService} from '../../shared/services/sidebar.service';
import {finalize} from 'rxjs/operators';
import {HttpEventType} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-project-configuration-page',
    templateUrl: './project-configuration.component.html'
})
export class ProjectConfigurationComponent implements OnInit {

    form: UntypedFormGroup;
    style: Style;
    project: Project;

    uploadProgress: number;
    uploadSub: Subscription;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private tenantService: TenantService,
        private sidebarService: SidebarService,
        private projectService: ProjectService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.form = this.formBuilder.group({
            badge: ['', [this.colorValidator]],
        });
    }

    colorValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (control === null || control.value === null || control.value === undefined || control.value === '') {
            return null;
        }
        const b = !control.value.startsWith('#');
        return b ? {hex: true} : null;
    }

    ngOnInit(): void {
        // Retrieve the prefetched project
        this.route.data.subscribe(
            (data) => {
                this.project = data.project;
                this.form.get('badge').patchValue(this.project.badge);
            });
    }


    resetUploadFile(): void {
        this.uploadProgress = null;
        this.uploadSub = null;
    }

    refreshBanner(): void {

    }

    doUploadFile(onEvent: any): void {
        if (onEvent.target.files && onEvent.target.files.length > 0) {
            this.uploadSub = this.projectService.uploadBanner(this.authenticationService.getTenant(), this.project.id, Array.from(onEvent.target.files), 'events', true)
                .pipe(
                    finalize(() => {
                        this.resetUploadFile();
                        this.refreshBanner();
                    })
                ).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        console.log('progress:' + this.uploadProgress);
                        this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                    } else if (event.type === HttpEventType.Response) {
                        console.log('body:' + event.body);
                    }
                });
        }
    }

    update(): void {
        const patches: Array<Patch> = [];
        this.patch('badge', patches);

        this.projectService.updateProject(this.authenticationService.getTenant(), this.project.id, patches)
            .subscribe(s => {
                this.sidebarService.forceRefreshProjects();
            });
    }

    private patch(path: string, patches: Array<Patch>): void {
        const newValue = this.form.get(path).value;
        const oldValue = this.project[path];

        let op = null;
        if (oldValue) {
            if (newValue) {
                if (oldValue !== newValue) {
                    op = Patch.OpEnum.Replace;
                }
            } else {
                op = Patch.OpEnum.Remove;
            }
        } else {
            if (newValue) {
                op = Patch.OpEnum.Add;
            }
        }

        if (op) {
            const patch: Patch = {
                op,
                value: newValue,
                path: '/' + path
            };
            patches.push(patch);
        }
    }

    refresh(): void {
        this.ngOnInit();
    }
}
