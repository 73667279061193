import {Component, Input, OnInit} from '@angular/core';
import {PaymentMethod} from '../../../../generated';

@Component({
    selector: 'app-payment-method-icon',
    templateUrl: './payment-method-icon.component.html'
})
export class PaymentMethodIconComponent implements OnInit {

    @Input()
    paymentMethod: PaymentMethod;

    @Input()
    size: number;

    @Input()
    displayTitle = false;

    word = '';

    constructor() {
    }

    ngOnInit(): void {
        if (this.displayTitle) {
            switch (this.paymentMethod.details.type.toLowerCase()) {
                case 'credit_card':
                    this.word = 'Credit card';
                    break;
                case 'sepa':
                    this.word = 'SEPA';
                    break;
                case 'paypal':
                    this.word = 'Paypal';
                    break;
                default:
                    this.word = 'Other';
            }
        }
    }
}
