import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-endpoint-icon',
    templateUrl: './endpoint-icon.component.html'
})
export class EndpointIconComponent implements OnInit {

    @Input()
    type: string;

    @Input()
    size: number;

    @Input()
    displayTitle = false;

    word = '';

    constructor() {
    }

    ngOnInit(): void {
        if (this.displayTitle) {
            switch (this.type.toLowerCase()) {
                case 'pmml':
                    this.word = 'PMML';
                    break;
                case 'tensorflow':
                    this.word = 'TensorFlow';
                    break;
                case 'torchserve':
                    this.word = 'TorchServe';
                    break;
                case 'lightgbm':
                    this.word = 'LightGBM';
                    break;
                case 'pytorch':
                    this.word = 'PyTorch';
                    break;
                case 'mlflow':
                    this.word = 'mlflow';
                    break;
                case 'xgboost':
                    this.word = 'XGBoost';
                    break;
                case 'sklearn':
                    this.word = 'SKlearn';
                    break;
                default:
                    this.word = 'Other';
            }
        }
    }
}
