import { Component, OnInit } from '@angular/core';

import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';

import '@cds/core/icon/register.js';
import { bellIcon, ClarityIcons, cogIcon, plusIcon, sunIcon } from '@cds/core/icon';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Identity,
    IdentityService,
    LibraryService,
    ModelError,
    Notifications,
    Project,
    ProjectService,
    RuntimeService,
    SecretService,
    GraphService,
    Workflow,
    Graph
} from '../../../generated';
import { AuthenticationService } from '../../auth/authentication.service';

ClarityIcons.addIcons(sunIcon, plusIcon, bellIcon, cogIcon);

@Component({
    selector: 'app-workflow-new-page',
    styles: [`
        ::ng-deep .in-clr-stack-block .stack-block-label .stack-view-key {
            max-width: 0px;
        }
    `],
    templateUrl: './workflow-new.component.html'
})
export class WorkflowNewComponent implements OnInit {
    form: UntypedFormGroup;

    identities: Array<Identity>;
    methods = [
        { name: 'GET' },
        { name: 'POST' },
        { name: 'PUT' },
        { name: 'HEAD' },
    ];

    projects: Array<Project>;
    project: Project;
    imageChecking = false;

    frequency: any;
    freqControl: any;

    creating = false;
    openErrorModal = false;
    internalError: ModelError;

    graph_list: Graph[];

    constructor(private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private projectService: ProjectService,
        private libraryService: LibraryService,
        private secretService: SecretService,
        private identityService: IdentityService,
        private runtimeService: RuntimeService,
        private graphService: GraphService,
        private formBuilder: UntypedFormBuilder
    ) {
        const conf = [];
        conf.push(this.createItem());
        const env = [];
        env.push(this.createItem());

        const notifications = [];
        notifications.push(this.createNotification());

        this.form = this.formBuilder.group({
            basic: this.formBuilder.group({
                name: ['My new workflow', Validators.required],
                description: [],
                project_id: ['', Validators.required],
                definition: [''],
                labels: []
            }),
            notifications: this.formBuilder.group({
                notifications: this.formBuilder.array(notifications)
            }),
            schedule: this.formBuilder.group({
                type: ['once', Validators.required],
                cron_expression: ['']
            }),
            advanced: this.formBuilder.group({
                auto_create_identity: ['true', Validators.required],
                identity_id: [''],
            }),
            valid: this.formBuilder.group({}),
        });
    }

    submit(): void {
        const workflowName = this.form.get('basic').get('name').value;

        this.creating = true;

        if (this.form.get('advanced').get('auto_create_identity').value) {
            console.log('Create identity');
            const identity: Identity = {
                name: workflowName
            };
            this.identityService.createIdentity(this.authenticationService.getTenant(), identity).subscribe(i => {
                console.log('Create workflow with new identity ' + i.id);
                console.log(i);
                this.createWorkflow(workflowName, i.id);
            });

        } else {
            const identityId = this.form.get('advanced').get('identity_id').value;
            this.createWorkflow(workflowName, identityId);
        }
    }

    private createWorkflow(workflowName: string, identityId: string) {
        const schedule = this.form.get('schedule').value;
        const notifications = this.form.get('notifications').get('notifications').value;
        const res = notifications.map(n => {
            if (n.type === 'mail') {
                return {
                    type: 'mail',
                    to: n.emailTo,
                    subject: n.emailSubject,
                    on_start: n.on_start,
                    on_success: n.on_success,
                    on_failure: n.on_failure
                };
            } else if (n.type === 'eventhub') {
                return {
                    type: 'eventhub',
                    connection_string: n.eventhubConnectionString,
                    data: n.eventhubData,
                    on_start: n.on_start,
                    on_success: n.on_success,
                    on_failure: n.on_failure
                };
            } else if (n.type === 'webhook') {
                const webhookHeaders = n.webhookHeaders
                    .filter(obj => obj.key != null && obj.key != '')
                    .reduce(function (map, obj) {
                        map[obj.key] = obj.value;
                        return map;
                    }, {});
                return {
                    type: 'webhook',
                    url: n.webhookUrl,
                    method: n.webhookMethod,
                    headers: webhookHeaders,
                    on_start: n.on_start,
                    on_success: n.on_success,
                    on_failure: n.on_failure
                };
            } else {
                return null;
            }
        });
        const notifs: Notifications = {
            on_start: res.filter(i => i && i.on_start),
            on_success: res.filter(i => i && i.on_success),
            on_failure: res.filter(i => i && i.on_failure)
        };
        // let type = this.form.get('schedule') ;
        /*let schedule:CronSchedule = type=="once"?{
                                        type: "cron",
                                        cron_expression: scheduleExpression,
                                        timezone: "Europe/Paris"
                                    }:schedule*/
        const projectId = this.form.get('basic').get('project_id').value;
        const workflowGraph = this.form.get('basic').get('definition').value

        const workflow: Workflow = {
            name: workflowName,
            description: this.form.get('basic').get('description').value,
            schedule,
            notifications: notifs,
            identity_id: identityId,
            project_id: projectId,
            tasks: workflowGraph.metadata.flow.dag.tasks,
            metadata: {
                "graph": workflowGraph
            }
        };
        this.projectService.createWorkflowForProject(this.authenticationService.getTenant(), projectId, workflow)
            .subscribe(a => {
                this.router.navigate(['projects', projectId, 'workflows', a.id]);
            },
                error => {
                    this.creating = false;
                    this.openErrorModal = true;
                    if (error.headers.get('content-type') === 'application/vnd.graal.systems.v1.error+json;charset=UTF-8') {
                        this.internalError = error.error;
                    }
                });
    }

    createNotification(): UntypedFormGroup {
        const webhookHeaders = [];
        webhookHeaders.push(this.formBuilder.group({
            key: new UntypedFormControl(''),
            value: new UntypedFormControl('')
        }));
        return this.formBuilder.group({
            on_start: new UntypedFormControl(''),
            on_success: new UntypedFormControl(''),
            on_failure: new UntypedFormControl(''),
            type: new UntypedFormControl(''),
            emailTo: new UntypedFormControl(''),
            emailSubject: new UntypedFormControl(''),
            eventhubData: new UntypedFormControl(''),
            eventhubConnectionString: new UntypedFormControl(''),
            webhookUrl: new UntypedFormControl(''),
            webhookMethod: new UntypedFormControl(''),
            webhookHeaders: this.formBuilder.array(webhookHeaders),
        });
    }

    getHeader(index: number) {
        return (this.getCurrentNotification(index).get('webhookHeaders') as UntypedFormArray).controls;
    }

    addHeader(index: number) {
        const details = this.getCurrentNotification(index).get('webhookHeaders') as UntypedFormArray;
        details.push(this.createItem());
    }

    removeHeader(index: number, indexHeader: number) {
        const details = this.getCurrentNotification(index).get('webhookHeaders') as UntypedFormArray;
        details.removeAt(indexHeader);
    }

    getNotificationCount(): number {
        return (this.form.get('notifications').get('notifications') as UntypedFormArray).length;
    }

    getCurrentNotification(index: number) {
        return (this.form.get('notifications').get('notifications') as UntypedFormArray).at(index);
    }

    getNotification() {
        return (this.form.get('notifications').get('notifications') as UntypedFormArray).controls;
    }

    addNotification() {
        const notifications = this.form.get('notifications').get('notifications') as UntypedFormArray;
        notifications.push(this.createNotification());
    }

    removeNotification(index) {
        const notifications = this.form.get('notifications').get('notifications') as UntypedFormArray;
        notifications.removeAt(index);
    }

    workflowValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        // control.
        // let value = this.form.get('options').get('type').value;

        // return { sparkIssue: true };
        return null;
    }

    getGraphList(): void {
        try {
            this.graphService.findGraphs(
                this.authenticationService.getTenant(),
                undefined,
                undefined,
                undefined,
                "workflow"
            )
                .subscribe(response => {
                    this.graph_list = response.content;
                })
        } catch (e) {
            console.log(e);
        }
    }

    ngOnInit(): void {
        // Retrieve the prefetched project
        this.route.data.subscribe(
            (data) => {
                this.project = data.project;
                if (this.project != null && this.project.id != null) {
                    this.form.get('basic').get('project_id').setValue(this.project.id);
                } else {
                    this.projectService.findProjects(this.authenticationService.getTenant())
                        .subscribe(r => {
                            this.projects = r;
                        });
                }
                this.identityService.findIdentities(this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.identities = r;
                    });
            });

        this.getGraphList();
    }

    createItem(): UntypedFormGroup {
        return this.formBuilder.group({
            key: [],
            value: []
        });
    }
}
