import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EChartsOption} from 'echarts';
import {ThemeOption} from 'ngx-echarts';
import {UserSettingsService} from '../../../shared/services/user-settings.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {CostService, Price, PriceService, Usage, UsageService} from '../../../../generated';

@Component({
    selector: 'app-budget-page',
    styles: [`
        ::ng-deep .in-clr-stack-block .stack-block-label .stack-view-key {
            max-width: 0px;
        }
    `],
    templateUrl: './budget.component.html'
})
export class BudgetComponent implements OnInit {

    @Input()
    resourceId: string;
    @Input()
    resourceType: string;

    usages: Array<Usage>;

    totalBudgetReady = false;
    budgetReady = false;

    costs: EChartsOption = {
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: [820, 932, 901, 934, 1290, 1430, 1550, 1200, 1650.1450, 1680.1890],
            type: 'line',
            smooth: false,
            areaStyle: {}
        }]
    };

    totalBudget: EChartsOption = {
        series: [
            {
                name: 'area',
                type: 'pie',
                radius: ['60%', '80%'],
                avoidLabelOverlap: false,
                color: [
                    '#0072a3'
                ],
                label: {
                    show: true,
                    fontSize: '40',
                    fontFamily: 'Metropolis, Avenir Next, Helvetica Neue, Arial, sans-serif',
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: false
                    }
                },
                labelLine: {
                    show: false
                },
                data: []
            }
        ]
    };

    globalRepartitionReady = false;
    spaces: EChartsOption = {
        calculable: true,
        series: [
            {
                name: 'area',
                type: 'pie',
                radius: [30, 110],
                roseType: 'area',
                data: [
                    {value: 10, name: 'Org test'},
                    {value: 5, name: 'Team 1'},
                    {value: 15, name: 'Sec Team'},
                    {value: 25, name: 'Project Integration'},
                    {value: 20, name: 'Project 3'},
                    {value: 35, name: 'Team 3'},
                    {value: 30, name: 'Team 33'},
                    {value: 40, name: 'Team 5'}
                ]
            }
        ]
    };

    budget: EChartsOption = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [{
            type: 'time',
            boundaryGap: false,
            splitLine: {
                show: false
            },
            axisLabel: {
                rotate: 30
            }
        }],
        yAxis: {
            splitNumber: 3
        },
        series: [{
            name: 'L',
            type: 'line',
            smooth: false,
            data: [],
            lineStyle: {
                opacity: 0
            },
            stack: 'confidence-band',
            symbol: 'none'
        }, {
            name: 'U',
            type: 'line',
            smooth: false,
            data: [],
            lineStyle: {
                opacity: 0
            },
            areaStyle: {
                color: '#ccc'
            },
            stack: 'confidence-band',
            symbol: 'none'
        }, {
            type: 'line',
            smooth: false,
            data: [],
            symbolSize: 6,
            itemStyle: {
                color: '#333'
            },
            showSymbol: false
        }]
    };
    prices: Map<string, Price>;
    MINUTES_IN_MONTH = 30 * 24 * 60;

    theme(): ThemeOption {
        return this.userSettingsService.getTheme();
    }

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private costService: CostService,
        private priceService: PriceService,
        private usageService: UsageService,
        private userSettingsService: UserSettingsService) {
    }

    ngOnInit(): void {

        this.globalRepartitionReady = true;

        const now = new Date().getUTCMilliseconds();
        const toDate = new Date();
        toDate.setMilliseconds(now);
        const fromDate = new Date();
        fromDate.setSeconds(now / 1000 - 365 * 24 * 60 * 60);

        this.priceService.findPrices(this.authenticationService.getTenant()).subscribe(e => {
            this.prices = new Map(e.map(i => [i.product_type + i.product_reference, i]));
        });

        const params = {};
        params[this.resourceType] = this.resourceId;
        this.costService.getCosts(this.authenticationService.getTenant(), fromDate.toISOString(), toDate.toISOString(), this.resourceType, params).subscribe(e => {
            this.totalBudget.series[0].data = [{value: e.total, name: e.total.toFixed(3) + '€'}];
            this.totalBudgetReady = true;

            if (e.budget_upper_projection) {
                this.budget.series[0].data = e.projection_period.map((value, index) => [value, e.budget_upper_projection[index]]); // Lower
                // console.log(this.budget.series[0].data);
            }
            // if (e.budget_upper_projection) this.budget.series[1].data = e.period.map((value, index) => [value, e.budget_upper_projection[index]]); // Upper
            this.budget.series[2].data = e.period.map((value, index) => [value, e.cumulative[index]]);

            this.budgetReady = true;
        });

        this.usageService.findUsages(this.authenticationService.getTenant(), fromDate.toISOString(), toDate.toISOString(), this.resourceType, params).subscribe(r => {
            this.usages = r;
        });

    }

    reload(): void {
        this.globalRepartitionReady = false;
        this.ngOnInit();
    }

    pricesFor(i: Usage): Price {
        const price = this.prices.get(i.product_type + i.product_reference);
        if (!price) {
            console.log('Can not find price for ' + i.product_type + i.product_reference);
        }
        return price;
    }

    parseFloat(amount: string): number {
        return parseFloat(amount);
    }
}
