import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import '@cds/core/alert/register';
import {CommonModule} from '@angular/common';
import {DrawComponent} from './draw.component';
import {DrawRoutingModule} from './draw-routing.module';


@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        DrawRoutingModule,
        CommonModule
    ],
    declarations: [
        DrawComponent,
    ],
    providers: [
    ]
})
export class DrawModule {
}
