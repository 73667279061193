import {Component} from '@angular/core';

import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import '@cds/core/icon/register.js';
import {bellIcon, ClarityIcons, cogIcon, plusIcon, sunIcon} from '@cds/core/icon';
import {
    FileLibrary,
    IssueDetails,
    PythonOptions,
    QuotaDetails,
    Tenant,
    Ticket,
    TicketService
} from "../../../generated";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../auth/authentication.service";

ClarityIcons.addIcons(sunIcon, plusIcon, bellIcon, cogIcon);

@Component({
    selector: 'app-ticket-new-page',
    templateUrl: './ticket-new.component.html'
})
export class TicketNewComponent {
    form: UntypedFormGroup;
    options: string = '';
    creating: boolean = false;

    tenant: Tenant;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private ticketService: TicketService
    ) {
        this.form = this.formBuilder.group({
            basic: this.formBuilder.group({
                title: ['', Validators.required],
                description: ['', Validators.required],
                type: ['', Validators.required]
            }),
            details: this.formBuilder.group({
                severity: [''],
                resource_id: [''],
                resource_type: [''],

                new_value: [''],
                scope: ['']
            }),
            valid: this.formBuilder.group({}),
        });
        this.tenant = {};
    }

    ngOnInit() {
    }

    submit() {
        this.creating = true;

        let details;
        if (this.form.get('basic').get('type').value === 'issue') {
            let internal: IssueDetails = {
                type: 'issue',
                severity: this.form.get('details').get('severity').value,
                resource_id: this.form.get('details').get('resource_id').value,
                resource_type: this.form.get('details').get('resource_type').value
            }
            details = internal;
        } else if (this.form.get('basic').get('type').value === 'quota') {
            let internal: QuotaDetails = {
                type: 'quota',
                new_value: this.form.get('details').get('new_value').value,
                scope: this.form.get('details').get('scope').value
            }
            details = internal;
        }

        let ticket: Ticket = {
            title: this.form.get('basic').get('title').value,
            description: this.form.get('basic').get('description').value,
            details: details
        };
        this.ticketService.createTicket(ticket, this.authenticationService.getTenant(), null)
            .subscribe(
                data => {
                    this.creating = false;
                    this.router.navigate(['support', 'tickets', data.id]);
                },
                error => {
                    this.creating = false;
                });
    }

}
