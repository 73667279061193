<div class="clr-row">
    <div class="clr-col-12">
        <h1>
            Preferences
        </h1>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-4">
        <h4>Display</h4>
        <form class="clr-form clr-form-horizontal">
            <clr-toggle-container>
                <label>Theme</label>
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="options" required [(ngModel)]="isDarkTheme" (change)="onThemeChange($event)"/>
                    <label>Use dark theme</label>
                </clr-toggle-wrapper>
            </clr-toggle-container>

            <clr-toggle-container>
                <label>Display the welcome page</label>
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="options" required [(ngModel)]="isDisplayWelcome" (change)="onDisplayWelcomeChange($event)"/>
                </clr-toggle-wrapper>
            </clr-toggle-container>
        </form>
    </div>
</div>


