import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AdvancedRoutingModule} from './advanced-routing.module';
import {
    ClrCheckboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrModalModule,
    ClrSpinnerModule,
    ClrTreeViewModule
} from '@clr/angular';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AdvancedHomeComponent} from './home/advanced-home.component';
import {DirectivesModule} from '../shared/directives/directives.module';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        AdvancedRoutingModule,
        ClrDatagridModule,
        CommonModule,
        ClrIconModule,
        ClrCheckboxModule,
        FormsModule,
        ClrTreeViewModule,
        ClrDropdownModule,
        ClrModalModule,
        ClrSpinnerModule,
        ReactiveFormsModule,
        ClrInputModule,
        DirectivesModule
    ],
    declarations: [
        AdvancedHomeComponent
    ]
})
export class AdvancedModule {
}
