<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <h1 style="float:left">Advanced</h1>
    </div>
</div>
<div class="clr-row" style="margin-top: 13px;">
    <div class="clr-col-6" *ngIf="tenant">
        <h4>Tenant informations</h4>
        <table class="table table-vertical table-noborder">
            <tbody>
                <tr>
                    <th>Tenant ID</th>
                    <td>{{tenant.id}} <a [copy-clipboard]="tenant.id" [tooltip-title]="'Copied!'">
                            <cds-icon shape="copy-to-clipboard"></cds-icon>
                        </a></td>
                </tr>
                <tr>
                    <th>Tenant name</th>
                    <td>{{tenant.name}} <a [copy-clipboard]="tenant.name" [tooltip-title]="'Copied!'">
                            <cds-icon shape="copy-to-clipboard"></cds-icon>
                        </a></td>
                </tr>
                <tr>
                    <th>Realm</th>
                    <td>{{tenant.realm}} <a [copy-clipboard]="tenant.realm" [tooltip-title]="'Copied!'">
                            <cds-icon shape="copy-to-clipboard"></cds-icon>
                        </a></td>
                </tr>
                <tr>
                    <th>Principal mail</th>
                    <td>{{tenant.email}} <a [copy-clipboard]="tenant.email" [tooltip-title]="'Copied!'">
                            <cds-icon shape="copy-to-clipboard"></cds-icon>
                        </a></td>
                </tr>
                <tr>
                    <th>SKU</th>
                    <td>{{tenant.sku}} <a [copy-clipboard]="tenant.sku" [tooltip-title]="'Copied!'">
                            <cds-icon shape="copy-to-clipboard"></cds-icon>
                        </a></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="clr-col-12">
        <div class="clr-row">

            <div class="clr-col-3">
                <div class="card">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="height: 200px">
                                <h4 class="card-title">
                                    <cds-icon shape="namespace" clrVerticalNavIcon></cds-icon>
                                    Resources
                                </h4>
                                <table class="table table-noborder" style="margin-top: 0.2rem;">
                                    <tbody>
                                        <tr>
                                            <td class="left">
                                                <a routerLink="/advanced/resources/gpu">
                                                    <cds-icon shape="storage-adapter"></cds-icon>
                                                    Configure GPU
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="left">
                                                <a routerLink="/advanced/resources/quotas">
                                                    <cds-icon shape="slider"></cds-icon>
                                                    Change quotas
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="left">
                                                <a routerLink="/advanced/resources/devices">
                                                    <cds-icon shape="wifi"></cds-icon>
                                                    Manage edge devices
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-col-3">
                <div class="card">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="height: 200px">
                                <h4 class="card-title">
                                    <cds-icon shape="shield" clrVerticalNavIcon></cds-icon>
                                    Security
                                </h4>
                                <table class="table table-noborder" style="margin-top: 0.2rem;">
                                    <tbody>
                                        <tr>
                                            <td class="left">
                                                <a routerLink="/advanced/security/users">
                                                    <cds-icon shape="user"></cds-icon>
                                                    Manage users & groups
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="left">
                                                <a routerLink="/advanced/security/assignments">
                                                    <cds-icon clrVerticalNavIcon shape="flag"></cds-icon>
                                                    Change roles assignments
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="left">
                                                <a routerLink="/advanced/security/directory">
                                                    <cds-icon shape="organization"></cds-icon>
                                                    Configure an external identity provider
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-col-3">
                <div class="card">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="height: 200px">
                                <h4 class="card-title">
                                    <cds-icon shape="dollar" clrVerticalNavIcon></cds-icon>
                                    Costs
                                </h4>
                                <table class="table table-noborder" style="margin-top: 0.2rem;">
                                    <tbody>
                                        <tr>
                                            <td class="left">
                                                <a routerLink="/advanced/resources/budgets">
                                                    <cds-icon clrVerticalNavIcon shape="wallet"></cds-icon>
                                                    View costs
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="left">
                                                <a routerLink="/advanced/security/payment-methods">
                                                    <cds-icon clrVerticalNavIcon shape="credit-card"></cds-icon>
                                                    Manage payment methods
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>