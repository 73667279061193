import {Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2} from '@angular/core';

@Directive({selector: '[editable]'})
export class EditableDirective {

    @Input('editable')
    public payload: string;

    @Output('copied')
    public copied: EventEmitter<string> = new EventEmitter<string>();

    @Input('tooltip-title')
    public tooltipTitle: string;

    tooltip: HTMLElement;

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    @HostListener('mouseleave') onMouseLeave(): void {
        this.hidetooltip();
    }

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {

        this.showTooltip();

        event.preventDefault();

        const listener = (e: ClipboardEvent) => {
            e.preventDefault();
        };

        document.addEventListener('copy', listener, false);
        document.execCommand('copy');
        document.removeEventListener('copy', listener, false);
    }

    private hidetooltip(): void {
        this.renderer.removeClass(this.el.nativeElement, 'tooltip');
        this.renderer.removeClass(this.el.nativeElement, 'tooltip-md');
        this.renderer.removeChild(this.el.nativeElement, this.tooltip);
    }

    private showTooltip(): void {
        this.renderer.setAttribute(this.el.nativeElement, 'role', 'tooltip');
        this.renderer.addClass(this.el.nativeElement, 'tooltip');
        this.renderer.addClass(this.el.nativeElement, 'tooltip-md');

        this.tooltip = this.renderer.createElement('span');
        this.renderer.addClass(this.tooltip, 'tooltip-content');

        this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipTitle));
        this.renderer.appendChild(this.el.nativeElement, this.tooltip);
    }
}
