import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Ticket, TicketService} from '../../../generated';
import {AuthenticationService} from '../../auth/authentication.service';

@Component({
    selector: 'app-ticket-details-page',
    templateUrl: './ticket-details.component.html'
})
export class TicketDetailsComponent implements OnInit {
    ticket: Ticket;

    openDeleteModal = false;
    deleting = false;

    creating: boolean;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private ticketService: TicketService
    ) {
        this.ticket = {};
    }

    ngOnInit(): void {
        // Retrieve the prefetched ticket
        this.route.data.subscribe(
            (data) => {
                this.ticket = data.ticket;
            }
        );
    }

    refresh(): void {
        this.ngOnInit();
    }


    onRefresh(): void {

    }

    onExportAll(): void {

    }

    onExportSelected(): void {

    }

    delete(): void {
        this.deleting = true;
        this.ticketService.deleteTicketById(this.authenticationService.getTenant(), this.ticket.id)
            .subscribe(a => {
                    this.openDeleteModal = false;
                    this.deleting = false;
                    this.router.navigate(['support', 'tickets']);
                },
                e => {
                    this.deleting = false;
                });
    }
}
