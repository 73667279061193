<clr-modal [(clrModalOpen)]="openDeleteModal">
    <h3 class="modal-title">Delete the {{selected.length}} selected personal access tokens ?</h3>
    <div class="modal-body">
        <ul cds-list *ngFor="let application of selected">
            <li> {{application.name}}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="deleting" (click)="delete();">
            <clr-spinner [clrSmall]="true" *ngIf="deleting" style="margin-top: 7px;"></clr-spinner>
            <ng-template [ngIf]="!deleting">Ok</ng-template>
        </button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="openCreateApplicationModal">
    <h3 class="modal-title">Create personal access token</h3>
    <div class="modal-body">
        <form clrForm [formGroup]="applicationForm" (ngSubmit)="createApplication()">
            <div formGroupName="application">

                <clr-input-container>
                    <label>Name</label>
                    <input clrInput formControlName="name" required />
                    <clr-control-error *clrIfError="'required'">Name Required</clr-control-error>
                </clr-input-container>

                <clr-input-container>
                    <label>Description</label>
                    <input clrInput formControlName="description" />
                </clr-input-container>

                <button type="button" class="btn btn-outline"
                    (click)="openCreateApplicationModal = false">Cancel</button>
                <button type="submit" class="btn btn-primary" [disabled]=" applicationForm.invalid">Create</button>
            </div>
        </form>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-12">
        <h1>
            <!--            <cds-icon shape="user" size="64"></cds-icon>-->
            Personal access tokens
        </h1>
        <p>
            <cds-icon shape="error-standard"></cds-icon>
            Personal access token is an alternative to authenticate to the API without using your password.
        </p>
    </div>
</div>

<div class="clr-row" style="margin-top: 13px;">
    <div class="clr-col-12">
        <clr-dg-action-bar style="margin-top: 0rem;">
            <div class="btn-group" *ngIf="selected.length == 0">
                <button type="button" class="btn btn-sm btn-secondary" (click)="openCreateApplicationModal = true">
                    <cds-icon shape="plus"></cds-icon>
                    Create
                </button>
            </div>
            <div class="btn-group" *ngIf="selected.length > 0">
                <button type="button" class="btn btn-link btn-sm btn-secondary" (click)="openDeleteModal = true"
                    *ngIf="selected.length >= 1">
                    <cds-icon shape="window-close"></cds-icon>
                    Delete
                </button>
                <cds-divider orientation="vertical"></cds-divider>
            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-link btn-sm btn-secondary" (click)="refresh()">
                    <cds-icon shape="refresh"></cds-icon>
                    Refresh
                </button>
            </div>
        </clr-dg-action-bar>
        <clr-datagrid [(clrDgSelected)]="selected" class="datagrid-compact">
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Name
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Description
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Application Id
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Application Secret
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    Created
                </ng-container>
            </clr-dg-column>

            <clr-dg-row *clrDgItems="let application of applications" [clrDgItem]="application">
                <clr-dg-cell>{{application.name}}</clr-dg-cell>
                <clr-dg-cell>{{application.description}}</clr-dg-cell>
                <clr-dg-cell>{{application.id}}</clr-dg-cell>
                <clr-dg-cell>
                    <ng-template [ngIf]="!viewings[application.id]">
                        XXXXXXXXXXXXXXXXXXXXXXX
                    </ng-template>
                    <ng-template [ngIf]="viewings[application.id]">
                        {{application.remote_application_secret}}
                    </ng-template>
                    <button class="btn btn-sm btn-link"
                        style="margin-top: 0px;margin-bottom: 0px;border-top-width: 0px;border-bottom-width: 0px;"
                        (click)="viewings[application.id] = !viewings[application.id]">
                        <ng-template [ngIf]="!viewings[application.id]">
                            <cds-icon shape="eye"></cds-icon>
                            View
                        </ng-template>
                        <ng-template [ngIf]="viewings[application.id]">
                            <cds-icon shape="eye-hide"></cds-icon>
                            Hide
                        </ng-template>
                    </button>
                </clr-dg-cell>
                <clr-dg-cell>{{application.created | date:'medium'}}</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>{{applications.length}} applications</clr-dg-footer>
        </clr-datagrid>
    </div>
</div>
