export const InternalTheme = {
    color: [
        '#DEDEDE',
        '#CCCCCC',
        '#B3b3b3',
        '#8c8c8c',
        '#666666',
        '#454545',
        '#333333',
        '#000000',
    ],
    // color: [
    //     '#b21ab4',
    //     '#6f0099',
    //     '#2a2073',
    //     '#0b5ea8',
    //     '#17aecc',
    //     '#b3b3ff',
    //     '#eb99ff',
    //     '#fae6ff',
    //     '#e6f2ff',
    //     '#eeeeee'
    // ],
    title: {
        textStyle: {
            fontWeight: 'normal',
            color: '#00aecd'
        }
    },

    visualMap: {
        top: 50,
        right: 10,
        pieces: [{
            gt: 0,
            lte: 5,
            color: '#FFFAE5'
        }, {
            gt: 5,
            lte: 10,
            color: '#FFF4C7'
        }, {
            gt: 10,
            lte: 20,
            color: '#FFEEA8'
        }, {
            gt: 20,
            lte: 30,
            color: '#FEE272'
        }, {
            gt: 30,
            lte: 40,
            color: '#F8CF2A'
        }, {
            gt: 40,
            lte: 50,
            color: '#EFC006'
        }, {
            gt: 50,
            lte: 60,
            color: '#E6B000'
        }, {
            gt: 60,
            lte: 70,
            color: '#D69A00'
        }, {
            gt: 70,
            lte: 80,
            color: '#B87D00'
        }, {
            gt: 80,
            lte: 90,
            color: '#8F5A00'
        }, {
            gt: 90,
            color: '#613200'
        }],
        outOfRange: {
            color: '#999'
        }
    },

    toolbox: {
        color: ['#00aecd', '#00aecd', '#00aecd', '#00aecd']
    },

    tooltip: {
        backgroundColor: '#ffffff',
        textStyle: {
            color: '#222'
        },
        axisPointer: {
            // Axis indicator, coordinate trigger effective
            type: 'cross', // The default is a straight line： 'line' | 'shadow'
            smooth: true,
            lineStyle: {
                // Straight line indicator style settings
                color: '#00aecd',
                type: 'dashed'
            },
            crossStyle: {
                color: '#00aecd'
            },
            shadowStyle: {
                // Shadow indicator style settings
                color: 'rgba(200,200,200,0.3)'
            },
            animation: false,
            label: {
                backgroundColor: '#ffffff',
                borderColor: '#aaa',
                borderWidth: 1,
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                color: '#222'
            }
        }
    },

    // Area scaling controller
    dataZoom: {
        dataBackgroundColor: '#eee', // Data background color
        fillerColor: 'rgba(144,197,237,0.2)', // Fill the color
        handleColor: '#00aecd' // Handle color
    },

    timeline: {
        lineStyle: {
            color: '#00aecd'
        },
        controlStyle: {
            color: '#00aecd',
            borderColor: '00aecd'
        }
    },

    candlestick: {
        itemStyle: {
            color: '#00aecd',
            color0: '#a2d4e6'
        },
        lineStyle: {
            width: 1,
            color: '#00aecd',
            color0: '#a2d4e6'
        },
        areaStyle: {
            color: '#b21ab4',
            color0: '#0b5ea8'
        }
    },

    chord: {
        padding: 4,
        itemStyle: {
            color: '#b21ab4',
            borderWidth: 1,
            borderColor: 'rgba(128, 128, 128, 0.5)'
        },
        lineStyle: {
            color: 'rgba(128, 128, 128, 0.5)'
        },
        areaStyle: {
            color: '#0b5ea8'
        }
    },

    line: {
        itemStyle: {
            color: '#666666'
        },
        linkStyle: {
            color: '#2a2073'
        }
    },

    graph: {
        itemStyle: {
            color: '#00aecd'
        },
        linkStyle: {
            color: '#2a2073'
        }
    },

    map: {
        itemStyle: {
            color: '#c12e34'
        },
        areaStyle: {
            color: '#ddd'
        },
        label: {
            color: '#c12e34'
        }
    },
    textStyle: {
        color: 'rgb(102, 102, 102)'
    },
    gauge: {
        axisLine: {
            lineStyle: {
                color: [
                    [0.2, '#dddddd'],
                    [0.8, '#00aecd'],
                    [1, '#f5ccff']
                ],
                width: 8
            }
        }
    }
};

var contrastColor = '#eee';
var axisCommon = function () {
    return {
        axisLine: {
            lineStyle: {
                color: contrastColor
            }
        },
        axisTick: {
            lineStyle: {
                color: contrastColor
            }
        },
        axisLabel: {
            textStyle: {
                color: contrastColor
            }
        },
        splitLine: {
            lineStyle: {
                type: 'dashed',
                color: '#aaa'
            }
        },
        splitArea: {
            areaStyle: {
                color: contrastColor
            }
        }
    };
};
export const DarkTheme = {
    backgroundColor: 'rgb(33, 51, 59)',
    color: [
        'hsl(198, 83%, 94%)',
        'hsl(198, 81%, 88%)',
        'hsl(198, 78%, 78%)',
        'hsl(198, 69%, 69%)',
        'hsl(198, 66%, 57%)',
        'hsl(198, 80%, 46%)',
        'hsl(198, 100%, 32%)',
        'hsl(198, 100%, 28%)',
        'hsl(198, 100%, 24%)',
        'hsl(198, 100%, 21%)',
        'hsl(198, 100%, 15%)'
    ],
    // color: [
    //     '#b21ab4',
    //     '#6f0099',
    //     '#2a2073',
    //     '#0b5ea8',
    //     '#17aecc',
    //     '#b3b3ff',
    //     '#eb99ff',
    //     '#fae6ff',
    //     '#e6f2ff',
    //     '#eeeeee'
    // ],
    legend: {
        textStyle: {
            color: contrastColor
        }
    },
    title: {
        textStyle: {
            fontWeight: 'normal',
            color: contrastColor
        }
    },
    textStyle: {
        color: contrastColor
    },
    visualMap: {
        top: 50,
        right: 10,
        pieces: [{
            gt: 0,
            lte: 5,
            color: 'hsl(198, 83%, 94%)'
        }, {
            gt: 5,
            lte: 10,
            color: 'hsl(198, 81%, 88%)'
        }, {
            gt: 10,
            lte: 20,
            color: 'hsl(198, 78%, 78%)'
        }, {
            gt: 20,
            lte: 30,
            color: 'hsl(198, 69%, 69%)'
        }, {
            gt: 30,
            lte: 40,
            color: 'hsl(198, 66%, 57%)'
        }, {
            gt: 40,
            lte: 50,
            color: 'hsl(198, 80%, 46%)'
        }, {
            gt: 50,
            lte: 60,
            color: 'hsl(198, 100%, 32%)'
        }, {
            gt: 60,
            lte: 70,
            color: 'hsl(198, 100%, 28%)'
        }, {
            gt: 70,
            lte: 80,
            color: 'hsl(198, 100%, 24%)'
        }, {
            gt: 80,
            lte: 90,
            color: 'hsl(198, 100%, 21%)'
        }, {
            gt: 90,
            color: 'hsl(198, 100%, 15%)'
        }],
        outOfRange: {
            color: '#999'
        }
    },

    toolbox: {
        color: ['#00aecd', '#00aecd', '#00aecd', '#00aecd']
    },

    tooltip: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        textStyle: {
            color: contrastColor
        },
        axisPointer: {
            // Axis indicator, coordinate trigger effective
            type: 'cross', // The default is a straight line： 'line' | 'shadow'
            smooth: true,
            lineStyle: {
                // Straight line indicator style settings
                color: contrastColor,
                type: 'dashed'
            },
            crossStyle: {
                color: contrastColor
            },
            shadowStyle: {
                // Shadow indicator style settings
                color: 'rgba(200,200,200,0.3)'
            },
            animation: false,
            label: {
                backgroundColor: 'rgba(0,0,0,0.5)',
                borderColor: 'rgba(200,200,200,0.3)',
                borderWidth: 1,
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                color: contrastColor
            }
        }
    },

    // Area scaling controller
    dataZoom: {
        textStyle: {
            color: contrastColor
        },
        dataBackgroundColor: '#eee', // Data background color
        fillerColor: 'rgba(144,197,237,0.2)', // Fill the color
        handleColor: '#00aecd' // Handle color
    },

    timeline: {
        lineStyle: {
            color: contrastColor
        },
        label: {
            normal: {
                textStyle: {
                    color: contrastColor
                }
            }
        },
        controlStyle: {
            normal: {
                color: contrastColor,
                borderColor: contrastColor
            }
        }
    },
    timeAxis: axisCommon(),
    logAxis: axisCommon(),
    valueAxis: axisCommon(),
    categoryAxis: axisCommon(),

    candlestick: {
        itemStyle: {
            color: '#00aecd',
            color0: '#a2d4e6'
        },
        lineStyle: {
            width: 1,
            color: '#00aecd',
            color0: '#a2d4e6'
        },
        areaStyle: {
            color: '#b21ab4',
            color0: '#0b5ea8'
        }
    },

    chord: {
        padding: 4,
        itemStyle: {
            color: '#b21ab4',
            borderWidth: 1,
            borderColor: 'rgba(128, 128, 128, 0.5)'
        },
        lineStyle: {
            color: '#00aecd'
        },
        areaStyle: {
            color: '#0b5ea8'
        }
    },

    line: {
        lineStyle: {
            color: '#00aecd'
        },
        itemStyle: {
            color: '#00aecd'
        },
        linkStyle: {
            color: '#2a2073'
        }
    },

    graph: {
        itemStyle: {
            color: '#00aecd'
        },
        linkStyle: {
            color: '#2a2073'
        }
    },

    map: {
        itemStyle: {
            color: '#c12e34'
        },
        areaStyle: {
            color: '#ddd'
        },
        label: {
            color: '#c12e34'
        }
    },

    gauge: {
        axisLine: {
            lineStyle: {
                color: [
                    [0.2, '#dddddd'],
                    [0.8, '#00aecd'],
                    [1, '#f5ccff']
                ],
                width: 8
            }
        }
    }
};

