import {Injectable,} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';

import {Workspace, WorkspaceService} from '../../generated';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../auth/authentication.service';

@Injectable()
export class WorkspaceResolver implements Resolve<Workspace> {
    constructor(private authenticationService: AuthenticationService,
                private workspaceService: WorkspaceService,
                private router: Router
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Workspace> {
        return this.workspaceService.findWorkspaceById(this.authenticationService.getTenant(), route.params.id)
            .pipe(catchError(() => {
                this.router.navigateByUrl('/');
                return EMPTY;
            }));
    }
}
