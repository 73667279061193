import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import '@cds/core/tag/register';
import {FollowService, User, User1, UserService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-followers',
    templateUrl: './followers.component.html'
})
export class FollowersComponent implements OnInit, OnChanges {

    @Input()
    resourceId: string;
    @Input()
    resourceType: string;

    followers: Array<User1>;
    users: Map<string, User>;

    constructor(
        private authenticationService: AuthenticationService,
        private followService: FollowService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        if (!this.resourceType || !this.resourceId) {
            const u = this.authenticationService.getInternalUser();
            this.resourceId = u.id;
            this.resourceType = 'user';
        }

        this.followService.getUserFollowers(this.authenticationService.getTenant(), this.resourceId)
            .subscribe(f => {
                this.followers = f;

                const userSubscriptions = this.followers
                    .filter(r => r.type === 'user')
                    .map(r =>
                        this.userService.findUserById(this.authenticationService.getTenant(), r.id)
                    );
                forkJoin(userSubscriptions)
                    .subscribe(a => {
                        this.users = new Map(a.map(i => [i.id, i]));
                    });
            });

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnInit();
    }
}
