import {Component, Input, OnInit} from '@angular/core';
import {SecretService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';
import {flatMap} from "rxjs/operators";

@Component({
    selector: 'app-secret',
    templateUrl: './secret.component.html'
})
export class SecretComponent implements OnInit {

    @Input()
    text: string;

    @Input()
    secret: string;

    viewing = false;

    value: string;

    constructor(
        private authenticationService: AuthenticationService,
        private secretService: SecretService
    ) {
    }

    ngOnInit(): void {
        this.getValue();
    }

    private getValue(): void {
        if (this.viewing) {
            if (this.text) {
                this.value = this.text;
            } else if (this.secret) {
                this.secretService.findSecretDataById(this.authenticationService.getTenant(), this.secret)
                    .pipe(flatMap(i => i.text()))
                    .subscribe(a => {
                        this.value = atob(a);
                    });
            } else {
                this.value = 'unknown';
            }
        } else {
            this.value = 'XXXXXXXXXXXXXXXXXXX';
        }
    }

    viewOrHide(): void {
        this.viewing = !this.viewing;
        this.getValue();
    }
}
