import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ClarityModule} from '@clr/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgxEchartsModule} from 'ngx-echarts';

import {BASE_PATH} from '../generated/index';
import {Environment} from './environment';
import {Configuration} from '../generated/configuration';
import {SharedModule} from './shared';
import {ClickOutsideModule} from 'ng-click-outside';
import {SwiperModule} from 'swiper/angular';
import {EditableModule} from '@ngneat/edit-in-place';
import {AuthGuard} from './auth/auth.guard';
import {AuthModule} from './auth/auth.module';
import {AboutModule} from './about/about.module';
import {ColorPickerModule} from 'ngx-color-picker';
import {ComponentsModule} from './shared/components/components.module';
import {NavigationModule} from './navigation/navigation.module';
import {DataModule} from './data/data.module';
import {DrawModule} from './draw/draw.module';
import {ProjectModule} from './project/project.module';
import {WorkspaceModule} from './workspace/workspace.module';
import {AlertModule} from './alert/alert.module';
import {ApplicationModule} from './application/application.module';
import {PreferencesModule} from './preferences/preferences.module';
import {ProfileModule} from './profile/profile.module';
import {SupportModule} from './support/support.module';
import {AdvancedModule} from './advanced/advanced.module';
import {NewModule} from './new/new.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.interceptor';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {NotificationsModule} from './notifications/notifications.module';

// needed to avoid the TS compilation error.
// This can be placed at the top of your app.module file.
declare var js: any;

// AoT requires an exported function for factories
export function apiConfig(): Configuration {
    return new Configuration({
        basePath: js.environment.apiUrl
    });
}

export function environment() {
    return js.environment;
}

export function basePath(environment: Environment): string {
    return environment.apiUrl;
}

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        ClickOutsideModule,
        SharedModule,
        PickerModule,

        AboutModule,
        AlertModule,
        NewModule,
        ApplicationModule,
        PreferencesModule,
        NotificationsModule,
        ProfileModule,
        SupportModule,
        DataModule,
        DrawModule,
        ProjectModule,
        WorkspaceModule,
        NavigationModule,
        AdvancedModule,

        AppRoutingModule,
        ClarityModule,
        SwiperModule,
        ColorPickerModule,
        EditableModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        SimpleNotificationsModule.forRoot(),
        AuthModule,
        BrowserAnimationsModule,
        ComponentsModule
    ],
    providers: [
        {
            provide: Environment,
            useFactory: environment
        },
        {
            provide: BASE_PATH,
            deps: [Environment],
            useFactory: basePath
        },
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
