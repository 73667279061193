import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Observable} from 'rxjs';
import {AssignmentService, RoleAndPrincipalAndAssignment, RoleService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-display-roles',
    templateUrl: './display-roles.component.html'
})
export class DisplayRolesComponent implements OnInit {

    @Input() mroles: Array<Array<RoleAndPrincipalAndAssignment>>;
    @Input() mtype: string;

    permissions$: Map<string, Observable<Array<string>>> = new Map<string, Observable<Array<string>>>();
    loading: Map<string, boolean> = new Map<string, boolean>();

    constructor(private authenticationService: AuthenticationService,
                private roleService: RoleService,
                private assignmentService: AssignmentService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
    }

    fetchPermissions(roleId: string) {
        this.loading.set(roleId, true);
        this.permissions$.set(roleId, this.roleService.findPermissionsByRoleId(this.authenticationService.getTenant(), roleId).pipe(tap(() => (this.loading.set(roleId, false)))));
    }

    unassign(id: string, index: number) {
        this.assignmentService.deleteRoleAssignmentById(this.authenticationService.getTenant(), id).subscribe(r => {
            this.mroles[index] = this.mroles[index].filter(e => {e.assignment.id != id;});
        });
    }
}
