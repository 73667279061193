<button class="btn btn-sm btn-link" (click)="viewOrHide()"
        style="margin-top: -12px;border-bottom-width: 0px;border-top-width: 0px;margin-bottom: -10px;">
    <ng-template [ngIf]="!viewing">
        <cds-icon shape="eye"></cds-icon>
        View
    </ng-template>
    <ng-template [ngIf]="viewing">
        <cds-icon shape="eye-hide"></cds-icon>
        Hide
    </ng-template>
</button>
{{ value }}
