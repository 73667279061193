import {Injectable} from '@angular/core';
import {AuthConfig, NullValidationHandler, OAuthService, UserInfo} from 'angular-oauth2-oidc';
import {ActivatedRoute, Router} from '@angular/router';
import {Environment} from '../environment';
import {User, UserService} from '../../generated';
import {filter} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private realm: string;
    private tenant: string;
    private user: UserInfo;
    private internalUser: User;

    private tokenRefreshed: Subject<string> = new Subject();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private oauthService: OAuthService,
        private userService: UserService,
        private environment: Environment
    ) {
        oauthService.events.pipe(filter(e => e.type === 'token_received'))
            .subscribe(e => {
                const token = this.oauthService.getAccessToken();
                console.log('Got a new token:' + token);
                this.tokenRefreshed.next(token);
            });
    }

    public onTokenRefreshed(): Observable<string> {
        return this.tokenRefreshed.asObservable();
    }

    async initAuth(): Promise<any> {
        return new Promise<void>((resolveFn, rejectFn) => {

            // console.log("Parsing url: " + window.location.href)
            const tenant = this.getParameterFromUrl('tenant');
            const realm = this.getParameterFromUrl('realm');

            if (tenant != null && realm != null) {
                this.setTenant(tenant);
                this.setRealm(realm);
            }

            if (!this.hasTenantAndRealmUser()) {
                const url = this.environment.accessUrl;
                // console.log("auth - going to " + url)
                window.location.href = url;
                rejectFn();
            }

            // console.log("auth - authenticating on " + this.getTenant() + " with realm " + this.getRealm())

            const authConfig: AuthConfig = {
                issuer: this.environment.issuer + this.getRealm(),
                requireHttps: this.environment.requireHttps,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: window.location.origin,
                clientId: this.environment.clientId,
                scope: this.environment.scope,
                responseType: this.environment.responseType,
                disableAtHashCheck: this.environment.disableAtHashCheck,
                showDebugInformation: this.environment.showDebugInformation
            };
            this.oauthService.configure(authConfig);
            this.oauthService.setStorage(localStorage);
            this.oauthService.tokenValidationHandler = new NullValidationHandler();

            this.oauthService.loadDiscoveryDocumentAndLogin().then(
                (isLoggedIn) => {
                    if (isLoggedIn) {
                        // console.log('auth - is logged in:' + isLoggedIn);
                        this.oauthService.setupAutomaticSilentRefresh();
                        this.oauthService.loadUserProfile().then(
                            (data) => {
                                this.user = data;
                                this.setUser(this.user);
                                this.setInternalUser();
                                // console.log('auth - load user info loaded');
                                // resolveFn();
                            },
                            (reason => {
                                // console.log('auth - can not load user info');
                                this.oauthService.initImplicitFlow();
                                rejectFn();
                            })
                        );
                        // this.router.navigate([], {relativeTo: this.route}); // TODO remove query params
                        // this.router.navigate(['/']);
                        // console.log('auth - completed');
                        resolveFn();
                    } else {
                        this.oauthService.initImplicitFlow();
                        rejectFn();
                    }
                },
                (error) => {
                    console.log({error});
                    if (error.status === 400) {
                        location.reload();
                    } else {
                        console.log('auth - error, logoff!');
                        localStorage.clear();
                        this.deleteAllCookies();
                        this.logoff();
                        rejectFn();
                    }
                }
            );
        });
    }

    public hasTenantAndRealmUser(): boolean {
        return this.getRealm() != null && this.getTenant() != null;
    }

    getParameterFromUrl(variable: string): string {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    public getUser(): UserInfo {
        return this.user;
    }

    public getToken(): string {
        return this.oauthService.getAccessToken();
    }

    public setUser(user: UserInfo): void {
        this.user = user;
    }

    public setInternalUser(): void {
        this.userService.findCurrentUser(this.getTenant()).subscribe(u => {
            this.internalUser = u;
        });
    }

    public getInternalUser(): User {
        return this.internalUser;
    }

    public login(): void {
        this.oauthService.initLoginFlow();
    }

    public deleteAllCookies(): void {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    public logoff(): void {
        this.oauthService.revokeTokenAndLogout().then(() => {
            localStorage.clear();
        });
    }

    public getTenant(): string {
        return localStorage.getItem('tenant');
    }

    public getRealm(): string {
        return localStorage.getItem('realm');
    }

    public setTenant(tenant: string): void {
        localStorage.setItem('tenant', tenant);
    }

    public setRealm(realm: string): void {
        localStorage.setItem('realm', realm);
    }

    public getClaims(): object {
        return this.oauthService.getIdentityClaims();
    }
}
