<ng-container *ngIf="lock">
    <clr-tooltip>
        <button clrTooltipTrigger class="btn btn-sm btn-link" (click)="unlockResource()">
            <cds-icon shape="lock" status="danger" size="md" role="img" style="margin-left: -20px;margin-top: -0.14rem;"></cds-icon>
        </button>
        <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
            <span>Unlock this {{resourceType}}</span>
        </clr-tooltip-content>
    </clr-tooltip>
</ng-container>
<ng-container *ngIf="!lock">
    <clr-tooltip>
        <button clrTooltipTrigger class="btn btn-sm btn-link" (click)="lockResource()">
            <cds-icon shape="unlock" size="md" role="img" style="margin-left: -20px;margin-top: -0.14rem;"></cds-icon>
        </button>
        <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
            <span>Follow this {{resourceType}}</span>
        </clr-tooltip-content>
    </clr-tooltip>
</ng-container>
