import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {
    AssignmentService,
    Infrastructure,
    InfrastructureService,
    InstanceType,
    InstanceTypeService,
    Patch,
    RoleAndAssignment,
    RoleAndPrincipalAndAssignment,
    RoleService,
    Workspace,
    WorkspaceService
} from '../../../generated';
import {AuthenticationService} from '../../auth/authentication.service';
import {InternalHistoryService} from '../../shared/services/internal-history.service';
import {Observable, Subscription, timer} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {ErrorService} from '../../shared/services/error.service';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-workspace-details-page',
    templateUrl: './workspace-details.component.html',
    styleUrls: ['./workspace-details.component.less']
})
export class WorkspaceDetailsComponent implements OnInit, OnDestroy {
    workspace: Workspace;

    openDeleteModal = false;
    deleting = false;

    roles: Array<RoleAndAssignment>;
    tenantRoles: Array<RoleAndPrincipalAndAssignment>;

    errorMessage: string;

    permissions$: Map<string, Observable<Array<string>>> = new Map<string, Observable<Array<string>>>();
    loading: Map<string, boolean> = new Map<string, boolean>();

    infrastructure: Infrastructure;

    instanceTypes: Map<string, InstanceType>;

    eventsSubscription: Subscription;
    controls: Map<string, UntypedFormControl>;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private workspaceService: WorkspaceService,
        private roleService: RoleService,
        private historyService: InternalHistoryService,
        private infrastructureService: InfrastructureService,
        private instanceTypeService: InstanceTypeService,
        private assignmentService: AssignmentService,
        private errorService: ErrorService
    ) {
        this.workspace = {};
        this.instanceTypes = new Map<string, InstanceType>();
        this.controls = new Map<string, UntypedFormControl>();
        this.updateEditForm();
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    private updateEditForm(): void {
        this.controls.set('name', new UntypedFormControl());
        this.get('name').setValue(this.workspace.name);
        this.controls.set('description', new UntypedFormControl());
        this.get('description').setValue(this.workspace.description);

        this.controls.set('options/instance_type', new UntypedFormControl());
        this.get('options/instance_type').setValue(this.workspace.instance_type);
    }

    update(old, field: string): void {
        if (this.controls.get(field) && this.controls.get(field).value) {
            const value = this.controls.get(field).value as any;

            const patch: Patch = {
                op: old ? Patch.OpEnum.Replace : Patch.OpEnum.Add,
                value,
                path: '/' + field
            };
            this.workspaceService.updateWorkspace(this.authenticationService.getTenant(), this.workspace.id as string, [patch])
                .subscribe(r => {
                    this.workspace = r;
                    this.updateEditForm();
                });
        }
    }

    get(field: string) {
        const formControl = this.controls.get(field);
        return formControl;
    }

    ngOnInit(): void {
        // Retrieve the prefetched user
        this.route.data.subscribe(
            (data) => {
                this.workspace = data.workspace;

                this.historyService.addResourceHistoryForUser('workspace', this.workspace.id);

                this.infrastructureService.findInfrastructureByInfrastructureId(this.authenticationService.getTenant(), this.workspace.infrastructure_id as string)
                    .subscribe(r => {
                        this.infrastructure = r;
                    });

                this.instanceTypeService.findInstanceTypes(this.authenticationService.getTenant()).subscribe(r => {
                    this.instanceTypes = new Map(r.map(i => [i.id, i]));
                });

                this.assignmentService.findRoleAssignments(this.authenticationService.getTenant(), 'workspace', data.workspace.id as string)
                    .subscribe(r => {
                        this.roles = r;
                    });

                this.assignmentService.findRoleAssignments(this.authenticationService.getTenant(), 'tenant', this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.tenantRoles = r;
                    });

                this.eventsSubscription = timer(0, 5000).pipe(
                    switchMap(() => this.workspaceService.findWorkspaceById(this.authenticationService.getTenant(), this.workspace.id))
                ).subscribe(r => {
                    this.workspace = r;
                });
            }
        );
    }

    fetchPermissions(roleId: string): void {
        this.loading.set(roleId, true);
        this.permissions$.set(roleId, this.roleService.findPermissionsByRoleId(this.authenticationService.getTenant(), roleId).pipe(tap(() => (this.loading.set(roleId, false)))));
    }

    delete(): void {
        this.deleting = true;
        this.workspaceService.deleteWorkspaceById(this.authenticationService.getTenant(), this.workspace.id)
            .subscribe(a => {
                this.openDeleteModal = false;
                this.deleting = false;
                this.router.navigate(['workspaces']);
            }, e => {
                this.errorMessage = this.errorService.getMessage(e);
                this.deleting = false;
            });
    }

    refresh(): void {
        this.ngOnInit();
    }
}
