import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClarityModule} from '@clr/angular';
import {SidenavComponent} from './sidenav/sidenav.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [SidenavComponent],
    imports: [
        CommonModule,
        ClarityModule,
        RouterModule,
    ],
    exports: [SidenavComponent]
})
export class NavigationModule {
}
