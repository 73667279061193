import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import '@cds/core/divider/register.js';
import {AuthenticationService} from '../../auth/authentication.service';
import {UntypedFormGroup} from '@angular/forms';
import {Project, ProjectService, TenantService} from '../../../generated';

@Component({
    selector: 'app-project-metrics-page',
    templateUrl: './project-metrics.component.html'
})
export class ProjectMetricsComponent implements OnInit {

    form: UntypedFormGroup;
    project: Project;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private tenantService: TenantService,
        private projectService: ProjectService
    ) {
    }

    ngOnInit(): void {
        // Retrieve the prefetched project
        this.route.data.subscribe(
            (data) => {
                this.project = data.project;
            });

    }

    refresh(): void {
        this.ngOnInit();
    }
}
