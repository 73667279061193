<clr-modal [(clrModalOpen)]="openEditLabels">
    <h3 class="modal-title">Change labels</h3>
    <div class="modal-body">
        <p>
            <cds-icon shape="error-standard"></cds-icon>
            Labels of the project, as tuple of key/value
        </p>
        <form [formGroup]="labelsForm">
            <div class="clr-row" formArrayName="labels" *ngFor="let field of getLabel(); let ind = index;">
                <ng-container [formGroupName]="ind">
                    <div class="clr-col-4">
                        <div class="clr-form-control">
                            <div class="clr-control-container">
                                <div class="clr-input-wrapper">
                                    <input type="email" class="form-control" formControlName="key" class="clr-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clr-col-4">
                        <div class="clr-form-control">
                            <div class="clr-control-container">
                                <div class="clr-input-wrapper">
                                    <input type="email" class="form-control" formControlName="value"
                                        class="clr-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clr-col-3">
                        <button class="btn btn-danger-outline btn-sm" type="button" (click)="removeLabel(ind)"
                            style="margin-top:30px;">
                            Remove
                        </button>
                    </div>
                </ng-container>
            </div>
        </form>
        <button class="btn btn-outline btn-sm" type="button" (click)="addLabel()">Add label</button>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="openEditLabels = false">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="editingLabels" (click)="updateLabels();">
            <clr-spinner [clrSmall]="true" *ngIf="editingLabels" style="margin-top: 7px;"></clr-spinner>
            <ng-template [ngIf]="!editingLabels">Ok</ng-template>
        </button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="openDeleteModal">
    <h3 class="modal-title">Delete "{{project.name}}" project ?</h3>
    <div class="modal-body">
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        {{errorMessage}}
                    </span>
                </div>
            </div>
            <button type="button" class="close" aria-label="Close">
                <cds-icon aria-hidden="true" shape="window-close"></cds-icon>
            </button>
        </div>
        <div class="alert alert-danger">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        All jobs, runs, metrics and logs will be deleted
                    </span>
                </div>
            </div>
        </div>
        <p>Please confirm before</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="deleting" (click)="delete();">
            <clr-spinner [clrSmall]="true" *ngIf="deleting" style="margin-top: 7px;"></clr-spinner>
            <ng-template [ngIf]="!deleting">Ok</ng-template>
        </button>
    </div>
</clr-modal>

<div class="clr-row" style="padding-bottom: 16px;">
    <!--    background-image:-->
    <!--    linear-gradient(-->
    <!--    rgba(248,248,248,0.45),-->
    <!--    rgba(0,60,255,0.45)-->
    <!--    ),-->
    <!--    url(assets/img1.jpg);-->
    <div class="clr-col-12">
        <div cds-layout="vertical gap:lg">
            <p><a [routerLink]="['/projects']">Projects</a> > {{project.name}}</p>
            <!--            <img class="gl-banner" width="1200px" [src]="project.id | project_banner | async">-->

            <h1 class="editable" style="margin-top: 0.2rem;">
                <editable (save)="update(project.name, 'name')">
                    <ng-template viewMode>{{ project.name }}</ng-template>
                    <ng-template editMode>
                        <input editableOnEnter editableOnEscape [formControl]="get('name')" />
                        <app-emoji [control]="get('name')"></app-emoji>
                    </ng-template>
                </editable>
                <app-follow [resourceType]="'project'" [resourceId]="project.id"></app-follow>
                <app-lock [lock]="project.locked" [resourceType]="'project'" [resourceId]="project.id"
                    (reload)="project.locked = $event"></app-lock>
            </h1>

            <h5 class="editable" style="margin-top: -1.2rem;margin-bottom: 8px;">
                <editable (save)="update(project.description, 'description')">
                    <ng-template viewMode>{{ project.description || '-' }}</ng-template>
                    <ng-template editMode>
                        <input editableOnEnter editableOnEscape placeholder="{{ project.description }}"
                            [formControl]="controls.get('description')" />
                        <app-emoji [control]="get('description')"></app-emoji>
                    </ng-template>
                </editable>
            </h5>

            <div cds-layout="horizontal">
                <span *ngFor="let mapEntry of project.labels | keyvalue"
                    class="label label-blue">{{mapEntry.key}}:{{mapEntry.value}}</span>
                <a class="p7" style="margin-top: 0.2rem;" (click)="openEditLabels = true">{{project.labels &&
                    (project.labels| json) !== '{}' ? 'edit labels': 'add label'}}</a>
            </div>

            <clr-dg-action-bar style="margin-top: 0rem;">
                <ng-container *ngIf="!project.locked">
                    <div class="btn-group" *hasPermission="'/project/delete'; id project.id; type 'project'">
                        <button class="btn btn-sm btn-secondary btn-danger" (click)="openDeleteModal = true">
                            <cds-icon shape="times"></cds-icon>
                            Delete
                        </button>
                    </div>
                </ng-container>
                <div class="btn-group" *hasPermission="'/run/cancel'; id project.id; type 'project'">
                    <button class="btn btn-sm btn-link">
                        <cds-icon shape="stop"></cds-icon>
                        Stop all runs
                    </button>
                </div>
                <div class="btn-group">
                    <button class="btn btn-sm btn-link">
                        <cds-icon shape="refresh"></cds-icon>
                        Refresh
                    </button>
                </div>
            </clr-dg-action-bar>
        </div>

    </div>
</div>
<div class="clr-row" style="margin-top: -8px;">
    <div class="clr-col-12">
        <!--        <clr-tabs>-->
        <!--            <clr-tab>-->
        <!--                <button clrTabLink>Summary</button>-->
        <!--                <clr-tab-content *clrIfActive>-->
        <div class="clr-row">
            <!--                            <div class="clr-col-3">-->
            <!--                                <div class="card">-->
            <!--                                    <div class="card-block">-->
            <!--                                        <div class="card-media-block wrap">-->
            <!--                                            <div class="card-media-description" style="height: 436px">-->
            <!--                                                <h4 class="card-title">Stats-->
            <!--                                                    <clr-spinner *ngIf="!statsReady" [clrInline]="true"></clr-spinner>-->
            <!--                                                </h4>-->
            <!--                                                <div *ngIf="statsReady && stats.total > 0" echarts [theme]="theme()"-->
            <!--                                                     [options]="activity" class="demo-chart"></div>-->
            <!--                                            </div>-->
            <!--                                        </div>-->
            <!--                                    </div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                            <div class="clr-col-3">-->
            <!--                                <div class="card">-->
            <!--                                    <div class="card-block">-->
            <!--                                        <div class="card-media-block wrap">-->
            <!--                                            <div class="card-media-description" style="height: 436px">-->
            <!--                                                <h4 class="card-title">Activity-->
            <!--                                                    <clr-spinner *ngIf="!activityReady" [clrInline]="true"></clr-spinner>-->
            <!--                                                </h4>-->

            <!--                                                <div class="btn-group btn-sm" *ngIf="activityReady"-->
            <!--                                                     (click)="onTimelineChange($event)">-->
            <!--                                                    <div class="radio btn" style="min-width: 2rem">-->
            <!--                                                        <input type="radio" name="btn-group-demo-radios" id="timescale-1" checked />-->
            <!--                                                        <label for="timescale-1">1h</label>-->
            <!--                                                    </div>-->
            <!--                                                    <div class="radio btn" style="min-width: 2rem">-->
            <!--                                                        <input type="radio" name="btn-group-demo-radios" id="timescale-2"/>-->
            <!--                                                        <label for="timescale-2">6h</label>-->
            <!--                                                    </div>-->
            <!--                                                    <div class="radio btn" style="min-width: 2rem">-->
            <!--                                                        <input type="radio" name="btn-group-demo-radios" id="timescale-3"/>-->
            <!--                                                        <label for="timescale-3">12h</label>-->
            <!--                                                    </div>-->
            <!--                                                    <div class="radio btn" style="min-width: 2rem">-->
            <!--                                                        <input type="radio" name="btn-group-demo-radios" id="timescale-4" />-->
            <!--                                                        <label for="timescale-4">1d</label>-->
            <!--                                                    </div>-->
            <!--                                                    <div class="radio btn" style="min-width: 2rem">-->
            <!--                                                        <input type="radio" name="btn-group-demo-radios" id="timescale-5"/>-->
            <!--                                                        <label for="timescale-5">7d</label>-->
            <!--                                                    </div>-->
            <!--                                                    <div class="radio btn" style="min-width: 2rem">-->
            <!--                                                        <input type="radio" name="btn-group-demo-radios" id="timescale-6"/>-->
            <!--                                                        <label for="timescale-6">30d</label>-->
            <!--                                                    </div>-->
            <!--                                                </div>-->

            <!--                                                <div echarts *ngIf="activityReady" [theme]="theme()" [options]="gradient"-->
            <!--                                                     class="demo-chart"></div>-->
            <!--                                            </div>-->
            <!--                                        </div>-->
            <!--                                    </div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                            <div class="clr-col-3">-->
            <!--                                <div class="card">-->
            <!--                                    <div class="card-block">-->
            <!--                                        <div class="card-media-block wrap">-->
            <!--                                            <div class="card-media-description" style="height: 436px">-->
            <!--                                                <h4 class="card-title">Total budget-->
            <!--                                                    <clr-spinner *ngIf="!totalBudgetReady" [clrInline]="true"></clr-spinner>-->
            <!--                                                </h4>-->
            <!--                                                <div *ngIf="totalBudgetReady" echarts [theme]="theme()"-->
            <!--                                                     [options]="totalBudget" class="demo-chart"></div>-->
            <!--                                            </div>-->
            <!--                                        </div>-->
            <!--                                    </div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                            <div class="clr-col-3">-->
            <!--                                <div class="card">-->
            <!--                                    <div class="card-block">-->
            <!--                                        <div class="card-media-block wrap">-->
            <!--                                            <div class="card-media-description" style="height: 436px">-->
            <!--                                                <h4 class="card-title">Budget-->
            <!--                                                    <clr-spinner *ngIf="!budgetReady" [clrInline]="true"></clr-spinner>-->
            <!--                                                </h4>-->
            <!--                                                <div *ngIf="budgetReady" echarts [theme]="theme()"-->
            <!--                                                     [options]="budget" class="demo-chart"></div>-->
            <!--                                            </div>-->
            <!--                                        </div>-->
            <!--                                    </div>-->
            <!--                                </div>-->
            <!--                            </div>-->

            <!--                        <div class="clr-col-12">-->
            <!--                            <h6>Latest runs <clr-spinner *ngIf="!latestRunsReady" [clrInline]="true"></clr-spinner></h6>-->

            <!--                            <clr-datagrid *ngIf="latestRunsReady">-->
            <!--                                <clr-dg-column>Run ID/Job ID</clr-dg-column>-->
            <!--                                <clr-dg-column>Name</clr-dg-column>-->
            <!--                                <clr-dg-column>Type</clr-dg-column>-->
            <!--                                <clr-dg-column>Status</clr-dg-column>-->
            <!--                                <clr-dg-column>Infrastructure</clr-dg-column>-->
            <!--                                <clr-dg-column>Creation date</clr-dg-column>-->
            <!--                                <clr-dg-column>Description</clr-dg-column>-->

            <!--                                <clr-dg-row *clrDgItems="let e of runWithJobs">-->
            <!--                                    <clr-dg-cell><a [routerLink]="['/projects', project.id, 'jobs', e.job.id, 'runs', e.run.id]">{{e.run.id}}</a>/<a-->
            <!--                                            [routerLink]="['/projects', project.id, 'jobs', e.job.id]">{{e.job.id}}</a></clr-dg-cell>-->
            <!--                                    <clr-dg-cell>{{e.run.name}}</clr-dg-cell>-->
            <!--                                    <clr-dg-cell>-->
            <!--                                        <app-runtime-icon [type]="e.job.options.type" [size]=12 [displayTitle]="true"></app-runtime-icon>-->
            <!--                                    </clr-dg-cell>-->
            <!--                                    <clr-dg-cell [ngSwitch]="e.run.status">-->
            <!--                                        <cds-tag *ngSwitchCase="'failed'" readonly status='danger'>Failed</cds-tag>-->
            <!--                                        <cds-tag *ngSwitchCase="'complete'" readonly status='success'>Complete</cds-tag>-->
            <!--                                        <cds-tag *ngSwitchCase="'running'" readonly status='info'>Running</cds-tag>-->
            <!--                                        <cds-tag *ngSwitchCase="'unknown'" readonly>Unknown</cds-tag>-->
            <!--                                    </clr-dg-cell>-->
            <!--                                    <clr-dg-cell>-->
            <!--                                        <ng-container *ngIf="infrastructures && e.run.infrastructure_id && infrastructures.get(e.run.infrastructure_id)">-->
            <!--                                            <span class="flag-icon flag-icon-{{infrastructures.get(e.run.infrastructure_id).country}} flag-icon-squared"></span>-->
            <!--                                            <app-infra-icon style="margin-left: 6px;" [type]="infrastructures.get(e.run.infrastructure_id).type" [displayTitle]="false" [size]="12"></app-infra-icon> {{infrastructures.get(e.run.infrastructure_id).name}}-->
            <!--                                        </ng-container>-->
            <!--                                        <ng-container *ngIf="devices && e.run.device_id && devices.get(e.run.device_id)">-->
            <!--                                            {{devices.get(e.run.device_id).name}}-->
            <!--                                        </ng-container>-->
            <!--                                    </clr-dg-cell>-->
            <!--                                    <clr-dg-cell>{{e.run.created | date }}</clr-dg-cell>-->
            <!--                                    <clr-dg-cell>{{e.run.description}}</clr-dg-cell>-->
            <!--                                </clr-dg-row>-->

            <!--                            </clr-datagrid>-->

            <!--                        </div>-->
            <div class="clr-col-12">
                <ng-container *ngIf="project">
                    <app-calendar [resourceType]="'project'" [resourceId]="project.id"></app-calendar>
                </ng-container>
            </div>
            <div class="clr-col-4">
                <h5>Common tasks</h5>
                <a routerLink="/new/job" style="text-align: left;display: block;padding-left: 0px;margin: 6px;margin-left: 0px;margin-right: 0px;"><cds-icon shape="applications" aria-current="true"
                                                                                                                                                             aria-label="Current"></cds-icon> Create a new Job</a>
                <a routerLink="/new/workflow" style="text-align: left;display: block;padding-left: 0px;margin: 6px;margin-left: 0px;margin-right: 0px;"><cds-icon shape="ci-cd" aria-current="true"
                                                                                                                                                                  aria-label="Current"></cds-icon> Create a new Workflow</a>

                <h5>Latest runs <clr-spinner *ngIf="!latestRunsReady" [clrInline]="true"></clr-spinner></h5>
                <ng-container *ngFor="let e of runWithJobs">
                    <a [routerLink]="['/jobs', e.job.id, 'runs', e.run.id]" style="display: block;margin: 6px;margin-left: 0px;margin-right: 0px;">
                        <span [ngSwitch]="e.run.status">
                            <span *ngSwitchCase="'failed'" class="badge badge-blue"
                                  style="background: red;"></span>
                            <span *ngSwitchCase="'complete'" class="badge badge-blue"
                                  style="background: green;"></span>
                            <span *ngSwitchCase="'running'" class="badge badge-blue"
                                  style="background: yellow;"></span>
                            <span *ngSwitchCase="'unknown'" class="badge badge-blue"
                                  style="background: grey;"></span>
                        </span>
                        <app-runtime-icon [type]="e.job.options.type" [size]=12
                                          [displayTitle]="true"></app-runtime-icon>
                        {{e.run.name}}
                    </a>
                </ng-container>

<!--                <h5>Stats-->
<!--                    <clr-spinner *ngIf="!statsReady" [clrInline]="true"></clr-spinner>-->
<!--                </h5>-->
<!--                <div *ngIf="statsReady && stats.total > 0" echarts [theme]="theme()"-->
<!--                     [options]="activity" class="demo-chart"></div>-->
            </div>

            <div class="clr-col-8">
                <h5>Latest activities <clr-spinner *ngIf="!project" [clrInline]="true"></clr-spinner></h5>
                <ng-container *ngIf="project">
                    <app-timeline [resourceType]="'project'" [resourceId]="project.id"></app-timeline>
                </ng-container>
            </div>
        </div>
        <!--                </clr-tab-content>-->
        <!--            </clr-tab>-->
        <!--            <clr-tab>-->
        <!--                <button clrTabLink>Permissions</button>-->
        <!--                <clr-tab-content *clrIfActive>-->
        <!--                    <div class="clr-row">-->
        <!--                        <div class="clr-col-12 clr-col-sm-12" style="margin-top: 8px;">-->
        <!--                            <clr-dg-action-bar>-->
        <!--                                <app-grant-roles [resourceId]="project.id" [resourceType]="'project'" *hasPermission="'/project/grant'; id project.id; type 'project'"></app-grant-roles>-->
        <!--                            </clr-dg-action-bar>-->
        <!--                            <app-display-roles [mroles]="[roles,tenantRoles]" [mtype]="'project'"></app-display-roles>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </clr-tab-content>-->
        <!--            </clr-tab>-->
        <!--        </clr-tabs>-->
    </div>
</div>
