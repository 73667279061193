import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import '@cds/core/divider/register.js';
import {AuthenticationService} from "../../auth/authentication.service";

@Component({
    selector: 'app-alert-home-page',
    templateUrl: './alert-home.component.html'
})
export class AlertHomeComponent implements OnInit {

    constructor(private authenticationService:AuthenticationService, private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
    }

    refresh() {
        this.ngOnInit();
    }
}
