<clr-modal [(clrModalOpen)]="openDeleteModal">
    <h3 class="modal-title">Delete "{{workspace.name}}" workspace ?</h3>
    <div class="modal-body">
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        {{errorMessage}}
                    </span>
                </div>
            </div>
            <button type="button" class="close" aria-label="Close">
                <cds-icon aria-hidden="true" shape="window-close"></cds-icon>
            </button>
        </div>
        <div class="alert alert-danger">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        All runs, metrics and logs will be deleted
                    </span>
                </div>
            </div>
        </div>
        <p>Please confirm before</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="deleting" (click)="delete();">
            <clr-spinner [clrSmall]="true" *ngIf="deleting" style="margin-top: 7px;"></clr-spinner>
            <ng-template [ngIf]="!deleting">Ok</ng-template>
        </button>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-12">
        <div cds-layout="vertical gap:lg">
            <p><a [routerLink]="['/workspaces']">Workspaces</a> > {{workspace.name}}</p>
            <h1 style="margin-top: 0.2rem;">
                <app-runtime-icon [type]="workspace.type" [size]=36></app-runtime-icon>
                <editable (save)="update(workspace.name, 'name')">
                    <ng-template viewMode>{{ workspace.name }}</ng-template>
                    <ng-template editMode>
                        <input editableOnEnter editableOnEscape [formControl]="get('name')" />
                    </ng-template>
                </editable>
                <cds-badge style="padding-left: 8px;" class="{{workspace.status==='stopped' ? 'blink_me' : ''}}"
                    status="{{workspace.status==='stopped' ? 'warning' : 'success'}}"></cds-badge>
            </h1>
            <h5 *ngIf="workspace.description" style="margin-top: -1.2rem;margin-bottom: 8px;">
                <editable (save)="update(workspace.description, 'description')">
                    <ng-template viewMode>{{ workspace.description || '-' }}</ng-template>
                    <ng-template editMode>
                        <input editableOnEnter editableOnEscape placeholder="{{ workspace.description }}"
                            [formControl]="controls.get('description')" />
                    </ng-template>
                </editable>
            </h5>

            <clr-dg-action-bar style="margin-top: 0rem;">
                <div class="btn-group" *hasPermission="'/workspace/delete'; id workspace.id; type 'workspace'">
                    <button class="btn btn-sm btn-secondary btn-danger" (click)="openDeleteModal = true">
                        <cds-icon shape="times"></cds-icon>
                        Delete
                    </button>
                </div>
                <div class="btn-group">
                    <button class="btn btn-sm btn-link" (click)="refresh()">
                        <cds-icon shape="refresh"></cds-icon>
                        Refresh
                    </button>
                </div>
            </clr-dg-action-bar>
        </div>
    </div>
</div>
<div class="clr-row" style="margin-top: 13px;">
    <div class="clr-col-12">
        <clr-tabs>
            <clr-tab>
                <button clrTabLink>Summary</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row">
                        <div class="clr-col-12">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description">
                                            <div class class="clr-row">
                                                <div class="clr-col-4">
                                                    <h4 style="margin-top: 0.2rem;">Definition</h4>
                                                    <table class="table table-noborder" style="margin-top: 0.2rem;">
                                                        <tbody>
                                                            <tr>
                                                                <td class="left">Type</td>
                                                                <td class="left"><app-runtime-icon
                                                                        [type]="workspace.type" [displayTitle]="true"
                                                                        [size]=24></app-runtime-icon></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Status</td>
                                                                <td class="left"><cds-badge
                                                                        status="{{workspace.status==='stopped' ? 'warning' : 'success'}}"></cds-badge>
                                                                    {{workspace.status}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Created</td>
                                                                <td class="left">{{workspace.created}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Updated</td>
                                                                <td class="left">{{workspace.updated}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="clr-col-4">
                                                    <h4 style="margin-top: 0.2rem;">Properties</h4>
                                                    <table class="table table-noborder" style="margin-top: 0.2rem;">
                                                        <tbody>
                                                            <tr>
                                                                <td class="left">Owner</td>
                                                                <td class="left"><app-user-fullname [userId]="workspace.owner"></app-user-fullname></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Infrastructure</td>
                                                                <td class="left" *ngIf="infrastructure">
                                                                    <a
                                                                        [routerLink]="['/advanced/resources/infrastructures', workspace.infrastructure_id]">
                                                                        <app-infra-icon [type]="infrastructure.type"
                                                                            [displayTitle]="false"
                                                                            [size]="12"></app-infra-icon>
                                                                        {{infrastructure.name}}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Instance</td>
                                                                <td class="left"
                                                                    style="padding-top: 0px;padding-bottom: 0px;">
                                                                    {{workspace.instance_type}}
                                                                    <clr-signpost *ngIf="instanceTypes">
                                                                        <clr-signpost-content *clrIfOpen>
                                                                            <h6 style="margin-top: 0px;">CPU</h6>
                                                                            <p style="margin-top: 0px;">
                                                                                {{instanceTypes.get(workspace.instance_type).cpus}}
                                                                            </p>
                                                                            <h6 style="margin-top: 0px;">Memory</h6>
                                                                            <p style="margin-top: 0px;">
                                                                                {{instanceTypes.get(workspace.instance_type).memory
                                                                                | byte_format}}</p>
                                                                            <h6 style="margin-top: 0px;">Disks</h6>
                                                                            <p style="margin-top: 0px;">
                                                                                {{instanceTypes.get(workspace.instance_type).storage
                                                                                | byte_format}}</p>
                                                                            <h6 style="margin-top: 0px;">GPU</h6>
                                                                            <p style="margin-top: 0px;">-</p>
                                                                        </clr-signpost-content>
                                                                    </clr-signpost>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="clr-col-4">
                                                    <h4 style="margin-top: 0.2rem;">Access</h4>
                                                    <table class="table table-noborder" style="margin-top: 0.2rem;">
                                                        <tbody>
                                                            <tr>
                                                                <td class="left">Public url</td>
                                                                <td class="left" *ngIf="workspace.status === 'running'">
                                                                    <a href="{{workspace.public_url}}"
                                                                        target="_blank">{{workspace.public_url}}</a>
                                                                </td>
                                                                <td class="left" *ngIf="workspace.status === 'stopped'">
                                                                    Not available
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink>Costs</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row">
                        <div class="clr-col-12">
                            <app-budget-page [resourceType]="'workspace'" [resourceId]="workspace.id"></app-budget-page>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink>Permissions</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row">
                        <div class="clr-col-12">

                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description">
                                            <h4 class="card-title">Roles</h4>

                                            <clr-dg-action-bar>
                                                <app-grant-roles [resourceId]="workspace.id"
                                                    [resourceType]="'workspace'"
                                                    *hasPermission="'/workspace/grant'; id workspace.id; type 'workspace'"></app-grant-roles>
                                            </clr-dg-action-bar>
                                            <app-display-roles [mroles]="[roles,tenantRoles]"
                                                [mtype]="'workspace'"></app-display-roles>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
</div>
