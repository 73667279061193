import {Injectable,} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';

import {Project, ProjectService} from '../../generated';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../auth/authentication.service';

@Injectable()
export class ProjectResolver implements Resolve<Project> {

    constructor(private authenticationService: AuthenticationService,
                private projectService: ProjectService,
                private router: Router
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Project> {
        return this.projectService.findProjectById(this.authenticationService.getTenant(), route.params.id)
            .pipe(catchError(() => {
                    this.router.navigateByUrl('/');
                    return EMPTY;
                })
            );
    }
}
