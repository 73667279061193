<ng-container *ngIf="type">
    <ng-container [ngSwitch]="type.toLowerCase()">
        <ng-container *ngSwitchCase="'aws'"><span class="iconify-inline" data-icon="logos:aws"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'azure'"><span class="iconify-inline" data-icon="logos:microsoft-azure"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'gcp'"><span class="iconify-inline" data-icon="logos:google-cloud"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'scaleway'"><span class="iconify-inline" data-icon="simple-icons:scaleway"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'ovh'"><span class="iconify-inline" data-icon="simple-icons:ovh"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'hetzner'"><span class="iconify-inline" data-icon="simple-icons:hetzner"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchDefault><cds-icon shape="data-cluster"></cds-icon> {{word}}</ng-container>
        <!-- TODO: missing size="{{size}}" -->
    </ng-container>
</ng-container>
<ng-container *ngIf="!type">
    <cds-icon shape="data-cluster"></cds-icon> {{word}} <!-- TODO: missing size="{{size}}" -->
</ng-container>