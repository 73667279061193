import {Directive, ElementRef, Input, OnInit, Output, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {

    @Input('hasPermission')
    permission: string;
    @Input('hasPermissionType')
    type: string;
    @Input('hasPermissionId')
    id: string;

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit(): void {
        this.updateView();
    }

    private updateView(): void {
        // console.log("check perm");
        const id = this.id === '_tenant' ? this.authenticationService.getTenant() : this.id;
        this.permissionService.hasPermission(this.authenticationService.getTenant(), this.type, id, this.permission)
            .subscribe(r => {
                if (r) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
