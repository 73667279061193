import {Injectable} from '@angular/core';
import {ThemeOption} from 'ngx-echarts';
import {DarkTheme, InternalTheme} from '../../theme';

@Injectable({
    providedIn: 'root',
})
export class UserSettingsService {

    theme = 'dark';
    displayWelcome = true;

    constructor() {
    }

    switchDark(): void {
        this.theme = 'dark';
    }

    switchLight(): void {
        this.theme = 'light';
    }

    disableWelcome(): void {
        this.displayWelcome = false;
    }

    enableWelcome(): void {
        this.displayWelcome = true;
    }

    getDisplayWelcome(): boolean {
        return this.displayWelcome;
    }

    getThemeName(): string {
        return this.theme;
    }

    getTheme(): ThemeOption {
        switch (this.theme) {
            case 'dark':
                return DarkTheme;
            case 'light':
                return InternalTheme;
            default:
                return InternalTheme;
        }
    }
}
