import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {DataHubComponent} from './hub/data-hub.component';
import {DataRoutingModule} from './data-routing.module';
import {
    ClrCheckboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrModalModule,
    ClrSpinnerModule,
    ClrTreeViewModule
} from '@clr/angular';
import {CommonModule} from '@angular/common';
import {DataExplorerComponent} from './explorer/data-explorer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataIndicatorsComponent} from './indicators/data-indicators.component';
import {DataHomeComponent} from './home/data-home.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        DataRoutingModule,
        ClrDatagridModule,
        CommonModule,
        ClrIconModule,
        ClrCheckboxModule,
        FormsModule,
        ClrTreeViewModule,
        ClrDropdownModule,
        ClrModalModule,
        ClrSpinnerModule,
        ReactiveFormsModule,
        ClrInputModule
    ],
    declarations: [
        DataHomeComponent,
        DataHubComponent,
        DataExplorerComponent,
        DataIndicatorsComponent
    ]
})
export class DataModule {
}
