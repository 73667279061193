<clr-modal [(clrModalOpen)]="openChangePasswordModal">
    <h3 class="modal-title">Change password</h3>
    <div class="modal-body">
        <form clrForm [formGroup]="passwordForm" (ngSubmit)="changePassword()">
            <div formGroupName="password">
                <clr-password-container>
                    <label>Password</label>
                    <input clrPassword formControlName="password" />
                    <!--clr-control-error *clrIfError="'required'">Password Required</clr-control-error-->
                </clr-password-container>

                <clr-password-container>
                    <label>Confirm password</label>
                    <input clrPassword formControlName="confirm" />
                    <!--clr-control-error *clrIfError="'required'">Confirm password Required</clr-control-error-->
                </clr-password-container>
                <div class="alert alert-danger" *ngIf="passwordForm.get('password').errors">
                    <div class="alert-items">
                        <div class="alert-item static"
                            *ngIf="passwordForm.get('password').errors?.passwordIssue && (passwordForm.touched || passwordForm.dirty)">
                            <div class="alert-icon-wrapper">
                                <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                            </div>
                            <span class="alert-text">
                                {{passwordForm.get('password').errors?.passwordIssue}}
                            </span>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-outline" (click)="openChangePasswordModal = false">Cancel</button>
                <button type="submit" class="btn btn-primary" [disabled]=" passwordForm.invalid">Validate</button>
            </div>
        </form>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-12">
        <h1>
            <!--            <cds-icon shape="user" size="64"></cds-icon>-->
            My profile
        </h1>
    </div>
</div>


<div class="clr-row" style="margin-top: 13px;">
    <div class="clr-col-12">
        <clr-tabs>
            <clr-tab>
                <button clrTabLink>Summary</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row" *ngIf="userDetails">
                        <div class="clr-col-12">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description">
                                            <div class class="clr-row">
                                                <div class="clr-col-3">
                                                    <h4 style="margin-top: 0.2rem;">Definition</h4>
                                                    <table class="table table-noborder" style="margin-top: 0.2rem;">
                                                        <tbody>
                                                            <tr>
                                                                <td class="left">Created</td>
                                                                <td class="left">{{userDetails.created}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Updated</td>
                                                                <td class="left">{{userDetails.updated}}</td>
                                                            </tr>
                                                            <tr *ngIf="auditlogs && auditlogs.length > 0">
                                                                <td class="left">Last connection</td>
                                                                <td class="left">{{auditlogs[0].created}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="clr-col-3">
                                                    <h4 style="margin-top: 0.2rem;">Infos</h4>
                                                    <table class="table table-noborder" style="margin-top: 0.2rem;">
                                                        <tbody>
                                                            <tr>
                                                                <td class="left">Origin</td>
                                                                <td class="left">{{userDetails.origin}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Id</td>
                                                                <td class="left">{{userDetails.id}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Firstname</td>
                                                                <td class="left">{{userDetails.firstname}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Lastname</td>
                                                                <td class="left">{{userDetails.lastname}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">Email</td>
                                                                <td class="left">{{userDetails.email}}</td>
                                                            </tr>
                                                    </table>
                                                </div>
                                                <div class="clr-col-3">
                                                    <h4 style="margin-top: 0.2rem;">Security</h4>
                                                    <table class="table table-noborder" style="margin-top: 0.2rem;">
                                                        <tbody>
                                                            <tr>
                                                                <td class="left">Active</td>
                                                                <td class="left">
                                                                    <cds-icon shape="check"></cds-icon>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="left">MFA</td>
                                                                <td class="left">
                                                                    -
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <button class="btn btn-sm btn-secondary "
                                                                    (click)="openChangePasswordModal = true">
                                                                    Change password
                                                                </button>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clr-col-6">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description">
                                            <h4 class="card-title">Connection history
                                                <clr-spinner *ngIf="!logsReady" [clrInline]="true"></clr-spinner>
                                            </h4>

                                            <div echarts *ngIf="logsReady" [theme]="theme()" [options]="gradient"
                                                class="demo-chart"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clr-col-6">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description">
                                            <h4 class="card-title">Roles</h4>

                                            <table class="table table-noborder" style="margin-top: 0px;">
                                                <tbody>
                                                    <tr *ngFor="let role of roles">
                                                        <td class="left">
                                                            {{role.role.name}}
                                                            <clr-signpost
                                                                style="position: static;margin-top: -9px;margin-bottom: -9px;"
                                                                (click)="fetchPermissions(role.role.id)">
                                                                <clr-signpost-content>
                                                                    <h6 style="margin-top: 0px;">
                                                                        {{role.role.name}}</h6>
                                                                    <p style="margin-top: 0px;"><code
                                                                            class="clr-code">{{role.role.description}}</code>
                                                                    </p>
                                                                    <ul class="list-unstyled">
                                                                        <li
                                                                            *ngFor="let permission of permissions$.get(role.role.id)  | async">
                                                                            {{permission}}</li>
                                                                    </ul>
                                                                </clr-signpost-content>
                                                            </clr-signpost>
                                                        </td>
                                                        <td class="left">{{role.assignment.resource_type}}
                                                            /{{role.assignment.resource_id}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink>Activities and followers</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row">
                        <div class="clr-col-9">
                            <h6>Latest activities <clr-spinner *ngIf="!userDetails" [clrInline]="true"></clr-spinner>
                            </h6>
                            <ng-container *ngIf="userDetails">
                                <app-calendar [resourceType]="'user'" [resourceId]="userDetails.id"></app-calendar>
                                <app-timeline [resourceType]="'user'" [resourceId]="userDetails.id"></app-timeline>
                            </ng-container>
                        </div>
                        <div class="clr-col-3">
                            <h6>Followers <clr-spinner *ngIf="!userDetails" [clrInline]="true"></clr-spinner></h6>
                            <ng-container *ngIf="userDetails">
                                <app-followers></app-followers>
                            </ng-container>
                            <h6>Following <clr-spinner *ngIf="!userDetails" [clrInline]="true"></clr-spinner></h6>
                            <ng-container *ngIf="userDetails">
                                <app-following></app-following>
                            </ng-container>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink>History</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row">
                        <div class="clr-col-12 clr-col-sm-12">
                            <table class="table table-noborder">
                                <thead>
                                    <tr>
                                        <th>IP</th>
                                        <th>Location</th>
                                        <th>Action</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let audit of auditlogs">
                                        <td>{{audit.ip}}</td>
                                        <td>-</td>
                                        <td>{{audit.type}}</td>
                                        <td>{{audit.created}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink>My privacy</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row">
                        <div class="clr-col-12 clr-col-sm-12">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description">
                                            <h4 style="margin-top: 0.2rem;">Edit my personal data</h4>
                                            <p>You can edit any incorrect personal data (name, surname, email address,
                                                phone number) through the account management console under My
                                                profile</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clr-row">
                        <div class="clr-col-12 clr-col-sm-12">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description">
                                            <h4 style="margin-top: 0.2rem;">Delete my account and my personal data</h4>
                                            <p>In accordance with the regulations currently in force, you can request
                                                the deletion of your personal data.</p>
                                            <div class="alert alert-danger">
                                                <div class="alert-items">
                                                    <div class="alert-item static">
                                                        <div class="alert-icon-wrapper">
                                                            <cds-icon class="alert-icon"
                                                                shape="exclamation-triangle"></cds-icon>
                                                        </div>
                                                        <span class="alert-text">
                                                            <b>Important</b><br />
                                                            Once you make this request, all of your Projects will be
                                                            deleted, and you will
                                                            no longer be able to access your account or invoices. The
                                                            request will take at least
                                                            72 hours to be processed. You must make sure that all
                                                            invoices are paid for all Projects which
                                                            you own, and that you have not run any resources during the
                                                            current calendar month.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <p style="margin-top: 0.2rem;">Please send your request to
                                                <a
                                                    href="mailto:privacy@graal.systems?subject=Request to delete my account from GraalSystems on tenant {{tenant.id}}&body=Hi,%0AFor some reason, I,{{userDetails.firstname}} {{userDetails.lastname}}, have decided not to use my account again; therefore, I request that you kindly delete my account from your systems and delete all notifications, email addresses, personal informations, payment detail, or any other informations.%0ARegards">privacy@graal.systems</a>
                                            </p>

                                            <div class="alert alert-info">
                                                <div class="alert-items">
                                                    <div class="alert-item static">
                                                        <div class="alert-icon-wrapper">
                                                            <cds-icon class="alert-icon" shape="alert-icon"></cds-icon>
                                                        </div>
                                                        <span class="alert-text">
                                                            The account deletion process consists of the following
                                                            steps:
                                                            <ul class="list">
                                                                <li><b>Request</b> You submit an account deletion
                                                                    request through an email</li>
                                                                <li><b>Validation</b> Our support staff will validate
                                                                    your request and cross-check your information in our
                                                                    systems. If data matches, including name, email, or
                                                                    phone number, we will initiate the deletion process.
                                                                </li>
                                                                <li><b>Approval</b> We will send you an email notifying
                                                                    you about the initiation of deletion, and ask for
                                                                    final approval.</li>
                                                                <li><b>Deletion</b> After you provide your final
                                                                    consent, we will delete your account and notify you
                                                                    about the successful deletion.</li>
                                                            </ul>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
</div>
