import {Component} from '@angular/core';

import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import '@cds/core/icon/register.js';
import {bellIcon, ClarityIcons, cogIcon, plusIcon, sunIcon} from '@cds/core/icon';
import {ModelError, Project, ProjectService} from '../../../generated';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../auth/authentication.service';
import {SidebarService} from '../../shared/services/sidebar.service';

ClarityIcons.addIcons(sunIcon, plusIcon, bellIcon, cogIcon);

@Component({
    selector: 'app-project-new-page',
    templateUrl: './project-new.component.html'
})
export class ProjectNewComponent {
    form: UntypedFormGroup;
    creating = false;
    openErrorModal = false;
    internalError: ModelError;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private projectService: ProjectService,
        private sidebarService: SidebarService
    ) {

        const labels = [];
        labels.push(this.createKVItem());

        this.form = this.formBuilder.group({
            basic: this.formBuilder.group({
                name: ['', Validators.required],
                description: ['', Validators.required],
                badge: ['', Validators.required],
                labels: this.formBuilder.array(labels)
            }),
            security: this.formBuilder.group({
                power: [],
                groups: [],
            }),
            valid: this.formBuilder.group({}),
        });
    }

    submit(): void {
        this.creating = true;
        const labels = this.form.get('basic').get('labels').value
            .filter(obj => obj.key != null && obj.key !== '')
            .reduce(function(map, obj) {
                map[obj.key] = obj.value;
                return map;
            }, {});
        const project: Project = {
            name: this.form.get('basic').get('name').value,
            description: this.form.get('basic').get('description').value,
            badge: this.form.get('basic').get('badge').value,
            labels
        };
        this.projectService.createProject(this.authenticationService.getTenant(), project)
            .subscribe(
                data => {
                    this.creating = false;
                    this.sidebarService.forceRefreshProjects();
                    this.router.navigate(['projects', data.id]);
                },
                error => {
                    this.creating = false;
                    this.openErrorModal = true;
                    if (error.headers.get('content-type') === 'application/vnd.graal.systems.v1.error+json;charset=UTF-8') {
                        this.internalError = error.error;
                    }
                });
    }

    createKVItem(): UntypedFormGroup {
        return this.formBuilder.group({
            key: [],
            value: []
        });
    }

    getLabel() {
        return (this.form.get('basic').get('labels') as UntypedFormArray).controls;
    }

    addLabel(): void {
        const details = this.form.get('basic').get('labels') as UntypedFormArray;
        details.push(this.createKVItem());
    }

    removeLabel(index): void {
        const details = this.form.get('basic').get('labels') as UntypedFormArray;
        details.removeAt(index);
    }

}
