import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DrawComponent} from './draw.component';

const routes: Routes = [
    {
        path: 'draw',
        component: DrawComponent
    },
    {
        path: 'draw/:resourceType/:drawMode/:graphId',
        component: DrawComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DrawRoutingModule {
}
