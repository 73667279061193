import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AssignmentService, Role, RoleAssignment, RoleService, User, UserService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ErrorService} from '../../services/error.service';

@Component({
    selector: 'app-grant-roles',
    templateUrl: './grant-roles.component.html'
})
export class GrantRolesComponent implements OnInit {

    @Input()
    resourceId: string;
    @Input()
    resourceType: string;

    @Output() onGrant: EventEmitter<any> = new EventEmitter();

    granting = false;
    openGrantModal = false;
    controls: Map<string, UntypedFormControl>;
    form: UntypedFormGroup;
    roles: Array<Role>;
    users: Array<User>;
    errorMessage: any;

    constructor(private authenticationService: AuthenticationService,
                private roleService: RoleService,
                private assignmentService: AssignmentService,
                private userService: UserService,
                private formBuilder: UntypedFormBuilder,
                private errorService: ErrorService,
                private route: ActivatedRoute,
                private router: Router) {
        this.controls = new Map<string, UntypedFormControl>();
        this.users = [];
        this.roles = [];

        this.form = this.formBuilder.group({
            role_id: ['', Validators.required],
            principal_id: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.roleService.findRoles(this.authenticationService.getTenant()).subscribe(r => {
            this.roles = r;
        });
        this.userService.findUsers(this.authenticationService.getTenant()).subscribe(r => {
            this.users = r;
        });
    }

    grant(): void {
        this.granting = true;
        const assignment: RoleAssignment = {
            resource_type: this.resourceType,
            resource_id: this.resourceId,
            principal_type: 'user',
            principal_id: this.form.get('principal_id').value,
            role_id: this.form.get('role_id').value
        };
        this.assignmentService.createRoleAssignment(this.authenticationService.getTenant(), assignment)
            .subscribe(r => {
                this.openGrantModal = false;
                this.granting = false;
                this.onGrant.emit();
                // TODO refresh content
            }, e => {
                this.errorMessage = this.errorService.getMessage(e);
                this.granting = false;
            });
    }
}
