<div style="margin-top: 15px;" *ngFor="let follower of following">
    <ng-container *ngIf="projects && follower.type === 'project'">
        <a routerLink="/projects/{{follower.id}}">
            <span class="badge badge-blue" style="background:{{projects.get(follower.id).badge}}; width: 40px;height: 40px;border-radius: 50%;"></span>
            {{projects.get(follower.id).name}}
        </a>
    </ng-container>
    <ng-container *ngIf="users && follower.type === 'user'">
        <a routerLink="/advanced/security/users/{{follower.id}}">
            <app-user-icon style="margin-bottom: 32px; align-self: baseline;margin-right: 5px;" [names]="[users.get(follower.id).firstname + ' ' + users.get(follower.id).lastname]"></app-user-icon>
            {{users.get(follower.id).firstname}} {{users.get(follower.id).lastname}}
        </a>
    </ng-container>
</div>
