import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import '@cds/core/tag/register';
import {FollowService, Project, Project1, ProjectService, User, User1, UserService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-following',
    templateUrl: './following.component.html'
})
export class FollowingComponent implements OnInit, OnChanges {

    @Input()
    resourceId: string;
    @Input()
    resourceType: string;

    following: Array<User1 | Project1>;
    projects: Map<string, Project>;
    users: Map<string, User>;

    constructor(
        private authenticationService: AuthenticationService,
        private followService: FollowService,
        private projectService: ProjectService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {

        if (!this.resourceType || !this.resourceId) {
            const u = this.authenticationService.getInternalUser();
            this.resourceId = u.id;
            this.resourceType = 'user';
        }

        this.followService.getUserFollowing(this.authenticationService.getTenant(), this.resourceId)
            .subscribe(f => {
                this.following = f;

                const projectSubscriptions = this.following
                    .filter(r => r.type === 'project')
                    .map(r =>
                        this.projectService.findProjectById(this.authenticationService.getTenant(), r.id)
                    );
                forkJoin(projectSubscriptions)
                    .subscribe(a => {
                        this.projects = new Map(a.map(i => [i.id, i]));
                    });

                const userSubscriptions = this.following
                    .filter(r => r.type === 'user')
                    .map(r =>
                        this.userService.findUserById(this.authenticationService.getTenant(), r.id)
                    );
                forkJoin(userSubscriptions)
                    .subscribe(a => {
                        this.users = new Map(a.map(i => [i.id, i]));
                    });
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnInit();
    }
}
