<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <p><a [routerLink]="['/projects']">Projects</a> > <a [routerLink]="['/projects', project.id]">{{project.name}}</a> > Permissions</p>
        <h1 style="margin-top: 0.2rem;">Permissions</h1>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12" style="margin-top: 8px;">
        <clr-dg-action-bar>
            <app-grant-roles [resourceId]="project.id" [resourceType]="'project'" *hasPermission="'/project/grant'; id project.id; type 'project'"></app-grant-roles>
        </clr-dg-action-bar>
        <app-display-roles [mroles]="[roles,tenantRoles]" [mtype]="'project'"></app-display-roles>
    </div>
</div>
