<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <p><a [routerLink]="['/projects']">Projects</a> > <a
                [routerLink]="['/projects', project.id]">{{project.name}}</a> > Configuration</p>
        <h1 style="margin-top: 0.2rem;">Configuration</h1>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-8">
        <h4>Display</h4>

        <form clrForm [formGroup]="form" (ngSubmit)="update()">
            <div class="clr-form-control">
                <label class="clr-control-label" style="padding-left: 0px;">Badge</label>
                <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                        <input formControlName="badge" class="clr-input" [value]="form.get('badge').value"
                            [colorPicker]="form.get('badge').value"
                            (colorPickerChange)="form.get('badge').setValue($event)"
                            [style.background]="form.get('badge').value" />
                    </div>
                    <ng-container *ngIf="form.get('badge').errors">
                        <span *ngIf="form.get('badge').errors?.hex && (form.touched || form.dirty)" class="clr-subtext"
                            style="color: var(--clr-forms-invalid-color, #c21d00);">Invalid color format</span>
                    </ng-container>
                </div>
            </div>
            <div class="clr-form-control">
                <label class="clr-control-label" style="padding-left: 0px;">Banner</label>
                <div class="btn-group">
                    <button type="button" class="btn btn-sm" (click)="uploader.click()">
                        <cds-icon shape="plus"></cds-icon>
                        Change
                    </button>
                </div>
                <input hidden type="file" #uploader (change)="doUploadFile($event)" />

            </div>

            <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Save</button>
        </form>
    </div>
</div>