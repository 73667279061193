import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {ProjectDetailsComponent} from './details/project-details.component';
import {ProjectListComponent} from './list/project-list.component';
import {ProjectResolver} from './project-resolver.service';
import {ProjectRoutingModule} from './project-routing.module';
import {
    ClrCheckboxModule,
    ClrComboboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrModalModule,
    ClrPasswordModule,
    ClrSpinnerModule,
    ClrStackViewModule,
    ClrStepperModule,
    ClrTabsModule,
    ClrTreeViewModule
} from '@clr/angular';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxEchartsModule} from 'ngx-echarts';
import {EditableModule} from '@ngneat/edit-in-place';
import {DirectivesModule} from '../shared/directives/directives.module';
import {ComponentsModule} from '../shared/components/components.module';
import {ColorPickerModule} from 'ngx-color-picker';
import {ProjectConfigurationComponent} from './configuration/project-configuration.component';
import {ProjectMetricsComponent} from './metrics/project-metrics.component';
import {ProjectPermissionsComponent} from './permissions/project-permissions.component';
import {ProjectCostsComponent} from './budget/project-costs.component';
import {PipesModule} from '../shared/pipes/pipes.module';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {RedirectJobComponent} from './redirect/redirect-job.component';
import {RedirectJobRunComponent} from './redirect/redirect-job-run.component';
import {RedirectWorkflowRunComponent} from './redirect/redirect-workflow-run.component';
import {RedirectWorkflowComponent} from './redirect/redirect-workflow.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        ProjectRoutingModule,
        ClrDatagridModule,
        CommonModule,
        ClrIconModule,
        ClrDropdownModule,
        ClrTreeViewModule,
        ClrStepperModule,
        ClrInputModule,
        FormsModule,
        ReactiveFormsModule,
        ClrCheckboxModule,
        ClrComboboxModule,
        ClrPasswordModule,
        ClrModalModule,
        ClrSpinnerModule,
        ClrTabsModule,
        NgxEchartsModule,
        // JobModule,
        EditableModule,
        DirectivesModule,
        ComponentsModule,
        // WorkflowModule,
        ColorPickerModule,
        ClrStackViewModule,
        PipesModule,
        PickerModule
    ],
    declarations: [
        ProjectDetailsComponent,
        ProjectConfigurationComponent,
        ProjectMetricsComponent,
        ProjectCostsComponent,
        ProjectPermissionsComponent,
        ProjectListComponent,
        RedirectJobComponent,
        RedirectJobRunComponent,
        RedirectWorkflowRunComponent,
        RedirectWorkflowComponent
    ],
    providers: [
        ProjectResolver
    ]
})
export class ProjectModule {
}
