import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-infra-icon',
    templateUrl: './infra-icon.component.html'
})
export class InfraIconComponent implements OnInit {

    @Input()
    type: string;

    @Input()
    size: number;

    @Input()
    displayTitle = false;

    word = '';

    constructor() {
    }

    ngOnInit(): void {
        if (this.displayTitle) {
            switch (this.type.toLowerCase()) {
                case 'aws':
                    this.word = 'Amazon Web Service';
                    break;
                case 'azure':
                    this.word = 'Azure';
                    break;
                case 'gcp':
                    this.word = 'Google Cloud';
                    break;
                case 'hetzner':
                    this.word = 'Hetzner';
                    break;
                case 'ovh':
                    this.word = 'OVH';
                    break;
                case 'scaleway':
                    this.word = 'Scaleway';
                    break;
                default:
                    this.word = 'Other';
            }
        }
    }
}
