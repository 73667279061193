import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import '@cds/core/divider/register.js';
import {AuthenticationService} from '../../auth/authentication.service';
import {UntypedFormGroup} from '@angular/forms';
import {Project, ProjectService, RoleAndPrincipalAndAssignment, Style, TenantService} from '../../../generated';

@Component({
    selector: 'app-project-permissions-page',
    templateUrl: './project-permissions.component.html'
})
export class ProjectPermissionsComponent implements OnInit {

    form: UntypedFormGroup;
    style: Style;
    project: Project;

    roles: Array<RoleAndPrincipalAndAssignment>;
    tenantRoles: Array<RoleAndPrincipalAndAssignment>;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private tenantService: TenantService,
        private projectService: ProjectService
    ) {
    }

    ngOnInit(): void {
        // Retrieve the prefetched project
        this.route.data.subscribe(
            (data) => {
                this.project = data.project;
            });
    }

    refresh(): void {
        this.ngOnInit();
    }
}
