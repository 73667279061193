<button class="btn btn-sm btn-link"
        style="border: 0;transform: translateX(-60px);cursor: pointer;font-size: 24px;"
        (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">😀
</button>
<div *ngIf="isEmojiPickerVisible" style="position: absolute;z-index: 9;">
<emoji-mart
        class="emoji-mart"
        [style]="style"
        *ngIf="isEmojiPickerVisible"
        (emojiSelect)="addEmoji($event)"
        title="Choose your emoji"
></emoji-mart>
</div>
