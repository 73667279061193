import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {assignUserIcon, ClarityIcons, pinIcon} from '@cds/core/icon';
import {UntypedFormControl} from '@angular/forms';

ClarityIcons.addIcons(assignUserIcon, pinIcon);

@Component({
    selector: 'app-emoji',
    templateUrl: './emoji.component.html'
})
export class EmojiComponent implements OnInit {

    @Input()
    control: UntypedFormControl;
    public isEmojiPickerVisible: boolean;

    @Output() reload: EventEmitter<boolean> = new EventEmitter();
    style: { 'font-family': 'Metropolis, \"Avenir Next\", "Helvetica Neue", Arial, sans-serif' };

    ngOnInit(): void {
    }

    addEmoji($event: any): void {
        const emoji = $event.emoji.native;
        this.control.setValue(this.control.value + emoji);
        this.isEmojiPickerVisible = false;
    }

}
