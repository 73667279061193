import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';

import '@cds/core/icon/register.js';
import '@cds/core/selection-panels/radio/register.js';
import '@cds/core/toggle/register.js';
import { bellIcon, ClarityIcons, cogIcon, plusIcon, sunIcon } from '@cds/core/icon';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BashOptions,
    BlobMetadata,
    CronSchedule,
    DaskOptions,
    DbtOptions,
    FileLibrary,
    FlinkOptions,
    Identity,
    IdentityService,
    Infrastructure,
    InfrastructureService,
    InstanceType,
    InstanceTypeService,
    Job,
    KnimeOptions,
    LibraryService,
    LowCodeOptions,
    ModelError,
    MXNetOptions,
    Notifications,
    Project,
    ProjectService,
    PythonOptions,
    PyTorchOptions,
    RunOnceSchedule,
    Runtime,
    RuntimeService,
    SASOptions,
    Secret,
    SecretService,
    SparkOptions,
    TensorflowOptions,
    XgboostOptions,
    Graph,
    GraphService, DataWarehouse, DatawarehouseService, DatabaseMigrationOptions, DockerOptions
} from '../../../generated';
import { AuthenticationService } from '../../auth/authentication.service';
import { ByteFormatPipe } from '../../shared/pipes/map/byte_format.pipe';
import { finalize } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';

ClarityIcons.addIcons(sunIcon, plusIcon, bellIcon, cogIcon);

@Component({
    // moduleId: module.id,
    selector: 'app-job-new-page',
    styles: [`
        ::ng-deep .in-clr-stack-block .stack-block-label .stack-view-key {
            max-width: 0px;
        }
    `],
    templateUrl: './job-new.component.html'
})
export class JobNewComponent implements OnInit {
    form: UntypedFormGroup;

    disabled = true;

    identities: Array<Identity>;
    methods = [
        { name: 'GET' },
        { name: 'POST' },
        { name: 'PUT' },
        { name: 'HEAD' },
    ];

    instanceTypes: Array<InstanceType>;
    libraries: Array<BlobMetadata>;
    infrastructures: Array<Infrastructure>;
    runtimes: Array<Runtime>;
    secrets: Array<Secret>;
    dataWarehouses: Array<DataWarehouse>;
    projects: Array<Project>;
    project: Project;
    imageChecking = false;

    frequency: any;
    freqControl: any;

    creating = false;
    openErrorModal = false;
    internalError: ModelError;

    uploadProgress: number;
    uploadSub: Subscription;

    graph_list: Graph[];
    compatibleFramework: string[];

    constructor(private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private projectService: ProjectService,
        private libraryService: LibraryService,
        private secretService: SecretService,
        private dataWarehouseService: DatawarehouseService,
        private identityService: IdentityService,
        private runtimeService: RuntimeService,
        private instanceTypeService: InstanceTypeService,
        private infrastructureService: InfrastructureService,
        private formBuilder: UntypedFormBuilder,
        private byteFormatPipe: ByteFormatPipe,
        private graphService: GraphService,
        private changeDetection: ChangeDetectorRef
    ) {
        const conf = [];
        conf.push(this.createKVItem());
        const preferences = [];
        preferences.push(this.createKVItem());
        const env = [];
        env.push(this.createKVItem());
        const parameters = [];
        parameters.push(this.createKItem());
        const labels = [];
        labels.push(this.createKVItem());


        const notifications = [];
        notifications.push(this.createNotification());

        const defaultInstance = 'Standard_Development_D2_v1';
        const executorInstance = 'Standard_General_G1_v1';

        this.form = this.formBuilder.group({
            basic: this.formBuilder.group({
                name: ['My new job', Validators.required],
                description: [],
                project_id: ['', Validators.required],
                labels: this.formBuilder.array(labels),
                continuous_job: [false],
            }),
            notifications: this.formBuilder.group({
                notifications: this.formBuilder.array(notifications)
            }),
            options: this.formBuilder.group({
                run_as: ['default', Validators.required],
                image: ['docker.io/library/ubuntu:latest', Validators.required],
                libraries: [''],
                type: ['bash', Validators.required],
                version: [''],
                options: [''],
                env: this.formBuilder.array(env),
                parameters: this.formBuilder.array(parameters),
            }),
            specific: this.formBuilder.group({
                // Python
                module: [''],

                // Dask
                package_name: [''],
                module_name: [''],
                function_name: [''],
                number_workers: [1],
                number_workers_max: [2],

                // Spark
                conf: this.formBuilder.array(conf),

                // Bash and SAS
                lines: [''],

                // Flink and Spark
                main_class_name: [''],
                main_library: [''],
                py_files: [''],

                // LowCode
                definition: [''],
                mode: [''],

                // Database Migration
                datawarehouse_id: [''],
                url: [''],
                path: ['/'],
                password: [''],
                username: [''],
                revision: ['main'],

                // KNIME
                archive: [''],
                workflow: [''],
                preferences: this.formBuilder.array(preferences),

                // DBT
                command: [''],
                adapter: [''],
                target: [''],
                profile: [''],

                // DOCKER
                docker_image: [''],
                docker_port: [''],
                docker_command: [''],
                docker_credentials: ['']

            }, { validators: this.jobValidator }),
            schedule: this.formBuilder.group({
                type: ['once', Validators.required],
                cron_expression: [''],
                infrastructure_id: ['']
            }),
            advanced: this.formBuilder.group({
                timeout_seconds: ['3600', Validators.required],
                max_retries: ['0', Validators.required],
                secrets: [''],
                auto_create_identity: ['true', Validators.required],
                identity_id: [''],
            }),
            resources: this.formBuilder.group({

                // Common for Tensorflow, Xgboost and PyTorch
                number_replicas: [1],

                // Spark, LowCode
                number_executors: [1],

                instance_type: [defaultInstance],

                // Dask, MXnet, PyTorch
                worker_instance_type: [executorInstance],

                // Dask, Spark
                driver_instance_type: [defaultInstance],

                // Flink
                job_manager_instance_type: [defaultInstance],
                task_manager_instance_type: [executorInstance],

                // MXnet
                server_instance_type: [executorInstance],
                scheduler_instance_type: [executorInstance],

                // Tensorflow
                replica_instance_type: [executorInstance],

                // XGboost, PyTorch
                master_instance_type: [defaultInstance],

                // Spark, LowCode
                executor_instance_type: [executorInstance]
            }),
            valid: this.formBuilder.group({}),
        });
    }

    jsonValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        try {
            JSON.parse(control.value);
        } catch (e) {
            return { jsonInvalid: true };
        }
        return null;
    }

    pyFilesDoUploadFile(onEvent: any): void {
        if (onEvent.target.files && onEvent.target.files.length > 0) {
            this.uploadSub = this.libraryService.uploadLibrary(this.authenticationService.getTenant(), Array.from(onEvent.target.files), 'events', true)
                .pipe(
                    finalize(() => {
                        this.pyFilesResetUploadFile();
                        this.refreshLibraries();
                    })
                ).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        // console.log('progress:' + this.uploadProgress);
                        this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                    } else if (event.type === HttpEventType.Response) {
                        // console.log('body:' + event.body);
                        let libraries = this.form.get('specific').get('py_files').value;
                        libraries = libraries ? event.body.concat(libraries) : event.body;
                        this.form.get('specific').get('py_files').setValue(libraries);
                    }
                });
        }
    }

    pyFilesCancelUploadFile(): void {
        this.uploadSub.unsubscribe();
        this.pyFilesResetUploadFile();
    }

    pyFilesResetUploadFile(): void {
        this.uploadProgress = null;
        this.uploadSub = null;
    }

    mainDoUploadFile(onEvent: any): void {
        if (onEvent.target.files && onEvent.target.files.length > 0) {
            this.uploadSub = this.libraryService.uploadLibrary(this.authenticationService.getTenant(), Array.from(onEvent.target.files), 'events', true)
                .pipe(
                    finalize(() => {
                        this.mainResetUploadFile();
                        this.refreshLibraries();
                    })
                ).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        // console.log('progress:' + this.uploadProgress);
                        this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                    } else if (event.type === HttpEventType.Response) {
                        // console.log('body:' + event.body);
                        this.form.get('specific').get('main_library').setValue(event.body[0]);
                    }
                });
        }
    }

    mainCancelUploadFile(): void {
        this.uploadSub.unsubscribe();
        this.mainResetUploadFile();
    }

    mainResetUploadFile(): void {
        this.uploadProgress = null;
        this.uploadSub = null;
    }

    librariesDoUploadFile(onEvent: any): void {
        if (onEvent.target.files && onEvent.target.files.length > 0) {
            this.uploadSub = this.libraryService.uploadLibrary(this.authenticationService.getTenant(), Array.from(onEvent.target.files), 'events', true)
                .pipe(
                    finalize(() => {
                        this.librariesResetUploadFile();
                        this.refreshLibraries();
                    })
                ).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                    } else if (event.type === HttpEventType.Response) {
                        let libraries = this.form.get('options').get('libraries').value;
                        libraries = libraries ? event.body.concat(libraries) : event.body;
                        this.form.get('options').get('libraries').setValue(libraries);
                    }
                });
        }
    }

    librariesCancelUploadFile(): void {
        this.uploadSub.unsubscribe();
        this.librariesResetUploadFile();
    }

    librariesResetUploadFile(): void {
        this.uploadProgress = null;
        this.uploadSub = null;
    }

    submit(): void {
        this.creating = true;

        const jobName = this.form.get('basic').get('name').value;
        if (this.form.get('advanced').get('auto_create_identity').value) {
            const identity: Identity = {
                name: jobName
            };
            this.identityService.createIdentity(this.authenticationService.getTenant(), identity).subscribe(i => {
                this.createJob(jobName, i.id);
            });

        } else {
            const identityId = this.form.get('advanced').get('identity_id').value;
            this.createJob(jobName, identityId);
        }
    }

    private createJob(jobName: string, identityId: string): void {
        let metadata = {};
        let options;
        const mainClassName = this.form.get('specific').get('main_class_name').value;
        if (this.form.get('options').get('type').value === 'spark') {
            const mainLibrary: FileLibrary = {
                type: 'file',
                key: this.form.get('specific').get('main_library').value.key as string
            };
            const py_files = this.form.get('specific').get('py_files').value;

            const internal: SparkOptions = {
                type: 'spark',
                main_library: mainLibrary,
                main_class_name: mainClassName,
                conf: this.form.get('specific').get('conf').value
                    .reduce(function (map, obj) {
                        map[obj.key] = obj.value;
                        return map;
                    }, {}),
                driver_instance_type: this.form.get('resources').get('driver_instance_type').value,
                executor_instance_type: this.form.get('resources').get('executor_instance_type').value,
                number_executors: this.form.get('resources').get('number_executors').value,
                py_files: py_files ? py_files.map(l => {
                    return { type: 'file', key: l.key };
                }) : []
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'knime') {
            const archive: FileLibrary = {
                type: 'file',
                key: this.form.get('specific').get('archive').value.key as string
            };

            const internal: KnimeOptions = {
                type: 'knime',
                workflow: this.form.get('specific').get('workflow').value,
                archive,
                preferences: this.form.get('specific').get('preferences').value
                    .reduce(function (map, obj) {
                        map[obj.key] = obj.value;
                        return map;
                    }, {})
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'lowcode') {
            const internal: LowCodeOptions = {
                type: 'lowcode',
                mode: this.form.get('specific').get('mode').value,
                definition: this.form.get('specific').get('definition').value.metadata.flow.dag,
                executor_instance_type: this.form.get('resources').get('executor_instance_type').value,
                number_executors: this.form.get('resources').get('number_executors').value
            };
            options = internal;
            metadata = {
                'graph': this.form.get('specific').get('definition').value
            }
        } else if (this.form.get('options').get('type').value === 'database-migration') {
            const internal: DatabaseMigrationOptions = {
                type: 'database-migration',
                datawarehouse_id: this.form.get('specific').get('datawarehouse_id').value,
                repository: {
                    type: 'git',
                    url: this.form.get('specific').get('url').value,
                    path: this.form.get('specific').get('path').value,
                    password: this.form.get('specific').get('password').value,
                    username: this.form.get('specific').get('username').value,
                    revision: this.form.get('specific').get('revision').value
                }
            };
            options = internal;
            metadata = {
                "graph": this.form.get('specific').get('definition').value
            }
        } else if (this.form.get('options').get('type').value === 'python') {
            const internal: PythonOptions = {
                type: 'python',
                module: this.form.get('specific').get('module').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'dask') {
            const internal: DaskOptions = {
                type: 'dask',
                package_name: this.form.get('specific').get('package_name').value,
                module_name: this.form.get('specific').get('module_name').value,
                function_name: this.form.get('specific').get('function_name').value,
                number_workers: 1,
                number_workers_max: this.form.get('specific').get('number_workers_max').value,
                worker_instance_type: this.form.get('resources').get('worker_instance_type').value,
                driver_instance_type: this.form.get('resources').get('driver_instance_type').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'tensorflow') {
            const internal: TensorflowOptions = {
                type: 'tensorflow',
                module: this.form.get('specific').get('module').value,
                number_replicas: this.form.get('resources').get('number_replicas').value,
                replica_instance_type: this.form.get('resources').get('replica_instance_type').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'pytorch') {
            const internal: PyTorchOptions = {
                type: 'pytorch',
                module: this.form.get('specific').get('module').value,
                number_replicas: this.form.get('resources').get('number_replicas').value,
                worker_instance_type: this.form.get('resources').get('worker_instance_type').value,
                master_instance_type: this.form.get('resources').get('master_instance_type').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'xgboost') {
            const internal: XgboostOptions = {
                type: 'xgboost',
                module: this.form.get('specific').get('module').value,
                number_replicas: this.form.get('resources').get('number_replicas').value,
                master_instance_type: this.form.get('resources').get('master_instance_type').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'mxnet') {
            const internal: MXNetOptions = {
                type: 'mxnet',
                module: this.form.get('specific').get('module').value,
                number_replicas: this.form.get('resources').get('number_replicas').value,
                worker_instance_type: this.form.get('resources').get('worker_instance_type').value,
                server_instance_type: this.form.get('resources').get('server_instance_type').value,
                scheduler_instance_type: this.form.get('resources').get('scheduler_instance_type').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'ray') {
            // TODO
            const internal: MXNetOptions = {
                type: 'mxnet',
                module: this.form.get('specific').get('module').value,
                number_replicas: this.form.get('resources').get('number_replicas').value,
                worker_instance_type: this.form.get('resources').get('worker_instance_type').value,
                server_instance_type: this.form.get('resources').get('server_instance_type').value,
                scheduler_instance_type: this.form.get('resources').get('scheduler_instance_type').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'dbt') {
            const internal: DbtOptions = {
                type: 'dbt',
                command: this.form.get('specific').get('command').value,
                adapter: this.form.get('specific').get('adapter').value,
                target: this.form.get('specific').get('target').value,
                profile: this.form.get('specific').get('profile').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'flink') {
            const mainLibrary: FileLibrary = {
                type: 'file',
                key: this.form.get('specific').get('main_library').value.key as string
            };
            const internal: FlinkOptions = {
                type: 'flink',
                main_library: mainLibrary,
                main_class_name: mainClassName,
                job_manager_instance_type: this.form.get('resources').get('job_manager_instance_type').value,
                task_manager_instance_type: this.form.get('resources').get('task_manager_instance_type').value
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'bash') {
            const internal: BashOptions = {
                type: 'bash',
                lines: this.form.get('specific').get('lines').value.split('\n')
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'sas') {
            const internal: SASOptions = {
                type: 'sas',
                lines: this.form.get('specific').get('lines').value.split('\n')
            };
            options = internal;
        } else if (this.form.get('options').get('type').value === 'docker') {
            const internal: DockerOptions = {
                type: 'docker',
                image: this.form.get('specific').get('docker_image').value,
                port: this.form.get('specific').get('docker_port').value,
                command: this.form.get('specific').get('docker_command').value,
                credentials: this.form.get('specific').get('docker_credentials').value
            }
            options = internal
        }

        if (this.form.get('options').get('type').value === 'docker') {
            options.docker_image = this.form.get('specific').get('docker_image').value
        }
        else {
            options.docker_image = this.form.get('options').get('image').value;
        }

        options.env = this.form.get('options').get('env').value
            .filter(obj => obj.key != null && obj.key !== '')
            .reduce(function (map, obj) {
                map[obj.key] = obj.value;
                return map;
            }, {});
        options.instance_type = this.form.get('resources').get('instance_type').value;

        const libraries = this.form.get('options').get('libraries').value;
        const secrets = this.form.get('advanced').get('secrets').value;

        const notifications = this.form.get('notifications').get('notifications').value;
        const res = notifications.map(n => {
            if (n.type === 'mail') {
                return {
                    type: 'mail',
                    to: n.emailTo,
                    subject: n.emailSubject,
                    on_start: n.on_start,
                    on_success: n.on_success,
                    on_failure: n.on_failure
                };
            } else if (n.type === 'eventhub') {
                return {
                    type: 'eventhub',
                    connection_string: n.eventhubConnectionString,
                    data: n.eventhubData,
                    on_start: n.on_start,
                    on_success: n.on_success,
                    on_failure: n.on_failure
                };
            } else if (n.type === 'webhook') {
                const webhookHeaders = n.webhookHeaders
                    .filter(obj => obj.key != null && obj.key !== '')
                    .reduce(function (map, obj) {
                        map[obj.key] = obj.value;
                        return map;
                    }, {});
                return {
                    type: 'webhook',
                    url: n.webhookUrl,
                    method: n.webhookMethod,
                    headers: webhookHeaders,
                    on_start: n.on_start,
                    on_success: n.on_success,
                    on_failure: n.on_failure
                };
            } else {
                return null;
            }
        });
        const notifs: Notifications = {
            on_start: res.filter(i => i && i.on_start),
            on_success: res.filter(i => i && i.on_success),
            on_failure: res.filter(i => i && i.on_failure)
        };
        let schedule;
        if (this.form.get('schedule').get('type').value === 'once') {
            const internal: RunOnceSchedule = {
                type: 'once'
            };
            schedule = internal;
        } else if (this.form.get('schedule').get('type').value === 'cron') {
            const internal: CronSchedule = {
                type: 'cron',
                cron_expression: this.form.get('schedule').get('cron_expression').value,
                timezone: 'Europe/Paris',
                infrastructure_id: this.form.get('schedule').get('infrastructure_id').value
            };
            schedule = internal;
        }
        const labels = this.form.get('basic').get('labels').value
            .filter(obj => obj.key != null && obj.key !== '')
            .reduce(function (map, obj) {
                map[obj.key] = obj.value;
                return map;
            }, {});
        const job: Job = {
            name: jobName,
            description: this.form.get('basic').get('description').value,
            continuous: this.form.get('basic').get('continuous_job').value,
            libraries: libraries ? libraries.map(l => {
                return { type: 'file', key: l.key };
            }) : [],
            schedule,
            notifications: notifs,
            identity_id: identityId,
            options,
            labels,
            parameters: this.form.get('options').get('parameters').value.filter(obj => obj.key != null && obj.key != '').map(obj => obj.key),
            project_id: this.form.get('basic').get('project_id').value.id,
            secrets: secrets ? secrets.map(l => {
                return l.id;
            }) : [],
            max_retries: this.form.get('advanced').get('max_retries').value,
            timeout_seconds: this.form.get('advanced').get('timeout_seconds').value,
            metadata: metadata
        };
        this.projectService.createJobForProject(this.authenticationService.getTenant(), this.form.get('basic').get('project_id').value.id, job)
            .subscribe(a => {
                this.router.navigate(['projects', this.form.get('basic').get('project_id').value.id, 'jobs', a.id]);
            },
                error => {
                    this.creating = false;
                    this.openErrorModal = true;
                    if (error.headers.get('content-type') === 'application/vnd.graal.systems.v1.error+json;charset=UTF-8') {
                        this.internalError = error.error;
                    }
                });
    }

    createNotification(): UntypedFormGroup {
        const webhookHeaders = [];
        webhookHeaders.push(this.formBuilder.group({
            key: new UntypedFormControl(''),
            value: new UntypedFormControl('')
        }));
        return this.formBuilder.group({
            on_start: new UntypedFormControl(''),
            on_success: new UntypedFormControl(''),
            on_failure: new UntypedFormControl(''),
            type: new UntypedFormControl(''),
            emailTo: new UntypedFormControl(''),
            emailSubject: new UntypedFormControl(''),
            eventhubData: new UntypedFormControl(''),
            eventhubConnectionString: new UntypedFormControl(''),
            webhookUrl: new UntypedFormControl(''),
            webhookMethod: new UntypedFormControl(''),
            webhookHeaders: this.formBuilder.array(webhookHeaders),
        });
    }

    getHeader(index: number) {
        return (this.getCurrentNotification(index).get('webhookHeaders') as UntypedFormArray).controls;
    }

    addHeader(index: number): void {
        const details = this.getCurrentNotification(index).get('webhookHeaders') as UntypedFormArray;
        details.push(this.createKVItem());
    }

    removeHeader(index: number, indexHeader: number): void {
        const details = this.getCurrentNotification(index).get('webhookHeaders') as UntypedFormArray;
        details.removeAt(indexHeader);
    }

    getNotificationCount(): number {
        return (this.form.get('notifications').get('notifications') as UntypedFormArray).length;
    }

    getCurrentNotification(index: number) {
        return (this.form.get('notifications').get('notifications') as UntypedFormArray).at(index);
    }

    getNotification() {
        return (this.form.get('notifications').get('notifications') as UntypedFormArray).controls;
    }

    addNotification(): void {
        const notifications = this.form.get('notifications').get('notifications') as UntypedFormArray;
        notifications.push(this.createNotification());
    }

    removeNotification(index): void {
        const notifications = this.form.get('notifications').get('notifications') as UntypedFormArray;
        notifications.removeAt(index);
    }

    getEnv() {
        return (this.form.get('options').get('env') as UntypedFormArray).controls;
    }

    addEnv(): void {
        const details = this.form.get('options').get('env') as UntypedFormArray;
        details.push(this.createKVItem());
    }

    removeEnv(index) {
        const details = this.form.get('options').get('env') as UntypedFormArray;
        details.removeAt(index);
    }

    resetEnv() {
        var details = this.form.get('options').get('env') as UntypedFormArray;
        for (let i = 0; i <= details.length + 1; i++) {
            this.removeEnv(0)
        }
    }

    loadEnvFromFile(event): void {
        this.resetEnv()
        const details = this.form.get('options').get('env') as UntypedFormArray;
        event.target.files[0].text().then(
            (data) => {
                var separateLines = data.split(/\r?\n|\r|\n/g);
                separateLines.forEach(element => {
                    if (element[0] != "#" && element.length > 1) {
                        var result = element.split('=')
                        details.push(this.formBuilder.group({
                            key: [result.shift()],
                            value: [result.join('=')]
                        }));
                    }
                });
            }
        )
    }

    getLabel() {
        return (this.form.get('basic').get('labels') as UntypedFormArray).controls;
    }

    addLabel(): void {
        const details = this.form.get('basic').get('labels') as UntypedFormArray;
        details.push(this.createKVItem());
    }

    removeLabel(index): void {
        const details = this.form.get('basic').get('labels') as UntypedFormArray;
        details.removeAt(index);
    }

    getParameters() {
        return (this.form.get('options').get('parameters') as UntypedFormArray).controls;
    }

    addParameter(): void {
        const details = this.form.get('options').get('parameters') as UntypedFormArray;
        details.push(this.createKItem());
    }

    removeParameter(index): void {
        const details = this.form.get('options').get('parameters') as UntypedFormArray;
        details.removeAt(index);
    }

    getConf() {
        return (this.form.get('specific').get('conf') as UntypedFormArray).controls;
    }

    addConf(): void {
        const details = this.form.get('specific').get('conf') as UntypedFormArray;
        details.push(this.createKVItem());
    }

    removeConf(index): void {
        const details = this.form.get('specific').get('conf') as UntypedFormArray;
        details.removeAt(index);
    }

    getPreferences() {
        return (this.form.get('specific').get('preferences') as UntypedFormArray).controls;
    }

    addPreferences(): void {
        const details = this.form.get('specific').get('preferences') as UntypedFormArray;
        details.push(this.createKVItem());
    }

    removePreferences(index): void {
        const details = this.form.get('specific').get('preferences') as UntypedFormArray;
        details.removeAt(index);
    }

    getGraphList(): void {
        try {
            this.graphService.findGraphs(
                this.authenticationService.getTenant(),
                undefined,
                undefined,
                undefined,
                "job"
            )
                .subscribe(response => {
                    this.graph_list = response.content;
                })
        } catch (e) {
            console.log(e);
        }
    }

    getCompatibleFramework(event: string): void {
        var compatibleFrameworkDict = null
        setTimeout(() => {
            try {
                compatibleFrameworkDict = this.form.get('specific').get('definition').value.metadata.compatible_framework;
                this.compatibleFramework = Object.keys(compatibleFrameworkDict).filter(key => compatibleFrameworkDict[key]);
                this.changeDetection.detectChanges();
                console.log(this.compatibleFramework)
            } catch (e) {
                console.log(e)
                this.compatibleFramework = ['pyspark', 'java', 'flink', 'beam', 'pandas', 'dask']
                this.changeDetection.detectChanges();
            }
        }, 200);
    }

    jobValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        // control.
        // let value = this.form.get('options').get('type').value;

        // return { sparkIssue: true };
        return null;
    }

    ngOnInit(): void {

        // Retrieve the prefetched project
        this.route.data.subscribe(
            (data) => {
                this.project = data.project;
                if (this.project != null && this.project.id != null) {
                    this.form.get('basic').get('project_id').setValue(this.project);
                } else {
                    this.projectService.findProjects(this.authenticationService.getTenant())
                        .subscribe(r => {
                            this.projects = r;
                        });
                }
                this.instanceTypeService.findInstanceTypes(this.authenticationService.getTenant()).subscribe(r => {
                    this.instanceTypes = r;
                });
                this.infrastructureService.findInfrastructures(this.authenticationService.getTenant()).subscribe(r => {
                    this.infrastructures = r;
                });
                this.identityService.findIdentities(this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.identities = r;
                    });
                this.refreshLibraries();
                this.secretService.findSecrets(this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.secrets = r;
                    });
                this.runtimeService.findRuntimes(this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.runtimes = r;
                        console.log(this.runtimes)
                    });
                this.dataWarehouseService.findDataWarehouses(this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.dataWarehouses = r.content;
                    });
                this.getGraphList();
            });
    }

    private refreshLibraries(): void {
        this.libraryService.findLibraries(this.authenticationService.getTenant())
            .subscribe(r => {
                this.libraries = r;
            });
    }

    createKVItem(): UntypedFormGroup {
        return this.formBuilder.group({
            key: [],
            value: []
        });
    }

    createKItem(): UntypedFormGroup {
        return this.formBuilder.group({
            key: []
        });
    }

    onImageChange($event: Event): void {
        const target = $event.target as HTMLInputElement;
        const value = 'docker.io';
        if (target.value === 'python') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/python:3.9-release-2');
        } else if (target.value === 'bash') {
            this.form.get('options').get('image').setValue(value + '/library/ubuntu:latest');
        } else if (target.value === 'spark') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/spark:python-3.3.1-release-2');
        } else if (target.value === 'sas') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/sas:2.44.0-release-7');
        } else if (target.value === 'tensorflow') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/tensorflow:2.7.1-release-1');
        } else if (target.value === 'dask') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/dask:2023.5.0-py3.8-release-3');
        } else if (target.value === 'pytorch') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/pytorch:1.11.0-release-1');
        } else if (target.value === 'xgboost') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/xgboost:1.5.2-release-1');
        } else if (target.value === 'mxnet') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/mxnet:2.0.0-release-1');
        } else if (target.value === 'dbt') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/dbt:1.7.4-release-1');
        } else if (target.value === 'flink') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/flink:1.17.1-release-1');
        } else if (target.value === 'knime') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/knime:4.6.2-release-2');
        } else if (target.value === 'lowcode') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/lowcode:ignored');
        } else if (target.value === 'database-migration') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/liquibase:1.0.0-release-2');
        } else if (target.value === 'ray') {
            this.form.get('options').get('image').setValue(value + '/graalsystems/ray:1.11.0-release-1');
        } else if (target.value === 'docker') {
            this.form.get('options').get('image').setValue(value + '');
        } else {
            this.form.get('options').get('image').reset();
        }
    }

    instanceInfo(value: string) {
        if (value) {
            const instanceType = this.instanceTypes.filter(i => i.id === value)[0];
            return instanceType.cpus + 'vcores, ' + this.byteFormatPipe.transform(instanceType.memory)
                + ', ' + this.byteFormatPipe.transform(instanceType.storage) + ' SSD, ' + instanceType.gpus + ' GPUs';
        }
        return '';
    }

    goToPage(value: string) {
        this.router.navigate([value])
    }
}
