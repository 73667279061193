import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProjectService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';

@Pipe({name: 'project_banner'})
export class ProjectBannerPipe implements PipeTransform {

    private latestValue!: string | SafeUrl;

    constructor(
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        private domSanitizer: DomSanitizer) {
    }

    transform(imagePath: string): Observable<SafeUrl> {
        return this.projectService.findBannerByProjectId(this.authenticationService.getTenant(), imagePath, 'large')
            .pipe(
                // we map our blob into an ObjectURL
                map((response: Blob) => {
                    const objectURL = URL.createObjectURL(response);
                    return objectURL;
                }),
                // we bypass Angular's security mechanisms
                map((unsafeBlobUrl: string) => {
                    const safeUrl = this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl);
                    return safeUrl;
                })
            );
    }

}
