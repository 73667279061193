import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'callback',
    pure: false
})
export class CallbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any) => any): any {
        if (!items || !callback) {
            return items;
        }
        console.log("--map")
        console.log(items)
        return items.map(item => callback(item));
    }
}
