<clr-modal [(clrModalOpen)]="openErrorModal">
    <h3 class="modal-title">Error</h3>
    <div class="modal-body">
        <p>The project {{form.get('basic').get('name').value}} can not be created.</p>
        <div class="alert alert-danger">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                        <ng-container *ngIf="internalError">
                            <span class="alert-text">{{internalError.message}}</span>
                        </ng-container>
                        <ng-container *ngIf="!internalError">
                            <span class="alert-text">
                            Internal error, please contact the support
                            </span>
                        </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="openErrorModal = false">Ok</button>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-12">
        <p><a [routerLink]="['/projects']">Projects</a> > Add a new project</p>
        <br/>
        <h1 style="display: inline">
            Add a new project
        </h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-12">
        <br/>
        <form clrStepper [formGroup]="form" (ngSubmit)="submit()">
            <clr-stepper-panel formGroupName="basic">
                <clr-step-title>Basic informations</clr-step-title>
                <!--                <clr-step-description>Description goes here.</clr-step-description>-->
                <clr-step-content *clrIfExpanded>
                    <clr-input-container>
                        <label>Name</label>
                        <input clrInput formControlName="name"/>
                        <clr-control-error *clrIfError="'required'">Name required</clr-control-error>
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description</label>
                        <input clrInput formControlName="description"/>
                        <clr-control-error *clrIfError="'required'">Description required</clr-control-error>
                    </clr-input-container>

                    <clr-stack-view style="margin-top: 24px;">
                        <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                            <clr-stack-label>Advanced</clr-stack-label>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">
                                <clr-stack-label ></clr-stack-label>
                                <clr-stack-content>
                                    <h3>Labels</h3>
                                    <p>
                                        <cds-icon shape="error-standard"></cds-icon>
                                        Labels of the project, as tuple of key/value
                                    </p>
                                    <div class="clr-row" formArrayName="labels"
                                         *ngFor="let field of getLabel(); let ind = index;">
                                        <ng-container [formGroupName]="ind">
                                            <div class="clr-col-3">
                                                <div class="clr-form-control">
                                                    <div class="clr-control-container">
                                                        <div class="clr-input-wrapper">
                                                            <input type="email" class="form-control" formControlName="key"
                                                                   class="clr-input"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clr-col-3">
                                                <div class="clr-form-control">
                                                    <div class="clr-control-container">
                                                        <div class="clr-input-wrapper">
                                                            <input type="email" class="form-control" formControlName="value"
                                                                   class="clr-input"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clr-col-2">
                                                <button class="btn btn-danger-outline btn-sm" type="button" (click)="removeLabel(ind)"
                                                        style="margin-top:30px;">
                                                    Remove
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <button class="btn btn-outline btn-sm" type="button" (click)="addLabel()">Add label</button>
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </clr-stack-view>

                    <div class="clr-form-control">
                        <label class="clr-control-label" style="padding-left: 0px;">Badge color</label>
                        <div class="clr-control-container">
                            <div class="clr-input-wrapper">
                                <input formControlName="badge" class="clr-input"
                                       [value]="form.get('basic').get('badge').value"
                                       [colorPicker]="form.get('basic').get('badge').value"
                                       (colorPickerChange)="form.get('basic').get('badge').setValue($event)"
                                       [style.background]="form.get('basic').get('badge').value"/>
                            </div>
                            <ng-container *ngIf="form.get('basic').get('badge').errors">
                                <span *ngIf="form.get('basic').get('badge').errors?.hex && (form.touched || form.dirty)" class="clr-subtext" style="color: var(--clr-forms-invalid-color, #c21d00);">Invalid color format</span>
                            </ng-container>
                        </div>
                    </div>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="valid">
                <clr-step-title>Summary</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <p>Name: {{form.get('basic').get('name').value}}</p>
                    <p>Description: {{form.get('basic').get('description').value}}</p>

                    <button clrStepButton="submit" [disabled]="creating">
                        <clr-spinner [clrSmall]="true" *ngIf="creating" style="margin-top: 7px;"></clr-spinner>
                        submit
                    </button>
                </clr-step-content>
            </clr-stepper-panel>
        </form>

    </div>
</div>
