import {Injectable} from '@angular/core';
import {NotificationsService, NotificationType} from 'angular2-notifications';

@Injectable({
    providedIn: 'root',
})
export class ToastService {

    constructor(private notificationService: NotificationsService) {

    }

    public notify(title: string, content: string, icon: string): void {
        let frame;
        switch (icon) {
            case 'success':
                frame = '<cds-icon status="success" shape="success-standard" size="24"></cds-icon>';
                break;
            case 'failed':
                frame = '<cds-icon status="danger" shape="error-standard" size="24"></cds-icon>';
                break;
            default:
                frame = '<cds-icon status="success" shape="success-standard" size="24"></cds-icon>';
                break;
        }
        this.notificationService.html( `
        <div class="clr-row">
            <div class="clr-col-12" style="padding-left: 0px;">
                <div class="card" style="margin-top: 0px;">
                    <div class="card-block">
                        <div class="clr-row">
                            <div class="clr-col-1">
                                ${frame}
                            </div>
                            <div class="clr-col-11">
                                <h5 style="margin-top: 0px;">${title}</h5>
                                <p style="margin-top: 0px;">${content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `, NotificationType.Bare);
    }
}
