<clr-modal [(clrModalOpen)]="openSearchModal" [clrModalClosable]="true" [clrModalStaticBackdrop]="false">
    <div class="modal-body" style="padding-top: -7px;">
        <div class="clr-row" style="padding-top: -3px;">
            <div class="clr-col-12">
                <form novalidate="" class="search ng-pristine ng-valid ng-touched" style="z-index: 2000;">
                    <label for="search_reactions" ng-reflect-for-attr="search_reactions" style="padding-left: 0px;">
                        <span
                            style='display: inline-block; background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2036%2036%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23ffffff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3ESearch%3C%2Ftitle%3E%3Cg%20id%3D%22icons%22%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22M15%2C4.05A10.95%2C10.95%2C0%2C1%2C1%2C4.05%2C15%2C11%2C11%2C0%2C0%2C1%2C15%2C4.05M15%2C2A13%2C13%2C0%2C1%2C0%2C28%2C15%2C13%2C13%2C0%2C0%2C0%2C15%2C2Z%22%2F%3E%3Cpath%20class%3D%22cls-1%22%20%20d%3D%22M33.71%2C32.29l-7.37-7.42-1.42%2C1.41%2C7.37%2C7.42a1%2C1%2C0%2C1%2C0%2C1.42-1.41Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); background-repeat: no-repeat; background-size: contain; cursor: pointer; height: 1rem; width: 1rem; margin: 1rem 0 0; vertical-align: top;margin-top: 16px;'></span>
                        <input id="search_reactions" #search_reactions name="search_reactions" type="text"
                            placeholder="Search reactions"
                            style="width: 400px; padding-left: 14px;background-color: transparent;border: none;margin-top: 12px;margin-bottom: 12px;color: white;">
                    </label>
                </form>
            </div>
            <div class="clr-col-12">
                <span *ngFor="let reaction of results">
                    <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"
                        (click)="addReactionAndCloseModal(reaction.id)">
                        <span class="tooltip-content">{{ reaction.id }}</span>
                        <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                            style="font-size: 24px; margin: 8px;"></span>
                    </a>
                </span>
            </div>
        </div>
    </div>
</clr-modal>

<div class="clr-row" style="margin-top: 33px;" *ngFor="let activity of activities">
    <div class="clr-col-12" *ngIf="$any(activity.data).actor">

        <div class="clr-row" *ngIf="$any(activity).verb === 'userfollow'">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:fire"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> follow
                user <h6 style="display: inline;">{{ $any(activity.data).target.firstname }} {{
                    $any(activity.data).target.lastname }}</h6>
            </div>
        </div>
        <!--        <div class="clr-row" *ngIf="$any(activity).verb === 'projectfollow'">-->
        <!--            <div class="clr-col-12">-->
        <!--                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>-->
        <!--                <span class="iconify-inline" data-icon="fluent-emoji:fire" style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>-->
        <!--                <h6 style="display: inline;"><a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{ $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> follow project<h6 style="display: inline;">{{ $any(activity.data).target.name }}</h6>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="clr-row" *ngIf="$any(activity).verb === 'projectcreation' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:ringed-planet"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> created
                <h6 style="display: inline;">{{ $any(activity.data).project.name }} - {{ activity.time | dateAgo }}</h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="margin-top: 0.2rem;">{{ $any(activity.data).project.name }}</h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).project.name }}</h4>
                                <p>{{ $any(activity.data).project.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'projectdeletion' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:fire"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> deleted
                the project <h6 style="display: inline;">{{ $any(activity.data).project.name }} - {{ activity.time |
                    dateAgo }}</h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="margin-top: 0.2rem;">{{ $any(activity.data).project.name }}</h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).project.name }}</h4>
                                <p>{{ $any(activity.data).project.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'projectupdate' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:pencil"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> updated project
                <h6 style="display: inline;"><a [routerLink]="['/projects', $any(activity.data).new.id]">{{
                        $any(activity.data).new.name }}</a>
                    - {{ activity.time | dateAgo }}
                </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="margin-top: 0.2rem;">{{ $any(activity.data).new.name }}</h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).new.name }}</h4>
                                <code class="language-json">
                                    <p *ngFor="let change of diff($any(activity.data).old, $any(activity.data).new)" style="margin-top: -0.4rem;white-space: pre-wrap;">
                                        <ng-container *ngIf="change.removed">
                                            <p style="background: hsl(9, 100%, 20%);margin-top: 4px;">{{ change.value }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="change.added">
                                            <p style="background: hsl(93, 100%, 13%);margin-top: 4px">{{ change.value }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!change.added && !change.removed">
                                            <p style="margin-top: 4px">{{ change.value }}</p>
                                        </ng-container>
                                    </p>
                                </code>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row"
            *ngIf="$any(activity).verb === 'jobcreation' && ($any(activity.data).project | json) !== '{}' && ($any(activity.data).job | json) !== '{}'">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:rocket"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> created a new job <h6 style="display: inline;"><a
                        [routerLink]="['/projects', $any(activity.data).project.id, 'jobs', $any(activity.data).job.id]">{{
                        $any(activity.data).job.name }}</a></h6> in project <h6 style="display: inline;"><a
                        [routerLink]="['/projects',$any(activity.data).project.id]">{{ $any(activity.data).project.name
                        }}</a>
                    - {{ activity.time | dateAgo }}
                </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'jobs', $any(activity.data).job.id]">{{
                                        $any(activity.data).job.name }}</a></h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).job.name }}</h4>
                                <p>{{ $any(activity.data).job.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px;margin-top: 0px;padding-top: 0px;">
                            <app-runtime-icon [type]="$any(activity.data).job.options.type" [size]=12
                                [displayTitle]="true"></app-runtime-icon>
                        </p>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'jobdeletion' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:fire"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> deleted the job <h6 style="display: inline;">{{ $any(activity.data).job.name }}</h6> in project
                <h6 style="display: inline;"><a [routerLink]="['/projects',$any(activity.data).project.id]">{{
                        $any(activity.data).project.name }}</a>
                    - {{ activity.time | dateAgo }}
                </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;">
                                    <a [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / {{ $any(activity.data).job.name }}
                                </h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).job.name }}</h4>
                                <p>{{ $any(activity.data).job.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px;margin-top: 0px;padding-top: 0px;">
                            <app-runtime-icon [type]="$any(activity.data).job.options.type" [size]=12
                                [displayTitle]="true"></app-runtime-icon>
                        </p>
                        <!--                        <p class="card-footer" style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">-->
                        <!--                            <clr-tooltip *ngFor="let reaction of activity.reaction_count">-->
                        <!--                                <a clrTooltipTrigger class="label label-blue" style="background-color: rgb(26, 31, 34);" >-->
                        <!--                                    <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)" style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}-->
                        <!--                                </a>-->
                        <!--                                <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>-->
                        <!--                                    <span *ngFor="let user of loadUsers(activity, reaction) | async">-->
                        <!--                                        {{user.firstname}} {{user.lastname}}-->
                        <!--                                    </span>-->
                        <!--                                </clr-tooltip-content>-->
                        <!--                            </clr-tooltip>-->
                        <!--                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);" (click)="openModal(activity)">-->
                        <!--                                <cds-icon shape="plus"></cds-icon>-->
                        <!--                            </a>-->
                        <!--                        </p>-->
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'jobupdate' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:pencil"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> edited
                the job <h6 style="display: inline;"><a
                        [routerLink]="['/projects', $any(activity.data).project.id, 'jobs', $any(activity.data).new.id]">{{
                        $any(activity.data).new.name }}</a></h6> in project <h6 style="display: inline;"><a
                        [routerLink]="['/projects',$any(activity.data).project.id]">{{ $any(activity.data).project.name
                        }}</a> - {{ activity.time | dateAgo }}</h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'jobs', $any(activity.data).new.id]">{{
                                        $any(activity.data).new.name }}</a></h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).new.name }}</h4>
                                <code class="language-json">
                                    <p *ngFor="let change of diff($any(activity.data).old, $any(activity.data).new)" style="margin-top: -0.4rem;white-space: pre-wrap;">
                                        <ng-container *ngIf="change.removed">
                                    <p style="background: hsl(9, 100%, 20%);margin-top: 4px;">{{ change.value }}</p>
                                    </ng-container>
                                    <ng-container *ngIf="change.added">
                                        <p style="background: hsl(93, 100%, 13%);margin-top: 4px">{{ change.value }}</p>
                                    </ng-container>
                                    <ng-container *ngIf="!change.added && !change.removed">
                                        <p style="margin-top: 4px">{{ change.value }}</p>
                                    </ng-container>
                                    </p>
                                </code>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px;margin-top: 0px;padding-top: 0px;">
                            <app-runtime-icon [type]="$any(activity.data).new.options.type" [size]=12
                                [displayTitle]="true"></app-runtime-icon>
                        </p>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'jobrun' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:bullseye"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> create
                a run for job <h6 style="display: inline;"><a
                        [routerLink]="['/projects', $any(activity.data).project.id, 'jobs', $any(activity.data).job.id]">{{
                        $any(activity.data).job.name }}</a> - {{ activity.time | dateAgo }}</h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'jobs', $any(activity.data).job.id]">{{
                                        $any(activity.data).job.name }}</a></h6>

                                <ul class="list-unstyled">
                                    <li style="margin-top: 6px;">
                                        <span [ngSwitch]="$any(activity.data).run.status">
                                            <span *ngSwitchCase="'failed'" class="badge badge-blue"
                                                style="background: red;"></span>
                                            <span *ngSwitchCase="'complete'" class="badge badge-blue"
                                                style="background: green;"></span>
                                            <span *ngSwitchCase="'running'" class="badge badge-blue"
                                                style="background: yellow;"></span>
                                            <span *ngSwitchCase="'unknown'" class="badge badge-blue"
                                                style="background: grey;"></span>
                                        </span>
                                        <a
                                            [routerLink]="['/projects', $any(activity.data).project.id, 'jobs', $any(activity.data).job.id, 'runs', $any(activity.data).run.id]">{{
                                            $any(activity.data).run.name }}</a>
                                        at {{ $any(activity.data).run.created | date:'medium' }} on
                                        <a
                                            [routerLink]="['/advanced/resources/infrastructures', $any(activity.data).infrastructure.id]"
                                            *ngIf="$any(activity.data).infrastructure"
                                        >
                                            <app-infra-icon [type]="$any(activity.data).infrastructure.type"
                                                [displayTitle]="false" [size]="16"></app-infra-icon>
                                            {{$any(activity.data).infrastructure.name}}
                                        </a>
                                        <a
                                                [routerLink]="['/advanced/resources/devices', $any(activity.data).device.id]"
                                                *ngIf="$any(activity.data).device"
                                        >
                                            {{$any(activity.data).device.name}}
                                        </a>

                                        <!--                                        <clr-stack-view style="margin-top: 8px;margin-left: 24px;">-->
                                        <!--                                            <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">-->
                                        <!--                                                <clr-stack-label>View logs</clr-stack-label>-->
                                        <!--                                                <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">-->
                                        <!--                                                    <clr-stack-label ></clr-stack-label>-->
                                        <!--                                                    <clr-stack-content>-->
                                        <!--                                                        <code class="language-yaml">-->
                                        <!--                                                            <p style="margin-top: 0px;white-space: pre-wrap;">This is the last logs line 1</p>-->
                                        <!--                                                            <p style="margin-top: 0px;white-space: pre-wrap;">This is the last logs line 1</p>-->
                                        <!--                                                            <p style="margin-top: 0px;white-space: pre-wrap;">This is the last logs line 1</p>-->
                                        <!--                                                        </code>-->
                                        <!--                                                    </clr-stack-content>-->
                                        <!--                                                </clr-stack-block>-->
                                        <!--                                            </clr-stack-block>-->
                                        <!--                                        </clr-stack-view>-->

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row"
             *ngIf="$any(activity).verb === 'experimentcreation' && ($any(activity.data).project | json) !== '{}' && ($any(activity.data).experiment | json) !== '{}'">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                               [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:test-tube"
                      style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> created a new experiment <h6 style="display: inline;"><a
                    [routerLink]="['/projects', $any(activity.data).project.id, 'experiments', $any(activity.data).experiment.id]">{{
                $any(activity.data).experiment.name }}</a></h6> in project <h6 style="display: inline;"><a
                    [routerLink]="['/projects',$any(activity.data).project.id]">{{ $any(activity.data).project.name
                }}</a>
                - {{ activity.time | dateAgo }}
            </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                    $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'experiments', $any(activity.data).experiment.id]">{{
                                    $any(activity.data).experiment.name }}</a></h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).experiment.name }}</h4>
                                <p>{{ $any(activity.data).experiment.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                           style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                      style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'experimentdeletion' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                               [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:fire"
                      style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> deleted the experiment <h6 style="display: inline;">{{ $any(activity.data).experiment.name }}</h6> in
                project <h6 style="display: inline;"><a [routerLink]="['/projects',$any(activity.data).project.id]">{{
                $any(activity.data).project.name }}</a>
                - {{ activity.time | dateAgo }}
            </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;">
                                    <a [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / {{ $any(activity.data).experiment.name
                                    }}
                                </h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).experiment.name }}</h4>
                                <p>{{ $any(activity.data).experiment.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                           style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                      style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'experimentupdate' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                               [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:pencil"
                      style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                    $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> edited
                the experiment <h6 style="display: inline;"><a
                    [routerLink]="['/projects', $any(activity.data).project.id, 'experiments', $any(activity.data).new.id]">{{
                $any(activity.data).new.name }}</a></h6> in project <h6 style="display: inline;"><a
                    [routerLink]="['/projects',$any(activity.data).project.id]">{{ $any(activity.data).project.name
                }}</a> - {{ activity.time | dateAgo }}</h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                    $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'experiments', $any(activity.data).new.id]">{{
                                    $any(activity.data).new.name }}</a></h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).new.name }}</h4>
                                <code class="language-json">
                                    <p *ngFor="let change of diff($any(activity.data).old, $any(activity.data).new)" style="margin-top: -0.4rem;white-space: pre-wrap;">
                                        <ng-container *ngIf="change.removed">
                                            <p style="background: hsl(9, 100%, 20%);margin-top: 4px;">{{ change.value }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="change.added">
                                            <p style="background: hsl(93, 100%, 13%);margin-top: 4px">{{ change.value }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!change.added && !change.removed">
                                            <p style="margin-top: 4px">{{ change.value }}</p>
                                        </ng-container>
                                    </p>
                                </code>
                            </div>
                        </div>
                        <p class="card-footer"
                           style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                      style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row"
             *ngIf="$any(activity).verb === 'endpointcreation' && ($any(activity.data).project | json) !== '{}' && ($any(activity.data).endpoint | json) !== '{}'">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                               [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:test-tube"
                      style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                            $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> created a new endpoint <h6 style="display: inline;"><a
                    [routerLink]="['/projects', $any(activity.data).project.id, 'endpoints', $any(activity.data).endpoint.id]">{{
                    $any(activity.data).endpoint.name }}</a></h6> in project <h6 style="display: inline;"><a
                    [routerLink]="['/projects',$any(activity.data).project.id]">{{ $any(activity.data).project.name
                }}</a>
                - {{ activity.time | dateAgo }}
            </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'endpoints', $any(activity.data).endpoint.id]">{{
                                        $any(activity.data).endpoint.name }}</a></h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).endpoint.name }}</h4>
                                <p>{{ $any(activity.data).endpoint.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                           style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                      style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'endpointdeletion' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                               [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:fire"
                      style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                            $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> deleted the endpoint <h6 style="display: inline;">{{ $any(activity.data).endpoint.name }}</h6> in
                project <h6 style="display: inline;"><a [routerLink]="['/projects',$any(activity.data).project.id]">{{
                    $any(activity.data).project.name }}</a>
                - {{ activity.time | dateAgo }}
            </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;">
                                    <a [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                            $any(activity.data).project.name }}</a> / {{ $any(activity.data).endpoint.name
                                    }}
                                </h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).endpoint.name }}</h4>
                                <p>{{ $any(activity.data).endpoint.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                           style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                      style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'endpointupdate' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                               [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:pencil"
                      style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> edited
                the endpoint <h6 style="display: inline;"><a
                    [routerLink]="['/projects', $any(activity.data).project.id, 'endpoints', $any(activity.data).new.id]">{{
                    $any(activity.data).new.name }}</a></h6> in project <h6 style="display: inline;"><a
                    [routerLink]="['/projects',$any(activity.data).project.id]">{{ $any(activity.data).project.name
                }}</a> - {{ activity.time | dateAgo }}</h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'endpoints', $any(activity.data).new.id]">{{
                                        $any(activity.data).new.name }}</a></h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).new.name }}</h4>
                                <code class="language-json">
                                    <p *ngFor="let change of diff($any(activity.data).old, $any(activity.data).new)" style="margin-top: -0.4rem;white-space: pre-wrap;">
                                        <ng-container *ngIf="change.removed">
                                            <p style="background: hsl(9, 100%, 20%);margin-top: 4px;">{{ change.value }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="change.added">
                                            <p style="background: hsl(93, 100%, 13%);margin-top: 4px">{{ change.value }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!change.added && !change.removed">
                                            <p style="margin-top: 4px">{{ change.value }}</p>
                                        </ng-container>
                                    </p>
                                </code>
                            </div>
                        </div>
                        <p class="card-footer"
                           style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                      style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                               (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>
        
        <div class="clr-row"
            *ngIf="$any(activity).verb === 'workflowcreation' && ($any(activity.data).project | json) !== '{}' && ($any(activity.data).workflow | json) !== '{}'">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:rocket"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> created a new workflow <h6 style="display: inline;"><a
                        [routerLink]="['/projects', $any(activity.data).project.id, 'workflows', $any(activity.data).workflow.id]">{{
                        $any(activity.data).workflow.name }}</a></h6> in project <h6 style="display: inline;"><a
                        [routerLink]="['/projects',$any(activity.data).project.id]">{{ $any(activity.data).project.name
                        }}</a>
                    - {{ activity.time | dateAgo }}
                </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'workflows', $any(activity.data).workflow.id]">{{
                                        $any(activity.data).workflow.name }}</a></h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).workflow.name }}</h4>
                                <p>{{ $any(activity.data).workflow.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'workflowdeletion' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:fire"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;">
                    <a [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a>
                </h6> deleted the workflow <h6 style="display: inline;">{{ $any(activity.data).workflow.name }}</h6> in
                project <h6 style="display: inline;"><a [routerLink]="['/projects',$any(activity.data).project.id]">{{
                        $any(activity.data).project.name }}</a>
                    - {{ activity.time | dateAgo }}
                </h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;">
                                    <a [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / {{ $any(activity.data).workflow.name
                                    }}
                                </h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).workflow.name }}</h4>
                                <p>{{ $any(activity.data).workflow.description }}</p>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'workflowupdate' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:pencil"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> edited
                the workflow <h6 style="display: inline;"><a
                        [routerLink]="['/projects', $any(activity.data).project.id, 'workflows', $any(activity.data).new.id]">{{
                        $any(activity.data).new.name }}</a></h6> in project <h6 style="display: inline;"><a
                        [routerLink]="['/projects',$any(activity.data).project.id]">{{ $any(activity.data).project.name
                        }}</a> - {{ activity.time | dateAgo }}</h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'workflows', $any(activity.data).new.id]">{{
                                        $any(activity.data).new.name }}</a></h6>
                                <h4 style="margin-top: 0.2rem;">{{ $any(activity.data).new.name }}</h4>
                                <code class="language-json">
                                    <p *ngFor="let change of diff($any(activity.data).old, $any(activity.data).new)" style="margin-top: -0.4rem;white-space: pre-wrap;">
                                        <ng-container *ngIf="change.removed">
                                            <p style="background: hsl(9, 100%, 20%);margin-top: 4px;">{{ change.value }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="change.added">
                                            <p style="background: hsl(93, 100%, 13%);margin-top: 4px">{{ change.value }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!change.added && !change.removed">
                                            <p style="margin-top: 4px">{{ change.value }}</p>
                                        </ng-container>
                                    </p>
                                </code>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

        <div class="clr-row" *ngIf="$any(activity).verb === 'workflowrun' && $any(activity).target">
            <div class="clr-col-12">
                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon
                    [names]="[$any(activity.data).actor.firstname + ' ' +  $any(activity.data).actor.lastname]"></app-user-icon>
                <span class="iconify-inline" data-icon="fluent-emoji:bullseye"
                    style="font-size: 28px; margin: 8px;margin-bottom: -16px;margin-left: -19px;"></span>
                <h6 style="display: inline;"><a
                        [routerLink]="['/advanced/security/users',$any(activity.data).actor.id]">{{
                        $any(activity.data).actor.firstname }} {{ $any(activity.data).actor.lastname }}</a></h6> create
                a run for workflow <h6 style="display: inline;"><a
                        [routerLink]="['/projects', $any(activity.data).project.id, 'workflows', $any(activity.data).workflow.id]">{{
                        $any(activity.data).workflow.name }}</a> - {{ activity.time | dateAgo }}</h6>
            </div>
            <div class="clr-col-12">
                <div class="card" style="margin-top: 8px;">
                    <div class="card-block">
                        <div class="card-media-block wrap">
                            <div class="card-media-description" style="margin-top: -4px;">
                                <h6 style="display: inline;margin-top: 0.2rem;"><a
                                        [routerLink]="['/projects',$any(activity.data).project.id]">{{
                                        $any(activity.data).project.name }}</a> / <a
                                        [routerLink]="['/projects', $any(activity.data).project.id, 'workflows', $any(activity.data).workflow.id]">{{
                                        $any(activity.data).workflow.name }}</a></h6>

                                <ul class="list-unstyled">
                                    <li style="margin-top: 6px;">
                                        <span [ngSwitch]="$any(activity.data).run.status">
                                            <span *ngSwitchCase="'failed'" class="badge badge-blue"
                                                style="background: red;"></span>
                                            <span *ngSwitchCase="'complete'" class="badge badge-blue"
                                                style="background: green;"></span>
                                            <span *ngSwitchCase="'running'" class="badge badge-blue"
                                                style="background: yellow;"></span>
                                            <span *ngSwitchCase="'unknown'" class="badge badge-blue"
                                                style="background: grey;"></span>
                                        </span>
                                        <a
                                            [routerLink]="['/projects', $any(activity.data).project.id, 'workflows', $any(activity.data).workflow.id, 'runs', $any(activity.data).run.id]">{{
                                            $any(activity.data).run.name }}</a>
                                        at {{ $any(activity.data).run.created | date:'medium' }} on
                                        <a
                                            [routerLink]="['/advanced/resources/infrastructures', $any(activity.data).infrastructure.id]">
                                            <app-infra-icon [type]="$any(activity.data).infrastructure.type"
                                                [displayTitle]="false" [size]="16"></app-infra-icon>
                                            {{$any(activity.data).infrastructure.name}}
                                        </a>

                                        <!--                                        <clr-stack-view style="margin-top: 8px;margin-left: 24px;">-->
                                        <!--                                            <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">-->
                                        <!--                                                <clr-stack-label>View logs</clr-stack-label>-->
                                        <!--                                                <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">-->
                                        <!--                                                    <clr-stack-label ></clr-stack-label>-->
                                        <!--                                                    <clr-stack-content>-->
                                        <!--                                                        <code class="language-yaml">-->
                                        <!--                                                            <p style="margin-top: 0px;white-space: pre-wrap;">This is the last logs line 1</p>-->
                                        <!--                                                            <p style="margin-top: 0px;white-space: pre-wrap;">This is the last logs line 1</p>-->
                                        <!--                                                            <p style="margin-top: 0px;white-space: pre-wrap;">This is the last logs line 1</p>-->
                                        <!--                                                        </code>-->
                                        <!--                                                    </clr-stack-content>-->
                                        <!--                                                </clr-stack-block>-->
                                        <!--                                            </clr-stack-block>-->
                                        <!--                                        </clr-stack-view>-->

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p class="card-footer"
                            style="padding-left: 0px; padding-right: 0px; margin-top: 0px; padding-top: 0px;padding-bottom: 0px;margin-bottom: -24px;">
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                *ngFor="let reaction of activity.reaction_count">
                                <span class="iconify-inline" [attr.data-icon]="getIcon(reaction.id)"
                                    style="font-size: 24px;margin-right: 5px;"></span> {{ reaction.count }}
                            </a>
                            <a class="label label-blue" style="background-color: rgb(26, 31, 34);"
                                (click)="openModal(activity)">
                                <cds-icon shape="plus"></cds-icon>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <app-comment [activity]="activity"></app-comment>
        </div>

    </div>
</div>



<!--<div class="clr-row" style="margin-top: 33px;">-->
<!--    <div class="clr-col-6">-->
<!--        <div class="clr-row">-->
<!--            <div class="clr-col-12">-->
<!--                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon [names]="['Project 1']"></app-user-icon>-->
<!--                <h6 style="display: inline;">user 1</h6> created <h6 style="display: inline;">project 1</h6>-->
<!--            </div>-->
<!--            <div class="clr-col-12">-->
<!--                <div class="card" style="margin-top: 8px;">-->
<!--                    <div class="card-block">-->
<!--                        <div class="card-media-block wrap">-->
<!--                            <div class="card-media-description" style="margin-top: -4px;">-->
<!--                                <h6 style="margin-top: 0.2rem;">project 1</h6>-->
<!--                                <h4 style="margin-top: 0.2rem;">project 1</h4>-->
<!--                                <p>rn that would come up time and again is the performance overhead of our client SDK and how that might impact the experience end-users have on our customers’ applications. We understand the importance of this concern and as such the Session Replay SDK takes several measures to avoid negatively impacting t</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <p class="card-footer" style="padding-left: 0px; padding-right: 0px;margin-top: 0px;padding-top: 0px;">-->
<!--                            <a class="label label-blue">-->
<!--                                <span class="iconify-inline" data-icon="fluent-emoji:love-you-gesture" style="font-size: 24px;margin-right: 5px;"></span> 3-->
<!--                            </a>-->
<!--                            <a class="label label-blue">-->
<!--                                <span class="iconify-inline" data-icon="fluent-emoji:bell" style="font-size: 24px;margin-right: 5px;"></span> 3-->
<!--                            </a>-->
<!--                            <a class="label label-blue">-->
<!--                                <span class="iconify-inline" data-icon="fluent-emoji:cold-face" style="font-size: 24px;margin-right: 5px;"></span> 3-->
<!--                            </a>-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="clr-row" style="margin-top: 33px;">-->
<!--    <div class="clr-col-6">-->
<!--        <div class="clr-row">-->
<!--            <div class="clr-col-12">-->
<!--                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon [names]="['Project 1']"></app-user-icon>-->
<!--                <h6 style="display: inline;">user 1</h6> created a new job <h6 style="display: inline;">job 1</h6> in project <h6 style="display: inline;">project 1</h6>-->
<!--            </div>-->
<!--            <div class="clr-col-12">-->
<!--                <div class="card" style="margin-top: 8px;">-->
<!--                    <div class="card-block">-->
<!--                        <div class="card-media-block wrap">-->
<!--                            <div class="card-media-description" style="margin-top: -4px;">-->
<!--                                <h6 style="display: inline;margin-top: 0.2rem;">project 1 / job 1</h6>-->
<!--                                <h4 style="margin-top: 0.2rem;">job 1</h4>-->
<!--                                <p>rn that would come up time and again is the performance overhead of our client SDK and how that might impact the experience end-users have on our customers’ applications. We understand the importance of this concern and as such the Session Replay SDK takes several measures to avoid negatively impacting t</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <p class="card-footer" style="padding-left: 0px; padding-right: 0px;margin-top: 0px;padding-top: 0px;">-->
<!--                            <span class="iconify-inline" data-icon="cib:tensorflow" style="font-size: 20px;"></span> Tensorflow-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="clr-row" style="margin-top: 33px;">-->
<!--    <div class="clr-col-6">-->
<!--        <div class="clr-row">-->
<!--            <div class="clr-col-12">-->
<!--                <app-user-icon style="margin-bottom: 32px;  align-self: baseline;margin-right: 8px;" clrVerticalNavIcon [names]="['Project 1']"></app-user-icon>-->
<!--                <h6 style="display: inline;">user 1</h6> run multiple runs of job <h6 style="display: inline;">job 1</h6>-->
<!--            </div>-->
<!--            <div class="clr-col-12">-->
<!--                <div class="card" style="margin-top: 8px;">-->
<!--                    <div class="card-block">-->
<!--                        <div class="card-media-block wrap">-->
<!--                            <div class="card-media-description" style="margin-top: -4px;">-->
<!--                                <h6 style="display: inline;margin-top: 0.2rem;">project 1 / job 1</h6>-->
<!--                                <h4 style="margin-top: 0.2rem;">job 1</h4>-->

<!--                                <ul class="list-unstyled">-->
<!--                                    <li style="margin-top: 6px;">-->
<!--                                        <span class="badge badge-blue" style="width: 24px;background: green;"></span> Run 1 at 2023/05/05:12h00 on <span class="iconify-inline" data-icon="logos:microsoft-azure" style="font-size: 24px;"></span> Azure-->
<!--                                    </li>-->
<!--                                    <li style="margin-top: 6px;">-->
<!--                                        <span class="badge badge-blue" style="width: 24px;background: green;"></span> Run 2 at 2023/05/05:12h00 on <span class="iconify-inline" data-icon="logos:microsoft-azure" style="font-size: 24px;"></span> Azure-->
<!--                                    </li>-->
<!--                                    <li style="margin-top: 6px;">-->
<!--                                        <span class="badge badge-blue" style="width: 24px;background: red;"></span> Run 3 at 2023/05/05:12h00 on <span class="iconify-inline" data-icon="simple-icons:scaleway" style="font-size: 24px;"></span> Scaleway-->

<!--                                        <clr-stack-view style="margin-top: 8px;margin-left: 24px;">-->
<!--                                            <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">-->
<!--                                                <clr-stack-label>View logs</clr-stack-label>-->
<!--                                                <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">-->
<!--                                                    <clr-stack-label ></clr-stack-label>-->
<!--                                                    <clr-stack-content>-->
<!--                                                        <code class="language-yaml">-->
<!--                                                            <p style="margin-top: 0.6rem;white-space: pre-wrap;margin-left: 24px;">This is the last logs line 1</p>-->
<!--                                                            <p style="margin-top: -0.4rem;white-space: pre-wrap;margin-left: 24px;">This is the last logs line 2</p>-->
<!--                                                            <p style="margin-top: -0.4rem;white-space: pre-wrap;margin-left: 24px;">This is the last logs line 3</p>-->
<!--                                                        </code>-->
<!--                                                    </clr-stack-content>-->
<!--                                                </clr-stack-block>-->
<!--                                            </clr-stack-block>-->
<!--                                        </clr-stack-view>-->

<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
