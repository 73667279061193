import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from "../../../auth/authentication.service";
import {
    GroupService,
    IdentityService,
    InfrastructureService,
    Patch,
    ProjectService,
    UserService
} from "../../../../generated";
import {ClarityIcons, lockIcon, unlockIcon} from "@cds/core/icon";

ClarityIcons.addIcons(lockIcon, unlockIcon);

@Component({
    selector: 'app-lock',
    templateUrl: './lock.component.html'
})
export class LockComponent implements OnInit {

    @Input()
    resourceId: string;
    @Input()
    resourceType: string;
    @Input()
    lock: boolean = false;

    @Output() reload: EventEmitter<boolean> = new EventEmitter();

    constructor(private authenticationService: AuthenticationService,
                private projectService: ProjectService,
                private userService: UserService,
                private groupService: GroupService,
                private infrastructureService: InfrastructureService,
                private identityService: IdentityService
    ) {
    }

    ngOnInit() {
    }

    editLock(lock: boolean) {
        let patch: Patch = {
            op: Patch.OpEnum.Replace,
            value: lock as any,
            path: "/locked"
        };

        switch (this.resourceType) {
            case "project":
                this.projectService.updateProject(this.authenticationService.getTenant(), this.resourceId, [patch]).subscribe(r => {
                    this.lockOrUnlock();
                });
                break;
            case "user":
                this.userService.updateUser(this.authenticationService.getTenant(), this.resourceId, [patch]).subscribe(r => {
                    this.lockOrUnlock();
                });
                break;
            case "identity":
                this.identityService.updateIdentity(this.authenticationService.getTenant(), this.resourceId, [patch]).subscribe(r => {
                    this.lockOrUnlock();
                });
                break;
            // case "group":
            //     this.groupService.updateGroup(this.authenticationService.getTenant(), this.resourceId, [patch]).subscribe(r => {
            //         this.lockOrUnlock();
            //     });
            //     break;
        }
    }

    private lockOrUnlock() {
        this.lock = !this.lock;
        this.reload.emit(this.lock);
    }

    lockResource() {
        this.editLock(true);
    }

    unlockResource() {
        this.editLock(false);
    }
}
