import { Injectable } from '@angular/core';

@Injectable()
export class Environment {
  production: boolean = false;

  // urls
  apiUrl: string = "http://tbd";
  wsUrl: string = "http://tbd";
  identityUrl: string = "http://tbd";

  // access
  accessUrl: string = 'http://tbd';

  // documentation
  documentationUrl: string = 'http://tbd';

  // draw
  drawUrl: string = 'http://tbd';

  // auth
  issuer: string = 'http://tbd';
  requireHttps: boolean = true;
  clientId: string = 'graal-ui';
  scope: string = 'openid profile email offline_access';
  responseType: string = 'code';
  disableAtHashCheck: boolean = true;
  showDebugInformation: boolean = true;
  allowedUrls: Array<string> = ['http://tbd'];
  sendAccessToken: boolean = true;

  platformVersion: string = "unknown";

}
