import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import '@cds/core/divider/register.js';
import {Project, ProjectService} from '../../../generated';
import {forkJoin} from 'rxjs';
import {AuthenticationService} from '../../auth/authentication.service';
import {SidebarService} from '../../shared/services/sidebar.service';

@Component({
    selector: 'app-project-list-page',
    templateUrl: './project-list.component.html'
})
export class ProjectListComponent implements OnInit {
    projects: Project[];
    selected: Project[];

    openDeleteModal = false;
    creating = false;
    deleting = false;
    viewAsList = false;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private sidebarService: SidebarService,
        private projectService: ProjectService
    ) {
        this.projects = [];
        this.selected = [];
    }

    ngOnInit(): void {
        this.projectService.findProjects(this.authenticationService.getTenant())
            .subscribe(r => {
                this.projects = r;
            });
    }

    delete(): void {
        this.deleting = true;
        const subscriptions = this.selected.map(s =>
            this.projectService.deleteProjectById(this.authenticationService.getTenant(), s.id));
        forkJoin(subscriptions)
            .subscribe(a => {
                this.openDeleteModal = false;
                this.deleting = false;
                this.sidebarService.forceRefreshProjects();
                this.refresh();
            });
    }

    onExportAll(): void {

    }

    onExportSelected(): void {

    }

    refresh(): void {
        this.ngOnInit();
    }
}
