import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WorkspaceResolver} from './workspace-resolver.service';
import {WorkspaceListComponent} from './list/workspace-list.component';
import {WorkspaceDetailsComponent} from './details/workspace-details.component';

const routes: Routes = [
    {
        path: 'workspaces',
        component: WorkspaceListComponent
    },
    {
        path: 'workspaces/:id',
        component: WorkspaceDetailsComponent,
        resolve: {
            workspace: WorkspaceResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WorkspaceRoutingModule {

}
