<clr-modal [(clrModalOpen)]="openDeleteModal">
    <h3 class="modal-title">Delete the {{selected.length}} selected tickets ?</h3>
    <div class="modal-body">
        <ul cds-list *ngFor="let ticket of selected">
            <li> {{ticket.title}}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="deleting" (click)="delete();">
            <clr-spinner [clrSmall]="true" *ngIf="deleting" style="margin-top: 7px;"></clr-spinner>
            <ng-template [ngIf]="!deleting">Ok</ng-template>
        </button>
    </div>
</clr-modal>
<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <h1 style="float:left">Support center</h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-12">
        <div class="card">
            <div class="card-block">
                <div class="card-media-block wrap">
                    <div class="card-media-description">
                        <div class="clr-row">
                            <div class="clr-col-1" style="margin-right: -66px;">
                                <h3 class="title" style="margin-top: 0.2rem;">
                                    <cds-icon shape="talk-bubbles" size="36"></cds-icon>
                                </h3>
                            </div>
                            <div class="clr-col-2">
                                <h3 class="title" style="margin-top: 0.2rem;">
                                    Current plan
                                </h3>
                                <h5>Basic</h5>
                            </div>
                            <div class="clr-col-3">
                                <h3 class="title" style="margin-top: 0.2rem;">
                                    <cds-badge color="orange"></cds-badge>
                                    Tickets
                                </h3>
                                <h5>24/7</h5>
                            </div>
                            <div class="clr-col-3">
                                <h3 class="title" style="margin-top: 0.2rem;">
                                    <cds-badge color="orange"></cds-badge>
                                    Hotline
                                </h3>
                                <div class="alert-items">
                                    <div class="alert-item static">
                                        <div class="alert-icon-wrapper">
                                            <cds-icon class="alert-icon" shape="bubble-exclamation"></cds-icon>
                                        </div>
                                        <span class="alert-text">
                                            Not available for this support level.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="clr-col-3">
                                <h3 class="title" style="margin-top: 0.2rem;">
                                    <cds-badge color="orange"></cds-badge>
                                    Technical account manager
                                </h3>
                                <div class="alert-items">
                                    <div class="alert-item static">
                                        <div class="alert-icon-wrapper">
                                            <cds-icon class="alert-icon" shape="bubble-exclamation"></cds-icon>
                                        </div>
                                        <span class="alert-text">
                                            Not available for this support level.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="clr-row" style="margin-top: 12px;">
    <div class="clr-col-12 clr-col-sm-12">
        <clr-tabs>
            <clr-tab>
                <button clrTabLink>Tickets</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row" style="margin-top: 8px;">
                        <div class="clr-col-12 clr-col-sm-12">
                            <clr-dg-action-bar>
                                <div class="btn-group" *ngIf="selected.length == 0">
                                    <button type="file" class="btn btn-sm"
                                        [routerLink]="['/support', 'tickets', 'new']">
                                        <cds-icon shape="plus"></cds-icon>
                                        Add
                                    </button>
                                </div>
                                <div class="btn-group" *ngIf="selected.length > 0">
                                    <button type="button" class="btn btn-link btn-sm btn-secondary"
                                        (click)="openDeleteModal = true" *ngIf="selected.length >= 1">
                                        <cds-icon shape="window-close"></cds-icon>
                                        Delete
                                    </button>
                                    <cds-divider orientation="vertical"></cds-divider>
                                </div>
                                <div class="btn-group">
                                    <button type="button" class="btn btn-link btn-sm btn-secondary" (click)="refresh()">
                                        <cds-icon shape="refresh"></cds-icon>
                                        Refresh
                                    </button>
                                    <clr-dropdown>
                                        <button type="button" class="btn btn-link btn-sm btn-secondary"
                                            clrDropdownTrigger>
                                            Export
                                            <cds-icon shape="angle" direction="down"></cds-icon>
                                        </button>
                                        <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                                            <button type="button" (click)="onExportAll()" clrDropdownItem>Export
                                                All</button>
                                            <button type="button" (click)="onExportSelected()"
                                                [disabled]="selected.length === 0" clrDropdownItem>
                                                Export Selected Items
                                            </button>
                                        </clr-dropdown-menu>
                                    </clr-dropdown>
                                </div>
                            </clr-dg-action-bar>

                            <clr-datagrid [(clrDgSelected)]="selected" class="datagrid-compact">
                                <clr-dg-column>
                                    <ng-container *clrDgHideableColumn="{hidden: false}">
                                        Id
                                    </ng-container>
                                </clr-dg-column>
                                <clr-dg-column>
                                    <ng-container *clrDgHideableColumn="{hidden: false}">
                                        Title
                                    </ng-container>
                                </clr-dg-column>
                                <clr-dg-column>Status</clr-dg-column>
                                <clr-dg-column>Description</clr-dg-column>
                                <clr-dg-column>Type</clr-dg-column>
                                <clr-dg-column>Created</clr-dg-column>

                                <clr-dg-row *clrDgItems="let ticket of tickets" [clrDgItem]="ticket">
                                    <clr-dg-cell><a [routerLink]="['/support', 'tickets', ticket.id]"> {{ticket.id}}</a>
                                        <a [copy-clipboard]="ticket.id" [tooltip-title]="'Copied!'"><cds-icon
                                                shape="copy-to-clipboard"></cds-icon></a></clr-dg-cell>
                                    <clr-dg-cell>{{ticket.title}}</clr-dg-cell>
                                    <clr-dg-cell><a
                                            class="label label-{{ticket.status === 'OPEN' ? 'success' : 'danger'}}">{{ticket.status}}</a></clr-dg-cell>
                                    <clr-dg-cell>{{ticket.description}}</clr-dg-cell>
                                    <clr-dg-cell>{{ticket.details.type}}</clr-dg-cell>
                                    <clr-dg-cell>{{ticket.created | date:'medium'}}</clr-dg-cell>
                                </clr-dg-row>

                                <clr-dg-footer>
                                    <clr-dg-pagination #pagination [clrDgPageSize]="20">
                                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Tickets per
                                            page</clr-dg-page-size>
                                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of
                                        {{pagination.totalItems}} tickets
                                    </clr-dg-pagination>
                                </clr-dg-footer>
                            </clr-datagrid>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink>My support plan</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row" style="margin-top: 8px;">
                        <div class="clr-col-12 clr-col-sm-12">
                            <h3>Support plans</h3>
                        </div>
                    </div>
                    <div class="clr-row" style="margin-top: 8px;">
                        <div class="clr-col-3 clr-col-sm-3">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description" style="text-align: center;">
                                            <h3 style="margin-top: 0px;"><cds-icon size="24" shape="home"></cds-icon>
                                            </h3>
                                            <h3 style="margin-top: 0.2rem;">Basic</h3>
                                            <h5 style="margin-top: 0.2rem;">Free</h5>
                                            <cds-divider orientation="horizontal"
                                                style="margin-top: 16px;margin-bottom: 16px;"></cds-divider>
                                            <p>Contact customer service for account and billing questions, and
                                                access the GraalSystems Community Forums</p>
                                        </div>
                                    </div>
                                    <div class="card-footer" style="text-align: center;">
                                        <h6>Your current plan</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clr-col-3 clr-col-sm-3">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description" style="text-align: center;">
                                            <h3 style="margin-top: 0px;"><cds-icon size="24" shape="code"></cds-icon>
                                            </h3>
                                            <h3 style="margin-top: 0.2rem;">Developer</h3>
                                            <h5 style="margin-top: 0.2rem;">49€/month</h5>
                                            <cds-divider orientation="horizontal"
                                                style="margin-top: 16px;margin-bottom: 16px;"></cds-divider>
                                            <p>Get started on GraalSystems - ask technical questions and get a
                                                response to your case within 12 hours during local business hours.</p>
                                        </div>
                                    </div>
                                    <div class="card-footer" style="text-align: center;">
                                        <a class="btn btn-xl disabled">
                                            <cds-icon shape="deploy"></cds-icon> Select
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clr-col-3 clr-col-sm-3">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description" style="text-align: center;">
                                            <h3 style="margin-top: 0px;"><cds-icon size="24" shape="crown"></cds-icon>
                                            </h3>
                                            <h3 style="margin-top: 0.2rem;">Business</h3>
                                            <h5 style="margin-top: 0.2rem;">Starting at 100€/month</h5>
                                            <cds-divider orientation="horizontal"
                                                style="margin-top: 16px;margin-bottom: 16px;"></cds-divider>
                                            <p>24/7/365 real-time assistance by phone and chat, a 1 hour response to
                                                cases, and help with 3rd party software. Access GraalSystems Trusted
                                                Advisor to increase
                                                performance, fault tolerance, security, and potentially save money.</p>
                                        </div>
                                    </div>
                                    <div class="card-footer" style="text-align: center;">
                                        <a class="btn btn-xl disabled">
                                            <cds-icon shape="deploy"></cds-icon> Select
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clr-col-3 clr-col-sm-3">
                            <div class="card">
                                <div class="card-block">
                                    <div class="card-media-block wrap">
                                        <div class="card-media-description" style="text-align: center;">
                                            <h3 style="margin-top: 0px;"><cds-icon size="24"
                                                    shape="building"></cds-icon></h3>
                                            <h3 style="margin-top: 0.2rem;">Enterprise</h3>
                                            <h5 style="margin-top: 0.2rem;">Contact us</h5>
                                            <cds-divider orientation="horizontal"
                                                style="margin-top: 16px;margin-bottom: 16px;"></cds-divider>
                                            <p>15 minutes response to case, an assigned technical account manager
                                                (TAM) who is an expert in your use case, and white-glove cases handling
                                                that notifies your
                                                TAM and the service engineering team of a critical issue.</p>
                                        </div>
                                    </div>
                                    <div class="card-footer" style="text-align: center;">
                                        <a class="btn btn-xl">
                                            Contact us
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
</div>
