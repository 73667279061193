import {Component, ElementRef, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import '@cds/core/tag/register';
import {EnvService, Tenant} from '../../generated';
import {AuthenticationService} from '../auth/authentication.service';
import {UserSettingsService} from '../shared/services/user-settings.service';
import {InternalStyleService} from '../shared/services/internal-style.service';

@Component({
    selector: 'app-preferences',
    templateUrl: './preferences.component.html'
})
export class PreferencesComponent implements OnInit {

    tenant: Tenant;

    isDarkTheme = true;
    isDisplayWelcome = false;

    constructor(@Inject(PLATFORM_ID) private platformId: Record<string, any>,
                private el: ElementRef,
                private envService: EnvService,
                private authenticationService: AuthenticationService,
                private internalStyleService: InternalStyleService,
                private userSettingsService: UserSettingsService) {
        this.tenant = {};
    }

    ngOnInit(): void {
        this.envService.getEnv(this.authenticationService.getTenant()).subscribe(e => {
            this.tenant = e;
        });
        this.isDisplayWelcome = this.userSettingsService.getDisplayWelcome();
    }

    onDisplayWelcomeChange($event: Event): void {
        if (this.isDisplayWelcome) {
            this.userSettingsService.enableWelcome();
        } else {
            this.userSettingsService.disableWelcome();
        }
    }

    onThemeChange($event: Event): void {
        if (this.isDarkTheme) {
            this.internalStyleService.switchToDarkTheme();
            this.userSettingsService.switchDark();
        } else {
            this.internalStyleService.switchToLightTheme();
            this.userSettingsService.switchLight();
        }
    }
}
