import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import '@cds/core/input/register.js';
import {AuthenticationService} from '../../auth/authentication.service';

@Component({
    selector: 'app-data-home-page',
    templateUrl: './data-home.component.html'
})
export class DataHomeComponent {

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute
    ) {
    }

}
