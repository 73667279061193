<button class="btn btn-primary" (click)="onSendMessage()">
    Send Test Message
</button>

<div class="clr-row">
    <div class="clr-col-6" >

        <div class="clr-row" *ngFor="let notification of notifications">
            <div class="clr-col-12" style="padding-left: 0px;">
                <div class="card" style="margin-top: 0px;">
                    <div class="card-block">
                        <div class="clr-row">
                            <div class="clr-col-1">
                                <ng-container [ngSwitch]="notification.icon">
                                    <cds-icon *ngSwitchCase="'success'" status="success" shape="success-standard" size="24"></cds-icon>
                                    <cds-icon *ngSwitchCase="'failed'" status="danger" shape="error-standard" size="24"></cds-icon>
                                    <cds-icon *ngSwitchDefault status="success" shape="success-standard" size="24"></cds-icon>
                                </ng-container>
                            </div>
                            <div class="clr-col-11">
                                <h5 style="margin-top: 0px;">{{notification.subject}}</h5>
                                <p style="margin-top: 0px;">{{notification.body}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
