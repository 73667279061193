import { Component, AfterViewInit, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import '@cds/core/tag/register.js';
import '@cds/core/badge/register.js';
import '@cds/core/divider/register.js';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../auth/authentication.service';
import { Graph, GraphService, UserService, JobService, CodeService, Patch, WorkspaceService, MarketplaceService, SessionsService, DagUnverified, WorkflowService, TasksService } from '../../generated';
import { updateGraph } from './draw.service';
import * as JSZip from 'jszip';
import { Environment } from '../environment';


const jsZip: JSZip = new JSZip();

@Component({
   selector: 'app-draw',
   templateUrl: './draw.component.html'
})
export class DrawComponent implements OnInit {

   drawUrl: SafeUrl;
   resultFiles: Array<any>;
   drawFrame: HTMLIFrameElement;
   channel = new MessageChannel();
   userID = '';

   constructor(
      private route: ActivatedRoute,
      private router: Router,
      private sanitizer: DomSanitizer,
      private graphService: GraphService,
      private authenticationService: AuthenticationService,
      private userService: UserService,
      private codeService: CodeService,
      private jobService: JobService,
      private workflowService: WorkflowService,
      private workspaceService: WorkspaceService,
      private marketplaceService: MarketplaceService,
      private sessionsService: SessionsService,
      private tasksService: TasksService,
      private environment: Environment,
      private angularZone: NgZone
   ) {
      this.resultFiles = [];
      const resourceType = route.snapshot.paramMap.get('resourceType');
      const id = route.snapshot.paramMap.get('graphId');
      const drawMode = route.snapshot.paramMap.get('drawMode');
      if (id != null) {
         this.drawUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.drawUrl + "draw/" + resourceType + "/" + drawMode + "/" + id);
      } else {
         this.drawUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.drawUrl);
      }
      this.userService.findCurrentUser(this.authenticationService.getTenant(), 'response').subscribe(response => { this.userID = response.body.id });
   }

   ngOnInit(): void {
      this.drawFrame = (document.getElementById('draw-frame') as HTMLIFrameElement)
      this.drawFrame.addEventListener("load", () => { this.initFrameCommunication() });
   }

   downloadUrl(url: string) {
      window.open(url, '_self');
   }

   initFrameCommunication() {
      this.channel.port1.onmessage = (event) => { this.onDrawFrameMessage(event) }
      this.drawFrame.contentWindow.postMessage(
         {
            "type": "init"
         },
         "*",
         [this.channel.port2]
      );
   }

   onDrawFrameMessage(messageEvent: MessageEvent<any>) {
      switch (messageEvent.data.type) {
         case "create_graph":
            const graph: Graph = {
               name: messageEvent.data.data.name,
               description: "temp description",
               version: "0.1",
               type: messageEvent.data.data.type,
               metadata: {
                  "flow": messageEvent.data.data.flow,
                  "preview": messageEvent.data.data.preview,
                  "compatible_framework": {
                     "pyspark": false,
                     "java": false,
                     "flink": false,
                     "beam": false,
                     "pandas": false,
                     "dask": false
                  }
               }
            }
            this.graphService.createGraph(
               this.authenticationService.getTenant(),
               graph,
               'response'
            )
               .subscribe(response => {
                  let data = {}
                  if (response.status === 201) {
                     data = {
                        "id": response.body.id,
                        "result": "success"
                     }
                  } else {
                     data = {
                        "result": "failure"
                     }
                  }
                  messageEvent.ports[0].postMessage(
                     {
                        "type": "new_graph",
                        "data": data
                     }
                  );
               });
            break
         case "export_code":
            this.tasksService.applyTask(
               this.authenticationService.getTenant(),
               {
                  "type": "code",
                  "dag": messageEvent.data.data.dag,
                  "language": messageEvent.data.data.language
               }
            ).subscribe(blob => {
               console.log(blob)
               var blobUrl = URL.createObjectURL(blob);
               this.downloadUrl(blobUrl)
            }
            )
            break
         case "my_graph_list":
            this.graphService.findGraphs(
               this.authenticationService.getTenant(),
               0,
               200,
               this.userID
            )
               .subscribe(response => {
                  messageEvent.ports[0].postMessage(
                     {
                        "type": "my_graph_list",
                        "data": {
                           "content": response.content,
                           "size": response.size,
                           "result": "success"
                        }
                     }
                  );
               });
            break
         case "all_graph_list":
            this.graphService.findGraphs(
               this.authenticationService.getTenant(),
               undefined
            )
               .subscribe(response => {
                  messageEvent.ports[0].postMessage(
                     {
                        "type": "all_graph_list",
                        "data": {
                           "content": response.content,
                           "size": response.size,
                           "result": "success"
                        }
                     }
                  );
               });
            break
         case "job_graph_list":
            this.jobService.findJobs(
               this.authenticationService.getTenant(),
               "lowcode",
                0,
                200
            ).subscribe(response => {
               messageEvent.ports[0].postMessage(
                  {
                     "type": "job_graph_list",
                     "data": {
                        "content": response,
                        "result": "success"
                     }
                  }
               );
            });
            break
         case "workflow_graph_list":
            this.workflowService.findWorkflows(
               this.authenticationService.getTenant()
            ).subscribe(response => {
               messageEvent.ports[0].postMessage(
                  {
                     "type": "workflow_graph_list",
                     "data": {
                        "content": response,
                        "result": "success"
                     }
                  }
               );
            });
            break
         case "update_graph":
            updateGraph(
               this.graphService,
               this.jobService,
               this.workflowService,
               this.authenticationService,
               messageEvent)
            break
         case "delete_graph":
            this.graphService.deleteGraphById(
               this.authenticationService.getTenant(),
               messageEvent.data.data.id,
               'response'
            ).subscribe(response => {
               messageEvent.ports[0].postMessage(
                  {
                     "type": "delete_graph",
                     "data": {
                        "result": "success"
                     }
                  }
               )
            }
            )
            break
         case 'get_graph':
            if (messageEvent.data.data.resourceType == "job") {
               if (messageEvent.data.data.drawMode == "draft") {
                  this.graphService.findGraphById(
                     this.authenticationService.getTenant(),
                     messageEvent.data.data.id,
                     'response'
                  ).subscribe(response => {
                     console.log(response.body)
                     messageEvent.ports[0].postMessage(
                        {
                           "type": "get_graph",
                           "data": {
                              "body": response.body,
                              "result": "success",
                              "status": 200
                           }
                        }
                     )
                  },
                     error => {
                        messageEvent.ports[0].postMessage(
                           {
                              "type": "get_graph",
                              "data": {
                                 "result": "fail",
                                 "status": error.status
                              }
                           }
                        )
                     }
                  )
               }
               else if (messageEvent.data.data.drawMode == "project") {
                  this.jobService.findJobByJobId(
                     this.authenticationService.getTenant(),
                     messageEvent.data.data.id,
                     'response'
                  ).subscribe(response => {
                     console.log(response)
                     messageEvent.ports[0].postMessage(
                        {
                           "type": "get_graph",
                           "data": {
                              "body": response.body,
                              "result": "success",
                              "status": 200
                           }
                        }
                     )
                  },
                     error => {
                        messageEvent.ports[0].postMessage(
                           {
                              "type": "get_graph",
                              "data": {
                                 "result": "fail",
                                 "status": error.status
                              }
                           }
                        )
                     }
                  )
               }
            } else if (messageEvent.data.data.resourceType == "workflow") {
               if (messageEvent.data.data.drawMode == "draft") {
                  this.graphService.findGraphById(
                     this.authenticationService.getTenant(),
                     messageEvent.data.data.id,
                     'response'
                  ).subscribe(response => {
                     messageEvent.ports[0].postMessage(
                        {
                           "type": "get_graph",
                           "data": {
                              "body": response.body,
                              "result": "success",
                              "status": 200
                           }
                        }
                     )
                  },
                     error => {
                        messageEvent.ports[0].postMessage(
                           {
                              "type": "get_graph",
                              "data": {
                                 "result": "fail",
                                 "status": error.status
                              }
                           }
                        )
                     }
                  )
               } else if (messageEvent.data.data.drawMode == "project") {
                  this.workflowService.findWorkflowById(
                     this.authenticationService.getTenant(),
                     messageEvent.data.data.id,
                     'response'
                  ).subscribe(response => {
                     messageEvent.ports[0].postMessage(
                        {
                           "type": "get_graph",
                           "data": {
                              "body": response.body,
                              "result": "success",
                              "status": 200
                           }
                        }
                     )
                  },
                     error => {
                        messageEvent.ports[0].postMessage(
                           {
                              "type": "get_graph",
                              "data": {
                                 "result": "fail",
                                 "status": error.status
                              }
                           }
                        )
                     }
                  )
               }
            }
            break
         case 'workspace_list':
            this.workspaceService.findWorkspaces(
               this.authenticationService.getTenant(),
               0,
               200,
               'response'
            ).subscribe(response => {
               messageEvent.ports[0].postMessage(
                  {
                     "type": "workspace_list",
                     "data": {
                        "result": "success",
                        "content": response.body.content
                     }
                  }
               )
            }
            )
            break
         case 'add_code_to_workspace':
            const code_data = [new Blob([messageEvent.data.data.dag], { type: 'application/json' })];
            const that = this;
            this.codeService.downloadCode(
               this.authenticationService.getTenant(),
               'etl',
               messageEvent.data.data.language,
               code_data,
               'response'
            ).subscribe(blob => {
               jsZip.loadAsync(blob.body.arrayBuffer())
                  .then(function (zip) {
                     const entries = Object.keys(zip.files).map(function (name) {
                        return zip.files[name];
                     });
                     const listOfPromises = entries.map(function (entry) {
                        return entry.async('string').then(function (u8) {
                           // we bind the two together to be able to match the name and the content in the last step
                           return [entry.name, u8];
                        });
                     });
                     const promiseOfList = Promise.all(listOfPromises);
                     promiseOfList.then(function (list) {
                        const resultFiles = list.map(value => {
                           return { name: value[0], content: value[1], active: false };
                        });
                        return resultFiles;
                     });

                     return promiseOfList;
                  }
                  )
                  .then(function (fileList) {
                     const fileObjectList = new Array();
                     console.log(fileList)
                     fileList.map(
                        function (newFile) {
                           const blob: Blob = new Blob([newFile[1]], { type: 'application/octet-stream' });
                           const file = new File([blob], newFile[0]);
                           fileObjectList.push(file)
                        }
                     )
                     return (fileObjectList)
                  })
                  .then(function (entries) {
                     console.log(entries)
                     console.log(messageEvent)
                     that.workspaceService.uploadFileInWorkspace(
                        that.authenticationService.getTenant(),
                        messageEvent.data.data.workspace_id,
                        messageEvent.data.data.workspace_path,
                        entries
                     ).subscribe(response => {
                        console.log(response)
                        messageEvent.ports[0].postMessage(
                           {
                              "type": "add_code_to_workspace",
                              "data": {
                                 "result": "success"
                              }
                           }
                        )
                     }
                     )
                  })
            }
            )
            break
         case 'navigate':
            this.angularZone.run(() => {
               this.router.navigate([messageEvent.data.data.path])
            })
            break
         case 'template_list':
            this.marketplaceService.findTemplates(
               this.authenticationService.getTenant()
            ).subscribe(response => {
               messageEvent.ports[0].postMessage(
                  {
                     "type": "template_list",
                     "data": {
                        "result": "success",
                        "content": response
                     }
                  }
               )
            }
            )
            break
         case 'template_versions':
            this.marketplaceService.findTemplateVersions(
               this.authenticationService.getTenant(),
               messageEvent.data.data.template_name,
            ).subscribe(response => {
               messageEvent.ports[0].postMessage(
                  {
                     "type": "template_versions",
                     "data": {
                        "result": "success",
                        "content": response
                     }
                  }
               )
            }
            )
            break
         case 'template_metadata':
            this.marketplaceService.findTemplateMetadata(
               this.authenticationService.getTenant(),
               messageEvent.data.data.template_name,
               messageEvent.data.data.template_version
            ).subscribe(response => {
               messageEvent.ports[0].postMessage(
                  {
                     "type": "template_metadata",
                     "data": {
                        "result": "success",
                        "content": response
                     }
                  }
               )
            }
            )
            break
         case 'generate_template':
            this.marketplaceService.generateTemplate(
               this.authenticationService.getTenant(),
               messageEvent.data.data.template_name,
               messageEvent.data.data.template_version,
               messageEvent.data.data.template_body
            ).subscribe(response => {
               messageEvent.ports[0].postMessage(
                  {
                     "type": "generate_template",
                     "data": {
                        "result": "success",
                        "content": response
                     }
                  }
               )
            },
               error => {
                  messageEvent.ports[0].postMessage(
                     {
                        "type": "generate_template",
                        "data": {
                           "result": "fail",
                           "status": error.status
                        }
                     }
                  )
               }
            )
            break
         case 'create_session':
            this.sessionsService.createSession(
               this.authenticationService.getTenant(),
               messageEvent.data.data.name,
            ).subscribe(response => {
               console.log(response)
               messageEvent.ports[0].postMessage(
                  {
                     "type": "create_session",
                     "data": {
                        "result": "success",
                        "session_id": response[0],
                        "workspace_id": response[1]
                     }
                  }
               )
            }
            )
            break
         case 'create_notebook':
            this.sessionsService.createNotebook(
               this.authenticationService.getTenant(),
               messageEvent.data.data.session_id
            ).subscribe(response => {
               console.log(response)
               messageEvent.ports[0].postMessage(
                  {
                     "type": "create_notebook",
                     "data": {
                        "result": "success",
                        "response": response
                     }
                  }
               )
            }, error => {
               console.log(error)
               messageEvent.ports[0].postMessage(
                  {
                     "type": "create_notebook",
                     "data": {
                        "result": "fail"
                     }
                  }
               )
            }
            )
            break
         case 'run_session':
            let dagUnverified: DagUnverified = JSON.parse(messageEvent.data.data.dag);
            this.sessionsService.runSession(
               this.authenticationService.getTenant(),
               messageEvent.data.data.session_id,
               dagUnverified
            ).subscribe(
               response => {
                  console.log(response)
                  messageEvent.ports[0].postMessage(
                     {
                        "type": "run_session",
                        "data": {
                           "result": "success",
                           "response": response
                        }
                     }
                  )
               },
               error => {
                  messageEvent.ports[0].postMessage(
                     {
                        "type": "run_session",
                        "data": {
                           "result": "failure",
                           "response": error.status
                        }
                     }
                  )
               }
            )
            break
         case 'verify_code':
            this.tasksService.applyTask(
               this.authenticationService.getTenant(),
               {
                  "type": "verify",
                  "dag": messageEvent.data.data.dag
               }
            ).subscribe(
               async response => {
                  const result = JSON.parse(await response.text());
                  messageEvent.ports[0].postMessage(
                     {
                        "type": "verify_code",
                        "data": {
                           "result": "success",
                           "response": result
                        }
                     }
                  )
               }
            )
            break
      }
   }
}
