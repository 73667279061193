<clr-signpost>
    <a class="nav-link nav-icon" aria-label="settings" clrSignpostTrigger style="width: 40px;">
        <cds-icon shape="info-circle" style="width: 24px; height: 24px;margin-left: -4px;" size="24"></cds-icon>
    </a>
    <clr-signpost-content #signpost class="signpost-about" clrPosition="right-top" *clrIfOpen>
        <h3 style="margin-top: 0">Graal Systems</h3>
        <p>
            Graal Platform SaaS version
            <br />
            <strong>Version</strong>: {{platformVersion}}<br />
            <button class="btn btn-sm btn-primary">More info</button>
        </p>
        <ul>
            <ul>
                <li>
                    <a target="_blank" href="{{documentationUrl}}">Documentation</a>
                </li>
                <li>
                    <a target="_blank" href="https://community.graal.systems/">Community</a>
                </li>
                <li>
                    <a target="_blank" href="https://api.graal.systems/">API Docs</a>
                </li>
                <li>
                    <a [routerLink]="['/support']">Support</a>
                </li>
                <li>
                    <a target="_blank" href="https://graal.systems/">Website</a>
                </li>
            </ul>
        </ul>
    </clr-signpost-content>
</clr-signpost>