import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Infrastructure, InfrastructureService, Workspace, WorkspaceService} from '../../../generated';
import {AuthenticationService} from '../../auth/authentication.service';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-workspace-list-page',
    templateUrl: './workspace-list.component.html'
})
export class WorkspaceListComponent implements OnInit {
    workspaces: Workspace[];
    selected: Workspace[];

    infrastructures: Map<string, Infrastructure>;

    openDeleteModal = false;
    creating = false;
    deleting = false;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private workspaceService: WorkspaceService,
        private infrastructureService: InfrastructureService
    ) {
        this.workspaces = [];
        this.selected = [];
    }

    ngOnInit(): void {
        this.workspaceService.findWorkspaces(this.authenticationService.getTenant())
            .subscribe(r => {
                this.workspaces = r.content;
            });
        this.infrastructureService.findInfrastructures(this.authenticationService.getTenant())
            .subscribe(r => {
                this.infrastructures = new Map(r.map(i => [i.id, i]));
            });
    }

    delete(): void {
        this.deleting = true;
        const subscriptions = this.selected.map(s =>
            this.workspaceService.deleteWorkspaceById(this.authenticationService.getTenant(), s.id));
        forkJoin(subscriptions)
            .subscribe(a => {
                this.openDeleteModal = false;
                this.deleting = false;
                this.refresh();
            });
    }

    onExportAll(): void {

    }

    onExportSelected(): void {

    }

    refresh(): void {
        this.ngOnInit();
    }
}
