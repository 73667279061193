import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-user-icon',
    templateUrl: './user-icon.component.html',
    styles: [`
        .rows {
            /*display: flex;*/
            align-items: center;
            display: inline;
            float: none;
            vertical-align: middle;
        }

        .circles {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background: #eee;
            border: 2px solid #fff;
            text-align: center;
            line-height: 40px;
            color: #2c2b2b;
            font-size: 16px;
            display: inline-block;
        }

        .rows .circles:not(:first-child) {
            margin-left: -16px;
        }

        .rows .circles:nth-child(n+5) {
            display: none;
        }
    `]
})
export class UserIconComponent implements OnInit {

    @Input()
    names: Array<string>;

    constructor() {
    }

    getInitials(nameString: string, i: number): string {
        const fullName = nameString.split(' ');
        const initials = fullName[0].charAt(0) + fullName[fullName.length - 1].charAt(0);
        return initials.toUpperCase();
    }

    ngOnInit(): void {
    }
}
