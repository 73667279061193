<!--                    <div class="alert alert-info" role="alert">-->
<!--                        <div class="alert-items">-->
<!--                            <div class="alert-item static">-->
<!--                                <div class="alert-icon-wrapper">-->
<!--                                    <cds-icon class="alert-icon" shape="info-circle"></cds-icon>-->
<!--                                </div>-->
<!--                                <span class="alert-text">-->
<!--                                    No permissions defined for this project-->
<!--                                  </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <button type="button" class="close" aria-label="Close">-->
<!--                            <cds-icon aria-hidden="true" shape="window-close"></cds-icon>-->
<!--                        </button>-->
<!--                    </div>-->
<table class="table table-noborder" style="margin-top: 0px;" *ngIf="mroles && mroles.length > 0">
    <thead>
        <tr>
            <th>Principal</th>
            <th>Role</th>
            <th>Scope</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let roles of mroles;let i=index">
            <tr *ngFor="let role of roles">
                <td>
                    <ng-container *ngIf="role.assignment.principal_type === 'identity'">
                        {{role.assignment.principal_type}}/{{$any(role.principal).name}}
                    </ng-container>
                    <ng-container *ngIf="role.assignment.principal_type === 'user'">
                        {{role.assignment.principal_type}}/{{$any(role.principal).firstname}}
                        {{$any(role.principal).lastname}}
                    </ng-container>
                    <ng-container *ngIf="role.assignment.principal_type === 'group'">
                        {{role.assignment.principal_type}}/{{$any(role.principal).name}}
                    </ng-container>
                </td>
                <td>
                    {{role.role.name}}
                    <clr-signpost style="position: static;margin-top: -9px;margin-bottom: -9px;"
                        (click)="fetchPermissions(role.role.id)">
                        <clr-signpost-content>
                            <h6 style="margin-top: 0px;">
                                {{role.role.name}}</h6>
                            <p style="margin-top: 0px;"><code class="clr-code">{{role.role.description}}</code>
                            </p>
                            <ul class="list-unstyled">
                                <li *ngFor="let permission of permissions$.get(role.role.id)  | async">{{permission}}
                                </li>
                            </ul>
                        </clr-signpost-content>
                    </clr-signpost>
                </td>
                <td>{{role.assignment.resource_type}}/{{role.assignment.resource_id}}</td>
                <td>
                    <ng-container *ngIf="role.assignment.resource_type === mtype">
                        <button class="btn btn-sm btn-link" (click)="unassign(role.assignment.id, i)"
                            style="margin-top: -4px;margin-bottom: 0px;border-top-width: 0px;border-bottom-width: 0px;">
                            <cds-icon shape="window-close"></cds-icon>
                        </button>
                    </ng-container>
                </td>
            </tr>
        </ng-container>
    </tbody>

</table>
