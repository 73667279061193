<ng-container *ngIf="type">
    <ng-container [ngSwitch]="type.toLowerCase()">
        <ng-container *ngSwitchCase="'pmml'"><span class="iconify-inline" data-icon="file-icons:test-generic"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'tensorflow'"><span class="iconify-inline" data-icon="cib:tensorflow"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'torchserve'"><span class="iconify-inline" data-icon="cib:pytorch"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'lightgbm'"><span class="iconify-inline" data-icon="file-icons:test-generic"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'pytorch'"><span class="iconify-inline" data-icon="cib:pytorch"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'mlflow'"><span class="iconify-inline" data-icon="simple-icons:mlflow"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'xgboost'"><span class="iconify-inline" data-icon="file-icons:test-generic"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchCase="'sklearn'"><span class="iconify-inline" data-icon="simple-icons:scikitlearn"
                style="font-size: {{size}}px;"></span> {{word}}</ng-container>
        <ng-container *ngSwitchDefault><cds-icon shape="data-cluster"></cds-icon> {{word}}</ng-container>
        <!-- TODO: missing size="{{size}}" -->
    </ng-container>

</ng-container>
<ng-container *ngIf="!type">
    <cds-icon shape="data-cluster"></cds-icon> {{word}} <!-- TODO: missing size="{{size}}" -->
</ng-container>