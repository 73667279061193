import { Patch, GraphService, JobService, WorkflowService } from "src/generated";
import { AuthenticationService } from "../auth/authentication.service";

export function updateGraph(graphService: GraphService, jobService: JobService, workflowService: WorkflowService, authenticationService: AuthenticationService, messageEvent: MessageEvent<any>): void {

    let patchArray: Array<Patch>

    if (messageEvent.data.data.draw_mode == "draft") {
        if (messageEvent.data.data.resource_type == "job") {
            patchArray = createPatchDraftJob(
                messageEvent.data.data.name,
                messageEvent.data.data.preview,
                messageEvent.data.data.flow,
                messageEvent.data.data.compatible_framework
            )
        }
        else if (messageEvent.data.data.resource_type == "workflow") {
            patchArray = createPatchDraftWorkflow(
                messageEvent.data.data.name,
                messageEvent.data.data.preview,
                messageEvent.data.data.flow
            )
        }
        else {
            messageEvent.ports[0].postMessage(
                {
                    "type": "update_graph",
                    "data": {
                        "result": "failure",
                        "message": "invalid ressource type"
                    }
                }
            );
            return
        }

        // update the graph
        graphService.updateGraph(
            authenticationService.getTenant(),
            messageEvent.data.data.id,
            patchArray,
            'response'
        )
            .subscribe(response => {
                let data = {}
                if (response.status === 200) {
                    data = {
                        "id": response.body.id,
                        "result": "success"
                    }
                } else {
                    data = {
                        "result": "failure"
                    }
                }
                messageEvent.ports[0].postMessage(
                    {
                        "type": "update_graph",
                        "data": data
                    }
                );
            });
        return
    }
    else if (messageEvent.data.data.draw_mode == "project") {
        if (messageEvent.data.data.resource_type == "job") {
            patchArray = createPatchProjectJob(
                messageEvent.data.data.flow,
                messageEvent.data.data.preview
            )

            jobService.updateJob(
                authenticationService.getTenant(),
                messageEvent.data.data.id,
                patchArray,
                'response'
            )
                .subscribe(response => {
                    let data = {}
                    if (response.status === 200) {
                        data = {
                            "id": response.body.id,
                            "result": "success"
                        }
                    } else {
                        data = {
                            "result": "failure"
                        }
                    }
                    messageEvent.ports[0].postMessage(
                        {
                            "type": "update_graph",
                            "data": data
                        }
                    );
                });
        }
        else if (messageEvent.data.data.resource_type == "workflow") {
            patchArray = createPatchProjectWorkflow(
                messageEvent.data.data.flow,
                messageEvent.data.data.preview
            )

            workflowService.updateWorkflow(
                authenticationService.getTenant(),
                messageEvent.data.data.id,
                patchArray,
                'response'
            ).subscribe(response => {
                let data = {}
                if (response.status === 200) {
                    data = {
                        "id": response.body.id,
                        "result": "success"
                    }
                } else {
                    data = {
                        "result": "failure"
                    }
                }
                messageEvent.ports[0].postMessage(
                    {
                        "type": "update_graph",
                        "data": data
                    }
                );
            });
        }
        else {
            messageEvent.ports[0].postMessage(
                {
                    "type": "update_graph",
                    "data": {
                        "result": "failure",
                        "message": "invalid ressource type"
                    }
                }
            );
            return
        }
    }
    else {
        messageEvent.ports[0].postMessage(
            {
                "type": "update_graph",
                "data": {
                    "result": "failure",
                    "message": "invalid draw mode"
                }
            }
        );
        return
    }
}

function createPatchDraftJob(name: any, preview: any, flow: any, compatibleFramework: {}): Array<Patch> {
    return [
        {
            op: Patch.OpEnum.Replace,
            path: "/name",
            value: name
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/preview",
            value: preview
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/flow",
            value: flow
        },
        {
            op: Patch.OpEnum.Add,
            path: "/metadata/compatible_framework",
            value: compatibleFramework
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/compatible_framework",
            value: compatibleFramework
        }
    ]
}

function createPatchProjectJob(flow: any, preview: any): Array<Patch> {
    return [
        {
            op: Patch.OpEnum.Replace,
            path: "/options/definition",
            value: flow.dag
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/graph/metadata/preview",
            value: preview
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/graph/metadata/flow",
            value: flow
        }
    ]
}

function createPatchDraftWorkflow(name: any, preview: any, flow: any): Array<Patch> {
    return [
        {
            op: Patch.OpEnum.Replace,
            path: "/name",
            value: name
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/preview",
            value: preview
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/flow",
            value: flow
        }
    ]
}

function createPatchProjectWorkflow(flow: any, preview: any): Array<Patch> {
    return [
        {
            op: Patch.OpEnum.Replace,
            path: "/tasks",
            value: flow.dag.tasks
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/graph/metadata/preview",
            value: preview
        },
        {
            op: Patch.OpEnum.Replace,
            path: "/metadata/graph/metadata/flow",
            value: flow
        }
    ]
}