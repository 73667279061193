import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisplayRolesComponent} from './roles/display-roles.component';
import {
    ClrComboboxModule,
    ClrCommonFormsModule,
    ClrDatagridModule,
    ClrIconModule,
    ClrInputModule,
    ClrModalModule,
    ClrRadioModule,
    ClrSignpostModule,
    ClrSpinnerModule,
    ClrStackViewModule,
    ClrTooltipModule
} from '@clr/angular';
import {GrantRolesComponent} from './grant/grant-roles.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RuntimeIconComponent} from './icon/runtime-icon.component';
import {UserIconComponent} from './user/user-icon.component';
import {LockComponent} from './lock/lock.component';
import {InfraIconComponent} from './infra/infra-icon.component';
import {TimelineActivityComponent} from './timeline/timeline-activity.component';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../pipes/pipes.module';
import {FollowComponent} from './follow/follow.component';
import {EmojiComponent} from './emoji/emoji.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {FollowersComponent} from './followers/followers.component';
import {FollowingComponent} from './following/following.component';
import {EndpointIconComponent} from './endpoint/endpoint-icon.component';
import {BucketIconComponent} from './bucket/bucket-icon.component';
import {SecretComponent} from './secret/secret.component';
import {TimelineCommentComponent} from './timeline/timeline-comment.component';
import {TimelineCalendarComponent} from './timeline/timeline-calendar.component';
import {UserFullnameComponent} from './user/user-fullname.component';
import {BudgetComponent} from './budget/budget.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {PaymentMethodIconComponent} from './payment-method/payment-method-icon.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        DisplayRolesComponent,
        EmojiComponent,
        FollowComponent,
        RuntimeIconComponent,
        BucketIconComponent,
        UserIconComponent,
        UserFullnameComponent,
        InfraIconComponent,
        LockComponent,
        SecretComponent,
        FollowersComponent,
        FollowingComponent,
        TimelineActivityComponent,
        TimelineCommentComponent,
        TimelineCalendarComponent,
        GrantRolesComponent,
        BudgetComponent,
        PaymentMethodIconComponent,
        EndpointIconComponent
    ],
    exports: [
        DisplayRolesComponent,
        EmojiComponent,
        FollowComponent,
        UserIconComponent,
        UserFullnameComponent,
        RuntimeIconComponent,
        BucketIconComponent,
        InfraIconComponent,
        LockComponent,
        SecretComponent,
        FollowersComponent,
        FollowingComponent,
        TimelineActivityComponent,
        TimelineCommentComponent,
        TimelineCalendarComponent,
        GrantRolesComponent,
        BudgetComponent,
        PaymentMethodIconComponent,
        EndpointIconComponent
    ],
    imports: [
        CommonModule,
        ClrSignpostModule,
        ClrComboboxModule,
        ReactiveFormsModule,
        ClrSpinnerModule,
        ClrIconModule,
        ClrModalModule,
        ClrStackViewModule,
        RouterModule,
        PipesModule,
        ClrTooltipModule,
        PickerModule,
        ReactiveFormsModule,
        ReactiveFormsModule,
        ClrCommonFormsModule,
        ClrInputModule,
        ReactiveFormsModule,
        ClrDatagridModule,
        ClrRadioModule,
        NgxEchartsModule
    ]
})
export class ComponentsModule {
}
