import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Style, StyleService} from '../../../generated';
import {AuthenticationService} from '../../auth/authentication.service';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class InternalStyleService {
    customStylelinkRef: HTMLLinkElement;
    internalStylelinkRef: HTMLLinkElement;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private styleService: StyleService,
        private authenticationService: AuthenticationService,
        @Inject(DOCUMENT) private document: Document) {

        this.internalStylelinkRef = this.document.createElement('link');
        this.internalStylelinkRef.rel = 'stylesheet';
        this.internalStylelinkRef.href = 'assets/styles/clr-ui-dark.css'; // Default to dark
        this.document.querySelector('head').appendChild(this.internalStylelinkRef);

        this.customStylelinkRef = this.document.createElement('link');
        this.customStylelinkRef.rel = 'stylesheet';
        this.customStylelinkRef.href = '';
        this.document.querySelector('head').appendChild(this.customStylelinkRef);

    }

    style: Style = {
        metadata: {}
    };

    public loadStyle(): void {
        this.styleService.findStyleByTenantId(this.authenticationService.getTenant()).subscribe(s => {
            this.applyStyle(s);

            // root.style.setProperty('--clr-alias-object-container-background', "--clr-global-color-construction-600");
            // root.style.setProperty('--clr-alias-object-overlay-background', '--clr-global-color-construction-600');
            // root.style.setProperty('--clr-global-space-1', 'calc(var(--clr-global-space-1) * 2');
        });
    }

    public switchToDarkTheme(): void {
        this.internalStylelinkRef.href = 'assets/styles/clr-ui-dark.css';
    }

    public switchToLightTheme(): void {
        this.internalStylelinkRef.href = '';
    }

    public applyStyle(s: Style): void {
        const root = document.documentElement;

        if (s.metadata) {
            if (s.metadata.header_background_color) {
                root.style.setProperty('--clr-header-7-bg-color', s.metadata.header_background_color as unknown as string);
            }
            if (s.metadata.google_font) {
                this.customStylelinkRef.href = 'https://fonts.googleapis.com/css2?family=' + s.metadata.google_font + ':wght@300;400;500;700&display=swap';
                root.style.setProperty('--clr-font', '\'' + s.metadata.google_font + '\', sans-serif');
            }
        }

        this.style = s;
    }
}
