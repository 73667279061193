<ng-container *ngIf="follow">
    <clr-tooltip>
        <button clrTooltipTrigger class="btn btn-sm btn-link" (click)="unfollowResource()" style="padding-right: 0px;padding-left: 0px;margin-right: -24px;margin-left: -11px;">
            <cds-icon shape="assign-user" size="md" role="img" style=" margin-top: -0.14rem;"></cds-icon>
        </button>
        <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
            <span>Unfollow this {{resourceType}}</span>
        </clr-tooltip-content>
    </clr-tooltip>
</ng-container>
<ng-container *ngIf="!follow">
    <clr-tooltip>
        <button clrTooltipTrigger class="btn btn-sm btn-link" (click)="followResource()" style="padding-right: 0px;padding-left: 0px;margin-right: -24px;margin-left: -11px;">
            <cds-icon shape="pin" size="md" role="img" style=" margin-top: -0.14rem;"></cds-icon>
        </button>
        <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
            <span>Follow this {{resourceType}}</span>
        </clr-tooltip-content>
    </clr-tooltip>
</ng-container>
