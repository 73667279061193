<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <h1 style="float:left">Issues and alarms</h1>
    </div>
</div>
<div class="clr-row">

    <div class="clr-col-12">
        <div class="card">
            <div class="card-block">
                <div class="card-media-block wrap">
                    <div class="card-media-description">
                        <div class="clr-row">
                            <div class="clr-col-1" style="margin-right: -20px;margin-left: 10px;">
                                <h3 class="title" style="margin-top: 0.6rem;">
                                    <span class="iconify-inline" data-icon="wpf:maintenance" style="font-size: 24px;"></span>
                                </h3>
                            </div>
                            <div class="clr-col-10">
                                <h4 class="title" style="margin-top: 0.2rem;">
                                    Upcoming maintenance on 21/06/2023
                                </h4>
                                <p style="margin-top: 10px;">
                                    Our services perform automated patching of the underlying hardware, OS, and compute engine.
                                    The patch includes new service features, security, and software updates.
                                    For our compute engine, minor version upgrades are automatic and included as part of the patching cycle.
                                    There is no user action or configuration settings required for patching.
                                    The patch is tested extensively and rolled out using safe deployment practices.
                                </p>

                                <clr-alert clrAlertType="info" [clrAlertAppLevel]="false">
                                    <clr-alert-item>
                                        <div class="alert-text">
                                            A planned maintenance is a maintenance window when these service updates are deployed to servers in a given Graal region.
                                            During planned maintenance, a notification event is created to inform customers when the service update is deployed in the region hosting their servers.
                                            Minimum duration between two planned maintenance is 30 days.
                                            You receive a notification of the next maintenance window 72 hours in advance.
                                        </div>
                                    </clr-alert-item>
                                </clr-alert>

                                <div cds-layout="horizontal gap:sm" style="margin-top: 16px;">
                                    <cds-tag readonly color="purple">OVH GRA-1</cds-tag>
                                    <cds-tag readonly color="blue">Hetzner DC3</cds-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
