import {Component, OnInit} from '@angular/core';
import {SidebarService} from '../../shared/services/sidebar.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from '@angular/router';

@Component({
    selector: 'app-navigation-sidenav',
    templateUrl: './sidenav.component.html'
})
export class SidenavComponent implements OnInit {

    path: string;
    data: any;

    constructor(
        private sidebarService: SidebarService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.router.events.subscribe((data) => {
            if (data instanceof RoutesRecognized) {
                this.path = data.state.root.firstChild.routeConfig.path;
            }
            if (data instanceof NavigationEnd) {
                this.data = this.activatedRoute.snapshot.firstChild.data;
            }
        });
    }
}
