<div class="clr-row">
    <div class="clr-col-12">
        <p><a [routerLink]="['/workspaces']">Workspaces</a> > Add a new workspace</p>
        <br/>
        <h1 style="display: inline">
            Add a new workspace
        </h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-12">
        <form clrStepper [formGroup]="form" (ngSubmit)="submit()">
            <clr-stepper-panel formGroupName="basic">
                <clr-step-title>Basic informations</clr-step-title>
                <clr-step-content *clrIfExpanded>
                    <clr-input-container>
                        <label>Name</label>
                        <input clrInput formControlName="name"/>
                        <clr-control-error *clrIfError="'required'">Name Required</clr-control-error>
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description</label>
                        <input clrInput formControlName="description"/>
                    </clr-input-container>

                    <clr-radio-container>
                        <label style="padding-bottom: 8px;">Type</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="vscode" formControlName="type"/>
                            <label><span class="iconify-inline" data-icon="vscode-icons:file-type-vscode" style="font-size: 20px;"></span> Visual Studio Code</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="jupyter" formControlName="type"/>
                            <label><span class="iconify-inline" data-icon="cib:jupyter" style="font-size: 20px;"></span> Jupyter</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper class="clr-form-control-disabled">
                            <input type="radio" clrRadio required value="superset" formControlName="type"/>
                            <label><span class="iconify-inline" data-icon="cib:apache" style="font-size: 20px;"></span> Apache Superset</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="metabase" formControlName="type"/>
                            <label><span class="iconify-inline" data-icon="simple-icons:metabase" style="font-size: 20px;"></span> Metabase</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="zeppelin" formControlName="type"/>
                            <label><span class="iconify-inline" data-icon="cib:apache" style="font-size: 20px;"></span> Apache Zeppelin</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="options">
                <clr-step-title>Options</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <clr-combobox-container *ngIf="infrastructures" style="margin-bottom: 22px;">
                        <label>Infrastructure</label>
                        <clr-combobox formControlName="infrastructure_id" name="infrastructure">
                            <clr-options>
                                <clr-option *clrOptionItems="let infrastructure of infrastructures" [clrValue]="infrastructure.id">
                                    <span class="flag-icon flag-icon-{{infrastructure.country}} flag-icon-squared"></span>
                                    <app-infra-icon style="margin-left: 4px;" [type]="infrastructure.type" [displayTitle]="false" [size]="12"></app-infra-icon>
                                    {{infrastructure.name}}
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                    </clr-combobox-container>

                    <clr-combobox-container *ngIf="instanceTypes">
                        <label>Instance type</label>
                        <clr-combobox formControlName="instance_type">
                            <clr-options>
                                <clr-option *clrOptionItems="let instanceType of instanceTypes" [clrValue]="instanceType.id">
                                    {{instanceType.id}} ({{instanceType.cpus}} vcores, {{instanceType.memory | byte_format}}, {{instanceType.storage | byte_format}} SSD, {{instanceType.gpus}} GPUs)
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <clr-control-error *clrIfError="'required'">Required</clr-control-error>
                    </clr-combobox-container>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="valid">
                <clr-step-title>Summary</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <p>Name: {{form.get('basic').get('name').value}}</p>
                    <p>Description: {{form.get('basic').get('description').value}}</p>

                    <button clrStepButton="submit" [disabled]="creating">
                        <clr-spinner [clrSmall]="true" *ngIf="creating" style="margin-top: 7px;"></clr-spinner>
                        submit
                    </button>
                </clr-step-content>
            </clr-stepper-panel>
        </form>

    </div>
</div>
