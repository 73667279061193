import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Bucket, BucketService, DataService} from '../../../generated';
import {ClrSelectedState} from '@clr/angular';
import {FileOrDirectoryWithStatus} from './file-or-directory-with-status';
import {finalize, map} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {HttpEventType} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {AuthenticationService} from '../../auth/authentication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import '@cds/core/file/register.js';

@Component({
    selector: 'app-data-explorer-page',
    templateUrl: './data-explorer.component.html'
})
export class DataExplorerComponent implements OnInit {
    //
    // root$: Map<string, FileOrDirectoryWithStatus[]>;
    //
    // buckets: Bucket[];
    //
    // openDeleteModal = false;
    // deleting = false;
    //
    // openCreateDirectoryModal: boolean;
    // formCreate: FormGroup;
    // creating = false;
    //
    // openUploadModal: boolean;
    // formUpload: FormGroup;
    // uploading = false;
    //
    // uploadProgress: number;
    //
    // uploadSub: Subscription;
    // selected: Map<string, Map<string, string>>;
    //
    // constructor(
    //     private authenticationService: AuthenticationService,
    //     private route: ActivatedRoute,
    //     private dataService: DataService,
    //     private bucketService: BucketService,
    //     private formBuilder: FormBuilder
    // ) {
    //     this.selected = new Map<string, Map<string, string>>();
    //     this.initCreateForm();
    //     this.initUploadForm();
    // }
    //
    // private initCreateForm(): void {
    //     this.formCreate = this.formBuilder.group({
    //         path: ['/', Validators.required]
    //     });
    // }
    //
    // private initUploadForm(): void {
    //     this.formUpload = this.formBuilder.group({
    //         path: ['/', Validators.required],
    //         files: ['', Validators.required]
    //     });
    // }
    //
    // isSelected(bucketId: string, file: FileOrDirectoryWithStatus): boolean {
    //     const key = file.fileOrDirectory.path || '' + file.fileOrDirectory.name;
    //     return this.selected.has(bucketId) && this.selected.get(bucketId).has(key);
    // }
    //
    // select(bucketId: string, file: FileOrDirectoryWithStatus, $event: ClrSelectedState): void {
    //     const key = file.fileOrDirectory.path || '' + file.fileOrDirectory.name;
    //     if ($event === ClrSelectedState.SELECTED) {
    //         if (!this.selected.get(bucketId)) {
    //             this.selected.set(bucketId, new Map<string, string>());
    //         }
    //         this.selected.get(bucketId).set(key, file.fileOrDirectory.name);
    //     } else if ($event === ClrSelectedState.UNSELECTED) {
    //         if (this.selected.has(bucketId) && this.selected.get(bucketId).has(key)) {
    //             this.selected.get(bucketId).delete(key);
    //         }
    //     }
    // }
    //
    // getChildren = (bucketId: string, folder: FileOrDirectoryWithStatus): Observable<FileOrDirectoryWithStatus[]> => {
    //     if (folder.fileOrDirectory.directory) {
    //         return this.dataService.getDataFiles(this.authenticationService.getTenant(), bucketId, folder.fileOrDirectory.path).pipe(map(i => i.map(e => {
    //             return {fileOrDirectory: e, status: ClrSelectedState.UNSELECTED};
    //         })));
    //     } else {
    //         return null;
    //     }
    // }
    //
    ngOnInit(): void {
    //     this.bucketService.findBuckets(this.authenticationService.getTenant())
    //         .subscribe(r => {
    //             this.buckets = r;
    //         });
    //
    //     // this.dataService.getDataFiles(this.authenticationService.getTenant(), '').subscribe(data => this.root$ = data.map(i => {
    //     //     return {fileOrDirectory: i, status: ClrSelectedState.UNSELECTED};
    //     // }));
    // }
    //
    // onFileSelected(event: any): void {
    //     if (event.target.files && event.target.files.length > 0) {
    //         this.formUpload.get('files').setValue(event.target.files);
    //     }
    }
    //
    // upload(bucketId: string): void {
    //     this.uploadSub = this.dataService.uploadFile(this.authenticationService.getTenant(), bucketId, this.formUpload.get('path').value, Array.from(this.formUpload.get('files').value), 'events', true)
    //         .pipe(
    //             finalize(() => {
    //                 this.reset();
    //                 this.refresh();
    //                 this.uploading = false;
    //                 this.openUploadModal = false;
    //                 this.initUploadForm();
    //             })
    //         ).subscribe(event => {
    //             // tslint:disable-next-line:triple-equals
    //             if (event.type == HttpEventType.UploadProgress) {
    //                 this.uploadProgress = Math.round(100 * (event.loaded / event.total));
    //             }
    //         });
    // }
    //
    // createDirectory(): void {
    //     const path: string = this.formCreate.get('path').value;
    //     const blob: Blob = new Blob([], {type: 'application/octet-binary'});
    //     const file = new File([blob], 'keep');
    //     this.uploadSub = this.dataService.uploadFile(this.authenticationService.getTenant(), path, [file], 'events', true)
    //         .pipe(
    //             finalize(() => {
    //                 this.reset();
    //                 this.refresh();
    //                 this.creating = false;
    //                 this.openCreateDirectoryModal = false;
    //                 this.initCreateForm();
    //             })
    //         ).subscribe(event => {
    //             // tslint:disable-next-line:triple-equals
    //             if (event.type == HttpEventType.UploadProgress) {
    //                 this.uploadProgress = Math.round(100 * (event.loaded / event.total));
    //             }
    //         });
    //
    // }
    //
    // onFileDownload(bucketId: string): void {
    //     const key = this.selected.keys().next().value;
    //     if (key) {
    //         this.dataService.downloadFile(this.authenticationService.getTenant(), bucketId, key).subscribe(blob => {
    //             saveAs(blob, this.selected.get(bucketId).get(key));
    //             this.selected.get(bucketId) = new Map<string, string>();
    //         });
    //     }
    // }
    //
    // delete(): void {
    //     this.deleting = true;
    //     this.dataService.deleteFiles(this.authenticationService.getTenant(), bucketId, Array.from(this.selected.keys()))
    //         .subscribe(a => {
    //             this.selected = new Map<string, string>();
    //             this.openDeleteModal = false;
    //             this.deleting = false;
    //             this.refresh();
    //         });
    // }
    //
    // cancelUpload(): void {
    //     this.uploadSub.unsubscribe();
    //     this.reset();
    // }
    //
    // reset(): void {
    //     this.uploadProgress = null;
    //     this.uploadSub = null;
    // }

    onExportAll(): void {

    }

    onExportSelected(): void {

    }

    refresh(): void {
        this.ngOnInit();
    }
}
