import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {PreferencesComponent} from './preferences.component';
import {PreferencesRoutingModule} from './preferences-routing.module';
import {CommonModule} from '@angular/common';
import {ClrCheckboxModule, ClrIconModule, ClrInputModule, ClrTreeViewModule} from '@clr/angular';
import {FormsModule} from '@angular/forms';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        PreferencesRoutingModule,
        CommonModule,
        ClrIconModule,
        ClrCheckboxModule,
        ClrTreeViewModule,
        ClrInputModule,
        FormsModule
    ],
    declarations: [
        PreferencesComponent
    ]
})
export class PreferencesModule {
}
