<clr-modal [(clrModalOpen)]="openGrantModal">
    <h3 class="modal-title">Grant a user</h3>
    <div class="modal-body">
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        {{errorMessage}}
                    </span>
                </div>
            </div>
            <button type="button" class="close" aria-label="Close">
                <cds-icon aria-hidden="true" shape="window-close"></cds-icon>
            </button>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="resourceType == 'tenant'">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        You are granting a user at the tenant level.
                        If you want to grant the user on a specific resource, operate on the resource.
                    </span>
                </div>
            </div>
            <button type="button" class="close" aria-label="Close">
                <cds-icon aria-hidden="true" shape="window-close"></cds-icon>
            </button>
        </div>
        <form clrForm [formGroup]="form" (ngSubmit)="grant()">
            <clr-combobox-container *ngIf="roles" style="margin-bottom: 22px;">
                <label>Role</label>
                <clr-combobox formControlName="role_id" name="role">
                    <clr-options>
                        <clr-option *clrOptionItems="let role of roles" [clrValue]="role.id">
                            {{role.name}}
                        </clr-option>
                    </clr-options>
                </clr-combobox>
                <clr-control-error *clrIfError="'required'">Required</clr-control-error>
            </clr-combobox-container>
            <clr-combobox-container *ngIf="users" style="margin-bottom: 22px;">
                <label>User</label>
                <clr-combobox formControlName="principal_id" name="role">
                    <clr-options>
                        <clr-option *clrOptionItems="let user of users" [clrValue]="user.id">
                            {{user.firstname}} {{user.lastname}}
                        </clr-option>
                    </clr-options>
                </clr-combobox>
                <clr-control-error *clrIfError="'required'">Required</clr-control-error>
            </clr-combobox-container>
            <button type="button" class="btn btn-outline" (click)="openGrantModal = false">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="granting || form.invalid">
                <clr-spinner [clrSmall]="true" *ngIf="granting" style="margin-top: 7px;"></clr-spinner>
                <ng-template [ngIf]="!granting">Ok</ng-template>
            </button>
        </form>
    </div>
</clr-modal>
<div class="btn-group">
    <button type="button" class="btn btn-sm btn-secondary" (click)="openGrantModal = true">
        <cds-icon shape="plus"></cds-icon>
        Grant
    </button>
</div>