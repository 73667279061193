<clr-modal [(clrModalOpen)]="openErrorModal">
    <h3 class="modal-title">Error</h3>
    <div class="modal-body">
        <p>The workflow {{form.get('basic').get('name').value}} can not be created.</p>
        <div class="alert alert-danger">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <ng-container *ngIf="internalError">
                        <span class="alert-text">{{internalError.message}}</span>
                    </ng-container>
                    <ng-container *ngIf="!internalError">
                        <span class="alert-text">
                            Internal error, please contact the support
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="openErrorModal = false">Ok</button>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-12">
        <p>Workflows > Add a new workflow</p>
        <br />
        <h1 style="display: inline">
            Add a new workflow
        </h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-12">
        <br />
        <form clrStepper [formGroup]="form" (ngSubmit)="submit()">

            <clr-stepper-panel formGroupName="basic">
                <clr-step-title>Basic informations</clr-step-title>
                <clr-step-content *clrIfExpanded>
                    <clr-combobox-container *ngIf="!project && projects">
                        <label>Project</label>
                        <clr-combobox formControlName="project_id" name="project">
                            <clr-options>
                                <clr-option *clrOptionItems="let project of projects" [clrValue]="project.id">
                                    {{project.name}} : {{project.id}}
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <clr-control-helper>The workflow will be added under this project</clr-control-helper>
                        <clr-control-error *clrIfError="'required'">Project Required</clr-control-error>
                    </clr-combobox-container>

                    <clr-combobox-container>
                        <label>Graph</label>
                        <clr-combobox formControlName="definition" name="definition" required>
                            <clr-options>
                                <clr-option *clrOptionItems="let graph of graph_list; field:'name'" [clrValue]="graph">
                                    {{ graph.name }} <span style="color: steelblue;">({{ graph.id }})</span>
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <clr-control-helper>The draft graph to use</clr-control-helper>
                        <clr-control-error *clrIfError="'required'">This field cannot be empty!</clr-control-error>
                    </clr-combobox-container>

                    <clr-input-container>
                        <label>Name</label>
                        <input clrInput formControlName="name" />
                        <clr-control-error *clrIfError="'required'">Name Required</clr-control-error>
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description</label>
                        <input clrInput formControlName="description" />
                    </clr-input-container>

                    <!--                    <clr-combobox-container>-->
                    <!--                        <label>Labels</label>-->
                    <!--                        <clr-combobox formControlName="labels" name="multiSelect" clrMulti="true">-->
                    <!--                            <ng-container *clrOptionSelected="let selected">-->
                    <!--                                {{selected?.key}} : {{selected?.value}}-->
                    <!--                            </ng-container>-->
                    <!--                            <clr-options>-->
                    <!--                                <clr-option *clrOptionItems="let label of labels" [clrValue]="label">-->
                    <!--                                    {{label.key}} : {{label.value}}-->
                    <!--                                </clr-option>-->
                    <!--                            </clr-options>-->
                    <!--                        </clr-combobox>-->
                    <!--                        <clr-control-helper>The new workflow will have the following labels</clr-control-helper>-->
                    <!--                        <clr-control-error>There was an error</clr-control-error>-->
                    <!--                    </clr-combobox-container>-->

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="advanced">
                <clr-step-title>Advanced</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <clr-stack-view style="margin-top: 24px;">
                        <clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                            <clr-stack-label>Advanced</clr-stack-label>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3"
                                [clrStackViewPosinset]="1" [class]="'in-clr-stack-block'">
                                <clr-stack-label></clr-stack-label>
                                <clr-stack-content>
                                    <clr-toggle-container>
                                        <label>Create a new identity</label>
                                        <clr-toggle-wrapper>
                                            <input type="checkbox" clrToggle value="true"
                                                formControlName="auto_create_identity" />
                                        </clr-toggle-wrapper>
                                    </clr-toggle-container>

                                    <ng-container *ngIf="!form.get('advanced').get('auto_create_identity').value">
                                        <clr-combobox-container>
                                            <label>Identity</label>
                                            <clr-combobox formControlName="identity_id" name="Identity">
                                                <clr-options>
                                                    <clr-option *clrOptionItems="let identity of identities"
                                                        [clrValue]="identity.id">
                                                        {{identity.name}} : {{identity.id}}
                                                    </clr-option>
                                                </clr-options>
                                            </clr-combobox>
                                            <clr-control-helper>The new workflow will have the following
                                                identity</clr-control-helper>
                                            <clr-control-error>There was an error</clr-control-error>
                                        </clr-combobox-container>
                                    </ng-container>

                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </clr-stack-view>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="schedule">
                <clr-step-title>Scheduling</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <clr-radio-container>
                        <!--<label>Scheduling</label>-->
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="once" formControlName="type" />
                            <label>Manual execution</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio required value="cron" formControlName="type" />
                            <label>Cron</label>
                        </clr-radio-wrapper>
                        <clr-control-helper>Choose the type</clr-control-helper>
                        <clr-control-error>Please select a scheduling</clr-control-error>
                    </clr-radio-container>

                    <!--<cron-workflows name="blob" *ngIf="this.form.get('schedule').get('type').value=='cron'" [(ngModel)]="frequency"></cron-workflows>-->
                    <cron-workflows *ngIf="this.form.get('schedule').get('type').value=='cron'"
                        formControlName="cron_expression"></cron-workflows>
                    <!--<p>ngModel = {{this.form.get('schedule').get('blob').value}}</p>-->
                    <!--<label>freqControl = {{this.form.get('freqControl').value}}</label>
                    <p>freqControl = {{this.form.get('schedule').get('type').value}}</p>
                    <p>freqControl = {{this.form.get('freqControl').value}}</p>-->
                    <!--input *ngIf="this.form.get('schedule').get('type').value=='cron'" clrInput formControlName="frequency" required/-->


                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="notifications">
                <clr-step-title>Notifications</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <div class="clr-row" formArrayName="notifications"
                        *ngFor="let field of getNotification(); let ind = index;">
                        <ng-container [formGroupName]="ind">
                            <div class="clr-col-3">
                                <clr-radio-container>
                                    <label>Type</label>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio value="mail" formControlName="type" />
                                        <label> Mail</label><br />
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio value="webhook" formControlName="type" />
                                        <label> Webhook</label><br />
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio value="eventhub" formControlName="type" />
                                        <label> Azure EventHub</label><br />
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio value="pubsub" formControlName="type" />
                                        <label> Google PubSub</label><br />
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio value="sqs" formControlName="type" />
                                        <label> AWS SQS</label><br />
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio value="kafka" formControlName="type" />
                                        <label> Apache Kafka</label><br />
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio value="teams" formControlName="type" />
                                        <label> Teams</label><br />
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio value="slack" formControlName="type" />
                                        <label> Slack</label><br />
                                    </clr-radio-wrapper>
                                </clr-radio-container>
                            </div>
                            <div class="clr-col-3">
                                <clr-checkbox-container clrInline="vertical">
                                    <label>On</label>
                                    <clr-checkbox-wrapper>
                                        <input type="checkbox" clrCheckbox value="start" formControlName="on_start" />
                                        <label> Start</label>
                                    </clr-checkbox-wrapper>
                                    <clr-checkbox-wrapper>
                                        <input type="checkbox" clrCheckbox value="success"
                                            formControlName="on_success" />
                                        <label> Success</label>
                                    </clr-checkbox-wrapper>
                                    <clr-checkbox-wrapper>
                                        <input type="checkbox" clrCheckbox value="failure"
                                            formControlName="on_failure" />
                                        <label> Failure</label>
                                    </clr-checkbox-wrapper>
                                </clr-checkbox-container>
                            </div>
                            <div class="clr-col-6">

                                <ng-container *ngIf="getCurrentNotification(ind).get('type').value === 'mail'">
                                    <clr-input-container>
                                        <label>Email</label>
                                        <input clrInput formControlName="emailTo" />
                                        <clr-control-error *clrIfError="'required'">Email required</clr-control-error>
                                    </clr-input-container>

                                    <clr-input-container>
                                        <label>Subject</label>
                                        <input clrInput formControlName="emailSubject" />
                                        <clr-control-error *clrIfError="'required'">Subject required</clr-control-error>
                                    </clr-input-container>
                                </ng-container>

                                <ng-container *ngIf="getCurrentNotification(ind).get('type').value === 'webhook'">
                                    <clr-input-container>
                                        <label>Url</label>
                                        <input clrInput formControlName="webhookUrl" />
                                    </clr-input-container>

                                    <clr-combobox-container>
                                        <label>Method</label>
                                        <clr-combobox formControlName="webhookMethod" name="Method">
                                            <clr-options>
                                                <clr-option *clrOptionItems="let method of methods"
                                                    [clrValue]="method.name">
                                                    {{method.name}}
                                                </clr-option>
                                            </clr-options>
                                        </clr-combobox>
                                        <clr-control-helper>HTTP methods</clr-control-helper>
                                    </clr-combobox-container>

                                    <clr-input-container>
                                        <label>Headers</label>
                                    </clr-input-container>
                                    <div class="clr-row" formArrayName="webhookHeaders"
                                        *ngFor="let field of getHeader(ind); let indHeader = index;">
                                        <ng-container [formGroupName]="indHeader">
                                            <div class="clr-col-5">
                                                <clr-input-container>
                                                    <label>Key</label>
                                                    <input clrInput formControlName="key" />
                                                </clr-input-container>
                                            </div>
                                            <div class="clr-col-5">
                                                <clr-input-container>
                                                    <label>Value</label>
                                                    <input clrInput formControlName="value" />
                                                </clr-input-container>
                                            </div>
                                            <div class="clr-col-2" *ngIf="indHeader > 0">
                                                <button class="btn btn-danger-outline" type="button"
                                                    (click)="removeHeader(ind, indHeader)" style="margin-top:30px;">
                                                    Remove
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <button class="btn btn-outline" type="button" (click)="addHeader(ind)">Add Header
                                    </button>
                                </ng-container>
                            </div>
                            <div class="clr-col-2">
                                <button class="btn btn-danger-outline" type="button" (click)="removeNotification(ind)"
                                    style="margin-top:30px;">
                                    Remove
                                </button>
                            </div>
                            <cds-divider orientation="horizontal" style="margin-top: 10px"
                                *ngIf="getNotificationCount() > 1"></cds-divider>
                        </ng-container>
                    </div>
                    <button class="btn btn-outline" type="button" (click)="addNotification()"
                        style="margin-top: 10px">Add new notification</button>

                    <button clrStepButton="next">next</button>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="valid">
                <clr-step-title>Summary</clr-step-title>
                <clr-step-content *clrIfExpanded>

                    <p>Name: {{form.get('basic').get('name').value}}</p>
                    <p>Description: {{form.get('basic').get('description').value}}</p>

                    <button clrStepButton="submit" [disabled]="creating">
                        <clr-spinner [clrSmall]="true" *ngIf="creating" style="margin-top: 7px;"></clr-spinner>
                        submit
                    </button>
                </clr-step-content>
            </clr-stepper-panel>
        </form>

    </div>
</div>