import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AlertHomeComponent} from './home/alert-home.component';

const routes: Routes = [
    {
        path: 'alerts',
        component: AlertHomeComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlertRoutingModule {
}
