import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Project} from '../../../generated';

@Component({
    selector: 'app-cost-budget-page',
    templateUrl: './project-costs.component.html'
})
export class ProjectCostsComponent implements OnInit {
    project: Project;

    constructor(
        private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        // Retrieve the prefetched project
        this.route.data.subscribe(
            (data) => {
                this.project = data.project;
            });
    }
}
