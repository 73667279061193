import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PreferencesComponent} from './preferences.component';

const routes: Routes = [
    {
        path: 'preferences',
        component: PreferencesComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PreferencesRoutingModule {
}
