import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

import '@cds/core/divider/register.js';
import {JobService, WorkflowService} from '../../../generated';
import {AuthenticationService} from '../../auth/authentication.service';

@Component({
    template: `

    `,
    selector: 'redirect-workflow'
})
export class RedirectWorkflowComponent implements OnInit {

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private workflowService: WorkflowService
    ) {

    }

    ngOnInit(): void {
        this.route.paramMap
            .subscribe((params: Params) => {
                this.workflowService.findWorkflowById(this.authenticationService.getTenant(), params.get('workflowId'))
                    .subscribe(
                        workflow => {
                            this.router.navigate(['projects', workflow.project_id, 'workflows', workflow.id]);
                        },
                        e => {
                            this.router.navigate(['not-found.component.html']);
                        });
            });
    }
}
