import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AlertHomeComponent} from "./home/alert-home.component";
import {AlertRoutingModule} from './alert-routing.module';
import {
    ClrAlertModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrModalModule,
    ClrRadioModule,
    ClrSelectModule,
    ClrSpinnerModule,
    ClrStepperModule,
    ClrTextareaModule
} from "@clr/angular";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "../shared/directives/directives.module";

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        AlertRoutingModule,
        ClrDatagridModule,
        CommonModule,
        ClrIconModule,
        ClrDropdownModule,
        ClrModalModule,
        ClrSpinnerModule,
        ClrStepperModule,
        ClrInputModule,
        ReactiveFormsModule,
        ClrRadioModule,
        FormsModule,
        ClrTextareaModule,
        ClrSelectModule,
        DirectivesModule,
        ClrAlertModule
    ],
    declarations: [
        AlertHomeComponent
    ]
})
export class AlertModule {
}
