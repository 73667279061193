import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SwitchPipe} from './map/switch.pipe';
import {ByteFormatPipe} from './map/byte_format.pipe';
import {ToBytePipe} from './map/to_byte.pipe';
import {CallbackPipe} from './map/callback.pipe';
import {ProjectBannerPipe} from './map/project_banner.pipe';
import {JsonPrettyPipe} from './map/jsonpretty.pipe';
import {DateAgoPipe} from './map/date-ago.pipe';

@NgModule({
    declarations: [
        SwitchPipe,
        ByteFormatPipe,
        ToBytePipe,
        DateAgoPipe,
        JsonPrettyPipe,
        CallbackPipe,
        ProjectBannerPipe
    ],
    exports: [
        SwitchPipe,
        ByteFormatPipe,
        ToBytePipe,
        DateAgoPipe,
        JsonPrettyPipe,
        CallbackPipe,
        ProjectBannerPipe
    ],
    imports: [
        CommonModule
    ],
    providers: [
        ByteFormatPipe
    ]

})
export class PipesModule {
}
