import {Component, OnInit} from '@angular/core';

import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import '@cds/core/icon/register.js';
import {bellIcon, ClarityIcons, cogIcon, plusIcon, sunIcon} from '@cds/core/icon';
import {
    Infrastructure,
    InfrastructureService,
    InstanceType,
    InstanceTypeService,
    User,
    UserService,
    Workspace,
    WorkspaceService
} from '../../../generated';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../auth/authentication.service';

ClarityIcons.addIcons(sunIcon, plusIcon, bellIcon, cogIcon);

@Component({
    selector: 'app-workspace-new-page',
    templateUrl: './workspace-new.component.html'
})
export class WorkspaceNewComponent implements OnInit {
    form: UntypedFormGroup;
    creating = false;
    instanceTypes: Array<InstanceType>;
    infrastructures: Array<Infrastructure>;
    userDetails: User;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private workspaceService: WorkspaceService,
        private userService: UserService,
        private infrastructureService: InfrastructureService,
        private instanceTypeService: InstanceTypeService
    ) {
        this.form = this.formBuilder.group({
            basic: this.formBuilder.group({
                name: ['', Validators.required],
                type: ['', Validators.required],
                description: []
            }),
            options: this.formBuilder.group({
                instance_type: ['Standard_Development_D0_v1', Validators.required],
                infrastructure_id: ['', Validators.required]
            }),
            valid: this.formBuilder.group({}),
        });
    }

    ngOnInit(): void {

        this.instanceTypeService.findInstanceTypes(this.authenticationService.getTenant())
            .subscribe(r => {
                this.instanceTypes = r;
            });
        this.infrastructureService.findInfrastructures(this.authenticationService.getTenant())
            .subscribe(r => {
                this.infrastructures = r;
            });
        this.userService.findCurrentUser(this.authenticationService.getTenant()).subscribe(e => {
            this.userDetails = e;
        });
    }

    submit(): void {
        this.creating = true;
        const workspace: Workspace = {
            name: this.form.get('basic').get('name').value,
            description: this.form.get('basic').get('description').value,
            type: this.form.get('basic').get('type').value,
            version: 'ignored',
            owner: this.userDetails.id,
            instance_type: this.form.get('options').get('instance_type').value,
            infrastructure_id: this.form.get('options').get('infrastructure_id').value
        };
        this.workspaceService.createWorkspace(this.authenticationService.getTenant(), workspace)
            .subscribe(
                data => {
                    this.creating = false;
                    this.router.navigate(['workspaces', data.id]);
                },
                error => {
                    this.creating = false;
                });
    }
}
