import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SupportHomeComponent} from './home/support-home.component';
import {TicketNewComponent} from './new/ticket-new.component';
import {TicketDetailsComponent} from './details/ticket-details.component';
import {TicketResolver} from './ticket-resolver.service';

const routes: Routes = [
    {
        path: 'support',
        component: SupportHomeComponent
    },
    {
        path: 'support/tickets/new',
        component: TicketNewComponent
    },
    {
        path: 'support/tickets/:ticketId',
        component: TicketDetailsComponent,
        resolve: {
            ticket: TicketResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SupportRoutingModule {
}
