import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import '@cds/core/divider/register.js';
import {AuthenticationService} from '../../auth/authentication.service';
import {Ticket, TicketService} from '../../../generated';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-support-home-page',
    templateUrl: './support-home.component.html'
})
export class SupportHomeComponent implements OnInit {
    tickets: Array<Ticket>;
    selected: Ticket[];

    openDeleteModal = false;
    deleting = false;

    constructor(
        private authenticationService: AuthenticationService,
        private ticketService: TicketService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.tickets = [];
        this.selected = [];
    }

    delete(): void {
        this.deleting = true;
        const subscriptions = this.selected.map(s =>
            this.ticketService.deleteTicketById(this.authenticationService.getTenant(), s.id));
        forkJoin(subscriptions)
            .subscribe(a => {
                    this.openDeleteModal = false;
                    this.deleting = false;
                    this.refresh();
                },
                e => {
                    this.deleting = false;
                });
    }

    ngOnInit(): void {
        this.ticketService.findTickets(this.authenticationService.getTenant()).subscribe(e => {
            this.tickets = e;
        });
    }

    refresh(): void {
        this.ngOnInit();
    }


    onExportAll(): void {

    }

    onExportSelected(): void {

    }
}
