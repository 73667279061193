<div class="clr-row">
    <div class="clr-col-12 clr-col-sm-12">
        <p>Data > Hub</p>
        <h1 style="display: inline">Hub</h1>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-6 clr-offset-sm-3" cds-layout="vertical gap:lg" style="margin-top: 12px;margin-bottom: 37px;">
        <cds-form-group layout="vertical">
            <cds-input>
                <label></label>
                <input placeholder="covid, france..." style="font-size: 18px;" />
                <cds-control-action action="prefix" aria-label="select user">
                    <cds-icon size="24" shape="search"></cds-icon>
                </cds-control-action>
            </cds-input>
        </cds-form-group>
    </div>
</div>
<div class="card-columns">
    <div class="card">
        <div class="card-block">
            <div class="card-media-block">
                <img src="https://static.data.gouv.fr/avatars/79/7e94cd7a8d43d39544d4018666e646-100.png"
                    class="card-media-image" />
                <div class="card-media-description">
                    <span class="card-media-title">
                        <h3 style="margin-top: 0px;margin-bottom: 0px;">Données relatives aux personnes vaccinées contre
                            la Covid-19</h3>
                    </span>
                    <span class="card-media-text">
                        MAJ 01/07/2021 Ces ressources ne seront plus actualisées le week-end à compter du 3 juillet
                        Vaccination contre la COVID-19
                    </span>
                </div>
            </div>
            <div class="card-text">
                <cds-icon shape="file-group"></cds-icon>
                5 file(s)
                <cds-icon shape="star"></cds-icon>
                0 star(s)
                <cds-icon shape="map-marker"></cds-icon>
                data.gouv.fr
                <cds-icon shape="check"></cds-icon>
                Source confirmed
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-block">
            <div class="card-media-block">
                <img src="https://static.data.gouv.fr/avatars/79/7e94cd7a8d43d39544d4018666e646-100.png"
                    class="card-media-image" />
                <div class="card-media-description">
                    <span class="card-media-title">
                        <h3 style="margin-top: 0px;margin-bottom: 0px;">Données de laboratoires pour le dépistage :
                            Indicateurs sur les mutations</h3>
                    </span>
                    <span class="card-media-text">
                        EVOLUTION DE LA STRATEGIE DE CRIBLAGE Les tests RT-PCR de criblage utilisés et dont les
                        résultats sont remontés
                    </span>
                </div>
            </div>
            <div class="card-text">
                <cds-icon shape="file-group"></cds-icon>
                5 file(s)
                <cds-icon shape="star"></cds-icon>
                0 star(s)
                <cds-icon shape="map-marker"></cds-icon>
                data.gouv.fr
                <cds-icon shape="check"></cds-icon>
                Source confirmed
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-block">
            <div class="card-media-block">
                <img src="https://static.data.gouv.fr/avatars/79/7e94cd7a8d43d39544d4018666e646-100.png"
                    class="card-media-image" />
                <div class="card-media-description">
                    <span class="card-media-title">
                        <h3 style="margin-top: 0px;margin-bottom: 0px;">EVOLUTION DE LA STRATEGIE DE CRIBLAGE Les tests
                            RT-PCR de criblage utilisés et dont les résultats sont remontés </h3>
                    </span>
                    <span class="card-media-text">
                        MAJ 18 Mai 2021 Le 17 mai, des modifications apportées dans les données SI-VIC transmises à
                        Santé publique France
                    </span>
                </div>
            </div>
            <div class="card-text">
                <cds-icon shape="file-group"></cds-icon>
                5 file(s)
                <cds-icon shape="star"></cds-icon>
                0 star(s)
                <cds-icon shape="map-marker"></cds-icon>
                data.gouv.fr
                <cds-icon shape="check"></cds-icon>
                Source confirmed
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-block">
            <div class="card-media-block">
                <img src="https://static.data.gouv.fr/avatars/ad/95e60b115a4582b2dcb1eb0ad7e9d3-100.png"
                    class="card-media-image" />
                <div class="card-media-description">
                    <span class="card-media-title">
                        <h3 style="margin-top: 0px;margin-bottom: 0px;">Baromètre des résultats de l’action publique
                        </h3>
                    </span>
                    <span class="card-media-text">
                        Le baromètre des résultats de l'action publique Le baromètre des résultats de l’action publique
                        concrétise un engagement
                    </span>
                </div>
            </div>
            <div class="card-text">
                <cds-icon shape="file-group"></cds-icon>
                5 file(s)
                <cds-icon shape="star"></cds-icon>
                0 star(s)
                <cds-icon shape="map-marker"></cds-icon>
                data.gouv.fr
                <cds-icon shape="check"></cds-icon>
                Source confirmed
            </div>
        </div>
    </div>
</div>